import React, { useContext } from 'react';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { Link, useRouteMatch } from 'react-router-dom';
import ViewIcon from 'assets/open_in_new.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EditIcon from 'assets/edit_outlined.svg';
import RemoveIcon from 'assets/remove_circle_outlined.svg';
import FormattedNumber from 'components/FormattedNumber';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { emptyValue } from 'screens/ProductList/helpers/tableConstants';
import { Context } from 'components/Store';

import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import ProductListCellQuantity from '../ProductListCellQuantity';
import '../ProductListTable/index.css';

const ProductListTableRow = ({ product, onEdit, onRemove }) => {
  const amplitude = useContext(AmplitudeContext);
  const [organization] = useContext(Context);
  const {
    feProductListRemoveProduct,
    feProductListRemoveProductButtonByOrgId
  } = useFlags();
  const { url } = useRouteMatch();
  return (
    <Table.Row key={product.productId}>
      <Table.Cell width="auto">
        <div className="-my-1">
          <div className="flex flex-col items-start text-sm  ">
            <span>{product.productName}</span>
            <span className="font-normal text-neutral-60 text-xs">
              {product?.manufacturerName}
            </span>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell width="auto">
        <ProductTypeDisplay productType={product.productType} />
      </Table.Cell>
      <Table.Cell width="auto">
        <ProductListCellQuantity product={product} />
      </Table.Cell>
      <Table.Cell width="auto">
        {product.averagePricePerUnit ? (
          <>
            <FormattedNumber unit="usCurrency" roundedTo={2}>
              {product.averagePricePerUnit}
            </FormattedNumber>{' '}
            / {getAbbreviatedUnit(product.averagePriceUnit ?? product.stdUnit)}
          </>
        ) : (
          emptyValue.none
        )}
      </Table.Cell>
      <Table.Cell width="auto">
        <div className="w-full flex flex-row justify-end">
          <Link
            to={`${url}/${product.productId}/view`}
            className="flex flex-row product-list-table-action"
            onClick={() =>
              amplitude.sendEventToAmplitude(
                amplitude.events.epic.ProductList.viewProductDetails,
                { productId: product.productId }
              )
            }
          >
            <img className="mr-1" src={ViewIcon} alt="view icon" />
            <span>View</span>
          </Link>
          <span className="mx-1 gr-text-neutral-400">|</span>
          <div
            className="flex flex-row product-list-table-action"
            role="button"
            onClick={() => onEdit(product)}
            onKeyDown={event => event.nativeEvent.key === 'Enter'}
            tabIndex={0}
            data-testid="btn-edit-product"
          >
            <img className="mr-1" width={18} src={EditIcon} alt="edit icon" />
            <span>Edit</span>
          </div>
          {feProductListRemoveProduct ||
          feProductListRemoveProductButtonByOrgId?.includes(
            organization?.organization?.id
          ) ? (
            <>
              <span className="mx-1 gr-text-neutral-400">|</span>
              <div
                className="flex flex-row product-list-table-action mr-2"
                role="button"
                onClick={() => onRemove(product)}
                onKeyDown={event => event.nativeEvent.key === 'Enter'}
                tabIndex={0}
                data-testid="btn-remove-product"
              >
                <img className="mr-1" src={RemoveIcon} alt="remove icon" />
                <span>Remove</span>
              </div>
            </>
          ) : null}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

ProductListTableRow.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    productType: PropTypes.string,
    available: PropTypes.number,
    stdUnit: PropTypes.string,
    averagePricePerUnit: PropTypes.number,
    averagePriceUnit: PropTypes.string,
    manufacturerName: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ProductListTableRow;
