import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import PAGINATION_SIZE from 'helpers/globalConstants';

export const paginationStyles = {
  paginationTableStyles: {
    border: 0,
    color: '#696F88',
    padding: '0px'
  },
  paginationButtonStyles: {
    border: '1px solid',
    borderRadius: '4px',
    padding: '1px'
  }
};
const ActionComponentDisabled = () => <span />;

const CwFPagination = (
  totalCount,
  pageNumber,
  handlePageChange,
  leftElement = null,
  rowsPerPage = PAGINATION_SIZE
) => {
  const LeftComponent = leftElement;
  const showPaginationArrows = totalCount > rowsPerPage;

  return (
    <table className="w-full ">
      <div className="float-right">
        <thead>
          <tr>
            {LeftComponent ? (
              <th className="font-normal">
                <LeftComponent />
              </th>
            ) : null}
            <th>
              <TablePagination
                count={totalCount}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                style={paginationStyles.paginationTableStyles}
                backIconButtonProps={{
                  style: {
                    ...paginationStyles.paginationButtonStyles,
                    marginRight: '8px'
                  }
                }}
                nextIconButtonProps={{
                  style: { ...paginationStyles.paginationButtonStyles }
                }}
                onPageChange={(_, page) => {
                  handlePageChange(page);
                }}
                ActionsComponent={
                  showPaginationArrows ? undefined : ActionComponentDisabled
                }
              />
            </th>
          </tr>
        </thead>
      </div>
    </table>
  );
};

export default CwFPagination;
