import React from 'react';
import PropTypes from 'prop-types';

const SVG_PATH =
  'M78.2079 15.875H28.6667V21.125H78.2079V29L94.6667 18.5L78.2079 8V15.875Z';
const DEFAULT_COLOR = '#696F88';

const MatchArrowIcon = ({ color = DEFAULT_COLOR }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122"
    height="38"
    viewBox="0 0 122 38"
    fill="none"
  >
    <path d={SVG_PATH} fill={color} />
  </svg>
);

MatchArrowIcon.propTypes = {
  color: PropTypes.string
};

MatchArrowIcon.defaultProps = {
  color: DEFAULT_COLOR
};

export default MatchArrowIcon;
