import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Input, Popup, Tag } from '@agconnections/grow-ui';
import SprayIcon from 'components/Icons/components/SprayIcon';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import ClickableDiv from 'components/ClickableDiv';

const registrationNumberPattern = /^\d+-\d+-[A-Za-z]{2}(-\d+)?$/;

const Product = ({ product, checked }) => {
  const {
    state: { selectedProducts },
    setSelectedProducts
  } = useContext(SubmissionSummaryContext);

  const selectedProductRef = selectedProducts.find(
    selectedProduct => selectedProduct.productId === product.productId
  );

  const handleCheckProduct = () => {
    const checkedIndex = selectedProducts.findIndex(
      selectedProduct => selectedProduct.productId === product.productId
    );

    if (checkedIndex >= 0) {
      selectedProducts.splice(checkedIndex, 1);
    } else {
      selectedProducts.push(product);
    }
    setSelectedProducts(selectedProducts);
  };

  const handleChangeRegistrationNumber = registrationNumber => {
    const registrationNumberWithoutSpaces = registrationNumber?.replace(
      /\s/g,
      ''
    );
    const isValid =
      registrationNumberWithoutSpaces &&
      registrationNumberPattern.test(registrationNumberWithoutSpaces);
    selectedProductRef.overrideRegistrationNumber = registrationNumberWithoutSpaces;
    selectedProductRef.error = !isValid;
    setSelectedProducts(selectedProducts);
  };

  const RegistrationNumberInput = (
    <div className="w-52">
      <GrowUIFormField
        style={{
          display: !checked ? 'none' : 'block',
          borderColor: selectedProductRef?.error ? '#EB4B4B' : '#e2e8f0'
        }}
        placeholder="CDPR Registration Number"
        control={Input}
        required={checked}
        type="text"
        id={`product_${product.productId}`}
        name={`product_${product.productId}`}
        value={selectedProductRef?.overrideRegistrationNumber}
        onChange={e => handleChangeRegistrationNumber(e.target.value)}
      />
    </div>
  );

  return (
    <div className="flex p-4 flex-line items-center justify-center w-full rounded shadow-md ">
      <ClickableDiv
        className="flex items-center justify-center"
        onClick={handleCheckProduct}
      >
        <div className="mr-2">
          <Checkbox checked={checked} color="#0071cd" />
        </div>
        <div className="flex items-center justify-center">
          <SprayIcon width={24} height={24} size={18} />
          <span className="ml-1 font-lg">{product.productName}</span>
        </div>
      </ClickableDiv>
      <div className="ml-auto flex justify-center items-center">
        <div className="mr-10">
          <Popup
            enableHover
            position="top"
            align="center"
            hasArrow
            bgColor="#232630"
            trigger={RegistrationNumberInput}
          >
            <div
              className="gr-w-64 text-xs text-white"
              data-testid="product-input-hover-content"
            >
              Here it is expected that a valid and active CDPR registration
              number will be filled in. You can use the link above to search.
            </div>
          </Popup>
        </div>
        <div>
          <Tag color="#C6E6FF">
            <span className="p-1 text-blue-70">
              {product.countApplications}{' '}
              {`${
                product.countApplications > 1 ? 'APPLICATIONS' : 'APPLICATION'
              }`}
            </span>
          </Tag>
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    countApplications: PropTypes.number.isRequired,
    overrideRegistrationNumber: PropTypes.string
  }).isRequired,
  checked: PropTypes.bool.isRequired
};

export default Product;
