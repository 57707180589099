const buildPURExtraDataForSubmit = (forms, counties) => {
  const formsToSubmit = [];
  forms.forEach(form => {
    form.applicationMethods.split(', ').forEach(method => {
      formsToSubmit.push({
        taskId: form.task.taskId,
        cropZoneId: form.task.cropZoneId,
        applicationMethod: method,
        applicator: form.applicatorType === 'pco' ? 2 : 1,
        ...(form.applicatorType === 'pco'
          ? {
              applicatorLicenseNumber: form.pcoNumber,
              applicatorLicenseType: form.pcoType,
              applicatorName: form.pco.name
            }
          : {}),
        ...(method === 'Fumigation'
          ? {
              fumigationCode: form.fumigationCode
            }
          : {})
      });
    });
  });

  return {
    purExtraData: {
      forms: formsToSubmit,
      counties
    }
  };
};

export default buildPURExtraDataForSubmit;
