import { useCallback, useContext, useState } from 'react';
import { agrian as agrianApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';

const useAgrianApi = () => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(Context);
  const orgId = localStorage.getItem('selectedOrganizationId');

  const authenticate = useCallback(
    ({ email, password }) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/auth'
        })
        .create(
          { email, password },
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const growers = useCallback(
    (hideError = false) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/growers'
        })
        .fetch(null, undefined, {
          headers: {
            'cwf-context': JSON.stringify({
              organization_id: orgId
            })
          }
        });

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(e => {
          if (!hideError) {
            parseServerError(dispatch)(e);
          }
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  const saveOrganizationMatch = useCallback(
    ({ growerId, organizationId, growerDescription }) => {
      const { promise } = agrianApi
        .createChildApi({
          action: 'integrations/agrian/match'
        })
        .create(
          [
            {
              cwfEntityId: orgId,
              agrianEntityDescription: growerDescription,
              agrianEntityId: growerId,
              type: 'Grower'
            },
            {
              cwfEntityId: orgId,
              agrianEntityDescription: growerDescription,
              agrianEntityId: organizationId,
              type: 'Organization'
            }
          ],
          {
            headers: {
              'cwf-context': JSON.stringify({
                organization_id: orgId
              })
            }
          }
        );

      setLoading(true);

      return promise
        .then(res => res)
        .catch(catchCancel)
        .catch(parseServerError(dispatch))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  return {
    authenticate,
    saveOrganizationMatch,
    growers,
    loading
  };
};

export default useAgrianApi;
