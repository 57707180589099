import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import useCropSeasons from 'hooks/useCropSeasons';
import usePopulateMapData from 'hooks/usePopulateMapData';
import { FETCH_TYPES } from 'helpers/constants';
import { mapFieldsGeoJSONCollectionOnCropZones } from 'helpers/propertyHelpers';
import { IntegrationsContext } from '../context/IntegrationsProvider';
import {
  getCropSeasonMaxEndDateEpoch,
  getCropSeasonMinStartDateEpoch
} from '../helpers/cropSeasonsHelpers';

const SELECTED_ORGANIZATION = 'selectedOrganization';
const SELECTED_CROP_SEASONS = 'selectedCropSeasons';
const SELECTED_PROPERTIES = 'selectedProperties';

/**
 * Adapter between integrations and Formik context
 */
const useFormikHandler = () => {
  const { values, setFieldValue } = useFormikContext();
  const [submissionCropZonesIds, setSubmissionCropZonesIds] = useState([]);
  const {
    updateSelectedProperties,
    state: { selectedProperties: selectedPropertiesFromIntegrationContext }
  } = useContext(IntegrationsContext);
  const {
    reloadData,
    setGeoJSONCollectionFeatures,
    filterFeatureCollectionByParentId,
    fieldsAndAreasGeoJSONCollection
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap
  });

  const { cropSeasons } = useCropSeasons();

  const setSelectedOrganization = orgId => {
    setFieldValue(SELECTED_ORGANIZATION, orgId);
  };

  const setSelectedProperties = properties => {
    setFieldValue(SELECTED_PROPERTIES, properties);
  };

  const setSelectedCropSeasons = seasonsSelected => {
    setFieldValue(SELECTED_CROP_SEASONS, seasonsSelected);
    setFieldValue(
      'cropSeasonsMinDate',
      getCropSeasonMinStartDateEpoch(seasonsSelected)
    );
    setFieldValue(
      'cropSeasonsMaxDate',
      getCropSeasonMaxEndDateEpoch(seasonsSelected)
    );
  };

  const setAllDataFromSubmission = (
    orgId,
    submissionCropSeasons,
    submissionFarms,
    _submissionCropZonesIds
  ) => {
    setSelectedOrganization(orgId);
    setSelectedCropSeasons(submissionCropSeasons);
    setSelectedProperties(submissionFarms);
    setSubmissionCropZonesIds(_submissionCropZonesIds);
  };

  // Append date if the selectedCropSeasons does not have it (coming from submission)
  useEffect(() => {
    const selectedCropSeasons = values[SELECTED_CROP_SEASONS];
    if (
      cropSeasons?.length &&
      selectedCropSeasons?.length &&
      !selectedCropSeasons?.[0]?.startDateEpoch
    ) {
      setSelectedCropSeasons(
        cropSeasons?.filter(cropSeasonWithDate =>
          selectedCropSeasons.find(
            submissionCrop => cropSeasonWithDate.id === submissionCrop?.id
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons, values[SELECTED_CROP_SEASONS]]);

  // Append proper data to properties selections steps
  useEffect(() => {
    if (
      submissionCropZonesIds?.length &&
      values[SELECTED_CROP_SEASONS]?.length
    ) {
      reloadData({
        seasonIds: values[SELECTED_CROP_SEASONS]?.map(season => season?.id)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionCropZonesIds, values[SELECTED_CROP_SEASONS]]);

  useEffect(() => {
    if (
      submissionCropZonesIds?.length &&
      fieldsAndAreasGeoJSONCollection?.features?.length &&
      !selectedPropertiesFromIntegrationContext.length
    ) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          undefined,
          submissionCropZonesIds
        )
      );

      const mappedProperties = mapFieldsGeoJSONCollectionOnCropZones(
        fieldsAndAreasGeoJSONCollection,
        submissionCropZonesIds
      );

      updateSelectedProperties(mappedProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsAndAreasGeoJSONCollection.features,
    submissionCropZonesIds,
    selectedPropertiesFromIntegrationContext
  ]);

  return useMemo(
    () => ({
      setSelectedOrganization,
      setSelectedCropSeasons,
      setSelectedProperties,
      setAllDataFromSubmission,
      selectedOrganization: values[SELECTED_ORGANIZATION],
      selectedCropSeasons: values[SELECTED_CROP_SEASONS],
      selectedProperties: values[SELECTED_PROPERTIES]
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );
};

export default useFormikHandler;
