/* eslint-disable no-nested-ternary */
import { cloneDeep, orderBy } from 'lodash';

const multiLevelSort = ({
  items = [],
  sortBy = [],
  sortDir = [],
  sorter = (f, i) => i[f]
}) => {
  if (!sortBy || !sortDir || sortBy.length !== sortDir.length) return items;
  const _items = items.map(item => cloneDeep(item));
  return orderBy(
    _items,
    sortBy.map(field => _item => sorter(field, _item)),
    sortDir
  );
};

export const extractAlphanumericPartsToSort = str => {
  if (/^\d+/.test(str)) {
    return ['~', parseInt(str, 10)];
  }
  const alphanumericPattern = /(\D+)(\d+)?/;
  const matches = str.match(alphanumericPattern);
  if (!matches) return [str, Infinity];
  const [, textPart, numericPart] = matches;
  const numericValue = numericPart ? parseInt(numericPart, 10) : -Infinity;
  return [textPart.trim().toLowerCase(), numericValue];
};

export default multiLevelSort;
