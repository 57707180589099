/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { Input, Checkbox } from '@agconnections/grow-ui';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './styles.css';
import { isEqual } from 'lodash';
import {
  ArrowRight,
  ArrowDropDown,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@material-ui/icons';
import { filterCropSeason } from 'screens/CropSeasons/helpers/dataHelpers';
import {
  getFarmIds,
  selectedCropzones,
  traverseFarms,
  traverseFields
} from 'screens/CropSeasons/CropSeason/helpers/propertyHelpers';

const GroupByFilterProperty = ({
  data,
  value,
  enableFilter,
  onChange,
  disabled,
  cropZonesChecked,
  setCropZonesChecked
}) => {
  const [checked, setChecked] = useState(value);
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState();
  const [fft, setFft] = useState([]);
  const [nodesFiltered, setNodesFiltered] = useState(fft);
  const [allCropzoneIds, setAllCropzoneIds] = useState([]);
  let selectedCropzoneData = [];
  const [selectAllFlag, setSelectAllFlag] = useState(null);

  let mappedFft = [];

  useEffect(() => {
    mappedFft = data?.map(farm => ({
      value: farm.id,
      className: farm.archived ? 'opacity-50' : '',
      label: farm.name + (farm.archived ? ' (archived)' : ''),
      type: 'farm',
      disabled: traverseFarms(farm.selectedCropSeason, farm.fields) === 0,
      ...(farm.fields.length > 0
        ? {
            children: farm.fields?.map(field => ({
              className: field.archived && !farm.archived ? 'opacity-50' : '',
              value: field.id,
              label: field.name + (field.archived ? ' ( archived)' : ''),
              type: 'field',
              disabled:
                traverseFields(farm.selectedCropSeason, field.cropzones) === 0,
              ...(field.cropzones.length > 0
                ? {
                    children: field.cropzones?.map(cropzone => ({
                      value: cropzone.id,
                      className:
                        cropzone.archived && !field.archived
                          ? 'opacity-50'
                          : '',
                      label:
                        cropzone.name +
                        (cropzone.archived ? ' (archived)' : ''),
                      farmId: farm.id,
                      type: 'cropzone',
                      disabled:
                        filterCropSeason(
                          farm.selectedCropSeason,
                          cropzone.cropSeasons
                        ) === 0
                    }))
                  }
                : {})
            }))
          }
        : {})
    }));

    if (mappedFft?.length > 0) {
      selectedCropzoneData = selectedCropzones(mappedFft, selectedCropzoneData);
      setAllCropzoneIds(selectedCropzoneData);
      setFft(mappedFft);
    }
  }, [data]);

  const checkCropzoneCount = checkedIds => {
    if (value?.length > 0 || checkedIds?.length > 0) {
      if (checkedIds?.length === selectedCropzoneData.length) {
        setSelectAllFlag(true);
      }
      if (checkedIds?.length < selectedCropzoneData.length) {
        setSelectAllFlag(null);
      }
    } else {
      setSelectAllFlag(null);
    }
  };

  const handleCheck = (checkedIds = [], targetNode) => {
    checkCropzoneCount(checkedIds);
    setCropZonesChecked(checkedIds);
    setChecked(checkedIds);
    const farmIds = getFarmIds(fft.length ? fft : mappedFft, checkedIds);
    onChange(checkedIds, farmIds, targetNode);
  };
  const onFilterChange = e => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    handleCheck(cropZonesChecked);
  }, [cropZonesChecked]);

  const filterNodes = useCallback(
    (filtered, node) => {
      const children = (node.children || []).reduce(filterNodes, []);

      if (
        // Node's label matches the search string
        node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1 ||
        // Or a children has a matching node
        children.length
      ) {
        filtered.push({ ...node, children });
      }
      return filtered;
    },
    [filterText]
  );

  useEffect(() => {
    if (!filterText) {
      setNodesFiltered(fft);

      return;
    }
    const nodesFilter = prevState => prevState.reduce(filterNodes, []);

    setNodesFiltered(nodesFilter);
  }, [filterText, filterNodes, fft]);

  useEffect(() => {
    if (!isEqual(selectedCropzoneData, cropZonesChecked)) {
      setCropZonesChecked(selectedCropzoneData);
    } else {
      setSelectAllFlag(true);
    }

    if (selectAllFlag === true) {
      handleCheck(allCropzoneIds);
      setSelectAllFlag(true);
    } else if (selectAllFlag === false) {
      handleCheck([]);
    } else {
      handleCheck(cropZonesChecked);
    }
  }, [setSelectAllFlag, selectAllFlag]);

  const handleSelectAllProperty = () => {
    if (selectAllFlag === null) {
      setSelectAllFlag(true);
    } else {
      setSelectAllFlag(!selectAllFlag);
    }
  };

  return (
    <div
      className="filter-container"
      data-testid="cropseason-group-by-property"
    >
      <div className="mt-2">
        <Checkbox
          label="Select All"
          onChange={() => handleSelectAllProperty()}
          checked={selectAllFlag}
          color="#0071cd"
        />
      </div>
      {enableFilter && (
        <div className="mb-2">
          <Input
            className=" filter-text"
            placeholder="Search..."
            type="text"
            value={filterText}
            onChange={onFilterChange}
          />
        </div>
      )}
      <CheckboxTree
        nodes={nodesFiltered}
        checked={checked}
        showNodeIcon={false}
        icons={{
          halfCheck: <IndeterminateCheckBox fontSize="small" />,
          uncheck: (
            <CheckBoxOutlineBlank
              fontSize="small"
              className="text-neutral-600"
            />
          ),
          check: <CheckBox fontSize="small" />,
          expandClose: (
            <ArrowRight fontSize="small" className="text-neutral-600" />
          ),
          expandOpen: (
            <ArrowDropDown fontSize="small" className="text-neutral-600" />
          )
        }}
        expanded={expanded}
        onCheck={(id, targetNode) => handleCheck(id, targetNode)}
        onExpand={ex => setExpanded(ex)}
        showNodeTitle
        optimisticToggle={false}
        disabled={disabled}
      />
    </div>
  );
};

GroupByFilterProperty.defaultProps = {
  onChange: () => {},
  enableFilter: false,
  disabled: false,
  value: [],
  cropZonesChecked: [],
  setCropZonesChecked: () => {}
};
GroupByFilterProperty.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  enableFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  cropZonesChecked: PropTypes.arrayOf(PropTypes.object),
  setCropZonesChecked: PropTypes.func
};

export default GroupByFilterProperty;
