import {
  booleanEqual,
  getType,
  getCoords,
  polygon,
  centroid,
  feature,
  featureCollection
} from '@turf/turf';
import { isFunction } from 'lodash';
import sortTree from '../../../helpers/sortTree';

import { NATIONAL_ZOOM, USA_CENTER_COORDINATES } from './constants';

const setState = (previousValue, newValue) => {
  if (isFunction(newValue)) {
    return newValue(previousValue);
  }
  return newValue;
};

// property data reducer action handlers

const findMatchingParentPolygonGeometry = (parentGeometry, childGeometry) =>
  getCoords(parentGeometry).find(polyonGeometry =>
    booleanEqual(polygon(polyonGeometry), childGeometry)
  );

const findMatchingParentGeometry = (parentGeometry, childGeometry) => {
  if (
    childGeometry === undefined ||
    (Object.keys(childGeometry).length === 0 &&
      childGeometry.constructor === Object)
  ) {
    return false;
  }
  return getType(parentGeometry) === 'MultiPolygon' &&
    getType(childGeometry) === 'Polygon'
    ? findMatchingParentPolygonGeometry(parentGeometry, childGeometry)
    : booleanEqual(parentGeometry, childGeometry);
};

const childrenIdsWithIncludedGeometry = (parentGeometry, cropzones) =>
  (cropzones || [])
    .filter(({ geometry }) => {
      return findMatchingParentGeometry(parentGeometry, geometry);
    })
    .map(({ id }) => id);

const doesParentDuplicateChildGeometry = (parentGeometry, geometry, id) =>
  !!childrenIdsWithIncludedGeometry(parentGeometry, [
    { id, geometry }
  ]).includes(id);

const cropZoneReducer = (propertyId, fieldId, parentGeometry) => (
  acc,
  { geometry, id, ...rest }
) => [
  ...acc,
  // ...(geometry
  ...(geometry &&
  !(Object.keys(geometry).length === 0 && geometry.constructor === Object)
    ? [
        feature(geometry, {
          ...rest,
          id,
          $landType: 'cropzone',
          propertyId,
          $parentFieldId: fieldId,
          $parentDuplicated: doesParentDuplicateChildGeometry(
            parentGeometry,
            geometry,
            id
          )
        })
      ]
    : [])
];

const fieldsReducer = propertyId => (
  acc,
  { geometry, cropzones = [], ...field }
) => [
  ...acc,
  ...(geometry
    ? [
        feature(geometry, {
          ...field,
          propertyId,
          cropzones,
          $landType: 'field',
          $includedChildGeometries: childrenIdsWithIncludedGeometry(
            geometry,
            cropzones
          )
        })
      ]
    : []),
  ...cropzones.reduce(cropZoneReducer(propertyId, field.id, geometry), [])
];

// handle the success of loading properties data
export const reducerUpdatePropertiesDataState = (state, action) => {
  const mappedProperties = action.payload.properties.filter(
    property => property.status === 200 || property.status === null
  );

  const geoJsonFeatures = mappedProperties.flatMap(property =>
    property?.fields?.reduce(fieldsReducer(property.id), [])
  );
  const newState = {
    ...state,
    data: {
      properties: mappedProperties
    },
    fieldsAndAreasGeoJSONCollection: featureCollection(geoJsonFeatures),
    loading: false
  };

  return newState;
};

export const reducerUpdateSingleFarmDataState = (state, action) => {
  const mappedProperties = action.payload.properties.filter(
    property => property.status === 200
  );

  const geoJsonFeatures = mappedProperties.flatMap(property =>
    property?.fields?.reduce(fieldsReducer(property.id), [])
  );

  const current = state.data.properties.find(
    property => property.id === mappedProperties[0]?.id
  );

  if (!current || !mappedProperties[0]) {
    return { ...state };
  }

  Object.assign(current, mappedProperties[0]);

  const newState = {
    ...state,
    data: { properties: sortTree([...state.data.properties]) },
    fieldsAndAreasGeoJSONCollection: {
      type: 'FeatureCollection',
      features: [
        ...state.fieldsAndAreasGeoJSONCollection.features,
        ...geoJsonFeatures
      ]
    }
  };

  return newState;
};

export const setPropertyCosts = (state, action) => {
  const newProperties = state.data.properties.map(farm => {
    return {
      ...farm,
      fields:
        action.payload.farmId === farm.id
          ? farm.fields.map(field => {
              return {
                ...field,
                cropzones: field.cropzones.map(cropzone => {
                  return {
                    ...cropzone,
                    costs: action.payload.costs[cropzone.id] ?? cropzone.costs
                  };
                })
              };
            })
          : farm.fields
    };
  });

  return { ...state, data: { properties: [...newProperties] } };
};

// set the state of fieldsLoading
export const reducerUpdateFieldsLoadingState = value => state => ({
  ...state,
  fieldsLoading: value,
  loading: false
});

export const reducerSetGeoJSONCollectionFeatures = (state, action) => ({
  ...state,
  fieldsAndAreasGeoJSONCollection: {
    ...state.fieldsAndAreasGeoJSONCollection,
    features: action.payload
  }
});

// calculate and update the centroid based on the features in the geojson feature collection
export const reducerCalculateGeoJSONCentroidState = state => {
  const fieldsAndAreasCentroid = centroid(
    state.fieldsAndAreasGeoJSONCollection
  );
  return {
    ...state,
    loading: false,
    fieldsAndAreasCentroidCoordinates:
      fieldsAndAreasCentroid && fieldsAndAreasCentroid.geometry
        ? fieldsAndAreasCentroid.geometry.coordinates
        : undefined
  };
};

// reset the zoom and map position
export const reducerResetMapViewState = state => ({
  ...state,
  zoom: NATIONAL_ZOOM,
  fieldsAndAreasCentroidCoordinates: USA_CENTER_COORDINATES,
  loading: state.shapesLoading ? state.loading : false
});

// functions as a setState for the specified stateField
export const reducerSetState = stateField => (state, action) => {
  const newState = {
    ...state,
    [stateField]: setState(state[stateField], action.payload)
  };
  return newState;
};
