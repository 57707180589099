import React, { useMemo, useContext, useState } from 'react';
import { Table } from '@agconnections/grow-ui';
import ViewIcon from 'assets/open_in_new.svg';
import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';
import { format } from 'date-fns';
import tableSort from 'helpers/tableSort';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import useInventoryData from 'screens/Inventory/helpers/useInventoryData';
import useInventoryInvoiceData from 'screens/Inventory/helpers/useInventoryInvoiceData';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import routesForPPLToCreate from 'screens/ProductList/helpers/creationRoutes';
import ProductDetailTableEmptyState from '../ProductDetailTableEmptyState';

const PAGE_COUNT = 20;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const ProductDetailInvoiceTable = ({ cropSeasons, dateRange }) => {
  const amplitude = useContext(AmplitudeContext);
  const { id: productId } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState('invoiceDate');
  const [sortDir, setSortDir] = useState('desc');

  const { products, loading } = useInventoryData();
  const { invoices, invsLoading } = useInventoryInvoiceData(productId);

  const selectedProduct = useMemo(() => {
    const prodData = products.find(
      prod => prod.productId === productId || prod.id === productId
    );

    return {
      ...prodData,
      productName: prodData?.name || prodData?.productName,
      manufacturer: prodData?.manufacturer,
      id: productId
    };
  }, [products, productId]);

  const { rows: invoiceRows, totalProductCount } = useMemo(() => {
    if (!selectedProduct || !invoices)
      return { rows: [], totalProductCount: 0 };

    let productCount = 0;

    const invoiceData = invoices.map(i => {
      const filteredProducts = i.invoiceProducts.filter(
        prod => prod.productId === selectedProduct.id
      );

      let totalCost = 0;
      let costPerUnit = 0;
      let invoiceQuantity = 0;
      const unit = filteredProducts.length
        ? filteredProducts[0].totalQuantityUnit
        : '';

      filteredProducts.forEach(product => {
        totalCost += product.totalCostValue;
        costPerUnit += product.totalCostValue / product.totalQuantityValue;
        invoiceQuantity += product.totalQuantityValue;
        productCount += product.totalQuantityValue;
      });

      return {
        id: i.id,
        invoiceName: i.invoiceNumber,
        invoiceDate: i.invoiceDate,
        invoiceCropSeasons: i.cropSeasons,
        totalCost,
        costPerUnit,
        invoiceQuantity,
        unit
      };
    });

    return { rows: invoiceData, totalProductCount: productCount };
  }, [selectedProduct, invoices]);

  const filteredAndSortedInvoices = useMemo(() => {
    const filtered = invoiceRows.filter(row => {
      const invoiceDate = new Date(row.invoiceDate);
      const cropFilter =
        cropSeasons.length === 0 ||
        row.invoiceCropSeasons.some(crop =>
          cropSeasons.includes(crop.seasonId)
        );
      const dateFilter =
        !dateRange ||
        (invoiceDate > new Date(dateRange[0]) &&
          invoiceDate < new Date(dateRange[1]));
      return cropFilter && dateFilter;
    });
    return tableSort(filtered, sortField, sortDir);
  }, [invoiceRows, sortField, sortDir, cropSeasons, dateRange]);

  const TABLE_COLUMNS = [
    {
      field: 'invoiceName',
      sortable: true,
      title: `Invoice Name (${filteredAndSortedInvoices.length})`
    },
    {
      field: 'invoiceDate',
      sortable: true,
      title: 'Invoice Date'
    },
    {
      field: 'totalCost',
      sortable: true,
      title: 'Total Cost'
    },
    {
      field: 'costPerUnit',
      sortable: true,
      title: 'Cost per unit'
    },
    {
      field: 'invoiceQuantity',
      sortable: true,
      title: 'Invoice Quantity'
    },
    {
      field: 'actions'
    }
  ];

  const paginatedRows = filteredAndSortedInvoices.slice(
    page * PAGE_COUNT,
    (page + 1) * PAGE_COUNT
  );

  const handleSort = (field, dir) => {
    const _dir = !dir ? 'asc' : dir;
    setSortField(field);
    setSortDir(_dir);
  };

  const isLoading = !selectedProduct || invsLoading || loading;
  const isEmpty = paginatedRows.length === 0;
  return (
    <div className="flex" style={{ height: 'calc(100% - 3rem)' }}>
      <div className="flex-1 overflow-scroll">
        <LandingTable
          items={paginatedRows}
          isLoading={isLoading}
          isEmpty={isEmpty}
          emptyState={() => null}
          tableColumns={TABLE_COLUMNS}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
          wrapperClassName="flex-1 h-fit"
        >
          {({ rows }) => {
            return rows.map(prod => (
              <Table.Row key={prod.id}>
                <Table.Cell width="auto">{prod.invoiceName}</Table.Cell>
                <Table.Cell width="auto">
                  {format(new Date(prod.invoiceDate), 'MMM dd, u')}
                </Table.Cell>
                <Table.Cell width="auto">
                  {formatter.format(prod.totalCost)}
                </Table.Cell>
                <Table.Cell width="auto">{`${formatter.format(
                  prod.costPerUnit
                )} / ${getAbbreviatedUnit(prod.unit)}`}</Table.Cell>
                <Table.Cell width="auto">{`${
                  prod.invoiceQuantity
                } ${getAbbreviatedUnit(prod.unit)}`}</Table.Cell>
                <Table.Cell width="auto">
                  <div className="w-full flex flex-row justify-end">
                    <Link
                      to={`${paths.invoices}/${prod.id}/view`}
                      className="flex flex-row product-list-table-action"
                      onClick={() =>
                        amplitude.sendEventToAmplitude(
                          amplitude.events.epic.ProductList.viewInvoice,
                          { productId: prod.id }
                        )
                      }
                    >
                      <img className="mr-1" src={ViewIcon} alt="view icon" />
                      <span>View</span>
                    </Link>
                  </div>
                </Table.Cell>
              </Table.Row>
            ));
          }}
        </LandingTable>
        {!isLoading && filteredAndSortedInvoices.length > 0 ? (
          <div className="w-full px-6 border-1 border-t-0 border-neutral-20">
            {CwFPagination(
              filteredAndSortedInvoices.length,
              page,
              setPage,
              () => (
                <div className="bg-banner-alert-blue-10 rounded py-1 px-3">
                  <span>Total Invoiced Quantity: </span>
                  <span className="font-semibold">
                    {totalProductCount.toFixed(2)}{' '}
                    {getAbbreviatedUnit(selectedProduct?.productQuantityUnit)}
                  </span>
                </div>
              )
            )}
          </div>
        ) : null}
        {!isLoading && isEmpty && (
          <ProductDetailTableEmptyState
            type="invoices"
            totalItemCount={invoiceRows.length}
            onButtonClick={() => history.push(routesForPPLToCreate.invoice)}
          />
        )}
      </div>
    </div>
  );
};

ProductDetailInvoiceTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ProductDetailInvoiceTable;
