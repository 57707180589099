import { useCallback, useContext, useState } from 'react';

import { Context } from 'components/Store';
import { organization as organizationApi } from 'utilities/api';
import { getAccessToken, useAuth } from 'utilities/base-auth';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';

import { selectOrganization } from 'utilities/updated-permissions';
import axios from 'axios';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';

const useOrganization = () => {
  const { user } = useAuth();
  const [, dispatch] = useContext(Context);
  const [
    organizationPermissionError,
    setOrganizationPermissionError
  ] = useState(false);
  const [loadedOrgByIdV1, setLoadedOrgByIdV1] = useState();
  const [orgByIdV1, setOrgByIdV1] = useState();

  const getOrganization = useCallback(async () => {
    dispatch({
      type: 'ORG_LOADING',
      payload: true
    });
    const { promise } = organizationApi.fetch();
    setOrganizationPermissionError(false);
    await promise
      .then(({ data }) => {
        const filterMember = data?.members?.filter(
          eachMember => eachMember.email === user.email
        )[0];
        dispatch({
          type: 'SELECT_MEMBER',
          payload: filterMember
        });
        dispatch({
          type: 'ORG_LOADING',
          payload: false
        });
      })
      .catch(catchCancel)
      .catch(err => {
        dispatch({
          type: 'ORG_LOADING',
          payload: false
        });
        setOrganizationPermissionError(true);
        parseServerError(dispatch)(err);
      });
  }, [dispatch, user.email]);

  const setOrganization = useCallback(
    newOrg => {
      selectOrganization(dispatch, newOrg, user);
      getOrganization();
    },
    [dispatch, getOrganization, user]
  );
  const getOrgByIdV1 = useCallback(orgId => {
    setLoadedOrgByIdV1(false);
    const promise = axios.get(`${CROPWISE_PROXY_URL}/v1/base/organization`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
        'cwf-context': JSON.stringify({
          organization_id: orgId
        })
      }
    });

    return promise
      .then(({ data }) => {
        if (data) {
          setOrgByIdV1(data);
        } else {
          setOrgByIdV1(null);
        }
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => setLoadedOrgByIdV1(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setOrganization,
    organizationPermissionError,
    getOrgByIdV1,
    loadedOrgByIdV1,
    orgByIdV1
  };
};

export default useOrganization;
