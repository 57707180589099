/* eslint-disable import/prefer-default-export */
import Decimal from 'decimal.js';

export const DEFAULT_ROUND = 15;

/**
 * A class providing static methods for performing arithmetic operations with rounding,
 * using the Decimal library for high precision calculations.
 */
export class Maths {
  /**
   * Divides two numbers or Decimal instances safely with rounding.
   * Returns 0 if the result is NaN or infinite.
   *
   * @param {number|Decimal} numerator - The numerator of the division.
   * @param {number|Decimal} denominator - The denominator of the division.
   * @param {number} [round=DEFAULT_ROUND] - The number of decimal places to round to.
   * @returns {number} The result of the division rounded to the specified precision, or 0 if invalid.
   */
  static divideSafeRound(numerator, denominator, round = DEFAULT_ROUND) {
    const result = Maths._round(this.divide(numerator, denominator), round);
    return result.isNaN() || !result.isFinite() ? 0 : result.toNumber();
  }

  /**
   * Divides one number or Decimal by another.
   *
   * @param {number|Decimal} numerator - The numerator of the division.
   * @param {number|Decimal} denominator - The denominator of the division.
   * @returns {Decimal} The result of the division as a Decimal instance.
   */
  static divide(numerator, denominator) {
    return new Decimal(numerator).div(denominator);
  }

  /**
   * Multiplies two numbers or Decimal instances and rounds the result.
   *
   * @param {number|Decimal} a - The first number to multiply.
   * @param {number|Decimal} b - The second number to multiply.
   * @param {number} [round=DEFAULT_ROUND] - The number of decimal places to round to.
   * @returns {number} The result of the multiplication rounded to the specified precision.
   */
  static multiplyRound(a, b, round = DEFAULT_ROUND) {
    return Maths._round(Maths.multiply(a, b), round).toNumber();
  }

  /**
   * Multiplies two numbers or Decimal instances.
   *
   * @param {number|Decimal} a - The first number to multiply.
   * @param {number|Decimal} b - The second number to multiply.
   * @returns {Decimal} The result of the multiplication as a Decimal instance.
   */
  static multiply(a, b) {
    return new Decimal(a).mul(b);
  }

  /**
   * Adds two numbers or Decimal instances and rounds the result.
   *
   * @param {number|Decimal} a - The first number to add.
   * @param {number|Decimal} b - The second number to add.
   * @param {number} [round=DEFAULT_ROUND] - The number of decimal places to round to.
   * @returns {number} The result of the addition rounded to the specified precision.
   */
  static plusRound(a, b, round = DEFAULT_ROUND) {
    return Maths._round(Maths.plus(a, b), round).toNumber();
  }

  /**
   * Adds two numbers or Decimal instances.
   *
   * @param {number|Decimal} a - The first number to add.
   * @param {number|Decimal} b - The second number to add.
   * @returns {Decimal} The result of the addition as a Decimal instance.
   */
  static plus(a, b) {
    return new Decimal(a).plus(b);
  }

  /**
   * Subtracts two numbers or Decimal instances and rounds the result.
   *
   * @param {number|Decimal} a - The number from which to subtract.
   * @param {number|Decimal} b - The number to subtract.
   * @param {number} [round=DEFAULT_ROUND] - The number of decimal places to round to.
   * @returns {number} The result of the subtraction rounded to the specified precision.
   */
  static minusRound(a, b, round = DEFAULT_ROUND) {
    return Maths._round(Maths.minus(a, b), round).toNumber();
  }

  /**
   * Subtracts the second number or Decimal instance from the first.
   *
   * @param {number|Decimal} a - The number from which to subtract.
   * @param {number|Decimal} b - The number to subtract.
   * @returns {Decimal} The result of the subtraction as a Decimal instance.
   */
  static minus(a, b) {
    return new Decimal(a).minus(b);
  }

  /**
   * This method mimics the behavior of parseFloat, but ensures the resulting number is rounded
   * to the specified number of decimal places. This combines the functionality of parseFloat
   * and rounding in a single step, returning a native JavaScript number.
   *
   * @param {string} value - The string to be converted and rounded.
   * @param {number} [round=DEFAULT_ROUND] - The number of decimal places to round to, default is 10.
   * @returns {number} The rounded number as a native JavaScript number.
   */
  static parseFloatRound(value, round = DEFAULT_ROUND) {
    try {
      const decimalValue = new Decimal(value);
      if (!decimalValue.isFinite()) return NaN;
      return Maths._round(decimalValue, round).toNumber();
    } catch {
      return NaN;
    }
  }

  /**
   * Rounds a Decimal instance to a specified number of decimal places using ROUND_HALF_EVEN.
   *
   * @param {Decimal} number - The number to round.
   * @param {number} round - The number of decimal places to round to.
   * @returns {Decimal} The rounded number as a Decimal instance.
   * @private The 'private' modifier can only be used in TypeScript files.ts(8009)
   */
  static _round(number, round) {
    return number.toDecimalPlaces(round, Decimal.ROUND_HALF_EVEN);
  }
}
