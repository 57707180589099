import { applicationOptions } from './constants';

export const filterItems = (item, value) => {
  return item.name.toLowerCase().includes(value.toLowerCase());
};

export const findTask = (tasks, value) => {
  if (value) {
    const filteredItems = [];
    const foundItem = tasks.filter(item => {
      return filterItems(item, value);
    });
    if (foundItem) {
      foundItem.forEach(fi => {
        filteredItems.push(fi);
      });
    }
    return filteredItems;
  }
  return tasks;
};

export const dateRangeFilter = (tasks, filteredDate) => {
  return tasks.filter(ele => {
    return (
      filteredDate[0].getTime() <= ele.startDateEpoch &&
      filteredDate[1].getTime() >= ele.startDateEpoch
    );
  });
};

export const getApplicationType = selectedApplicationOption =>
  applicationOptions.find(option => option.key === selectedApplicationOption);

export const mapProductsToSave = product => ({
  id: product.productId,
  productId: product.productId,
  productName: product.productName,
  manufacturerName: product.manufacturerName,
  areaUnit: product.areaUnit,
  averagePriceAtTimeOfCreation: product.averagePriceAtTimeOfCreation,
  averagePriceUnitAtTimeOfCreation: product.averagePriceUnitAtTimeOfCreation,
  totalProductValue: product.totalProductValue,
  totalProductUnit: product.totalProductUnit,
  ratePerAreaValue: product.ratePerAreaValue,
  ratePerAreaUnit: product.ratePerAreaUnit,
  ratePerTankValue: product.ratePerTankValue,
  ratePerTankUnit: product.ratePerTankUnit,
  totalCostAtTimeOfCreation: product.totalCostAtTimeOfCreation,
  appliedAreaValue: product.appliedAreaValue,
  appliedAreaUnit: product.appliedAreaUnit,
  applicationMethod: product.applicationMethod,
  targetPest: product.targetPest,
  specificCostPerUnit: product.specificCostPerUnit,
  specificCostUnit: product.specificCostUnit,
  shareOwnerInformation: product.shareOwnerInformation,
  productDensity: product.density,
  custom: product.custom,
  customProductValue: product.customProductValue,
  customProductUnit: product.customProductUnit,
  hasCost: product.hasCost,
  customRateType: product.customRateType,
  associatedProduct: product.associatedProduct,
  coveragePercent: product.coveragePercent / 100
});
