import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Spinner } from '@agconnections/grow-ui';
import { noop, isEmpty, orderBy } from 'lodash';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import useDebounce from 'hooks/useDebounce';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { hasRoleAccess } from 'utilities/access';
import { Context } from 'components/Store';
import { GLOBAL_BACKGROUND_COLOR } from 'reducers/reducer';
import useCropSeasons from 'hooks/useCropSeasons';
import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import LandingSearch from 'components/LandingSearch';
import { paths } from 'routes/paths';
import LandingListViewToggle from 'components/LandingListViewToggle';
import EmptyState from 'components/EmptyState';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { findCrops, dateRangeFilter } from './helpers/dataHelpers';
import CropSeasonTileBoard from './components/CropSeasonTileBoard';
import CropSeasonTable from './components/CropSeasonTable';
import DeleteSeasonModal from './components/DeleteSeasonModal';

const cropsSeasonViewStorageApi = getLocalStorageApi(
  STORAGE_KEYS.CROPSEASON_VIEW,
  'tile'
);
const orderCropSeasons = cropSeasons => orderBy(cropSeasons, 'active', 'desc');
const paginateCropSeasons = (cropSeasonsToPaginate, seasonPageNumber) => {
  const indexOfFirstPost = seasonPageNumber * PAGINATION_SIZE;
  const indexOfLastPost = PAGINATION_SIZE + indexOfFirstPost;
  return cropSeasonsToPaginate.slice(indexOfFirstPost, indexOfLastPost);
};
const CropSeasons = () => {
  const [
    { loggedInUserOrgPermission, loadingOrg, isGlobalCropSeasonLoading },
    dispatch
  ] = useContext(Context);
  const defaultCropSeasonsView = cropsSeasonViewStorageApi.get();
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);

  const { cropSeasons, findCropSeason, reloadCropSeasons } = useCropSeasons();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  // const closeDeleteModal = () => setDeleteModalOpen(false);
  const [seasonToDelete, setSeasonToDelete] = useState(null);
  const [reRenderSeason, setReRenderSeason] = useState(false);
  const [view, setView] = useState(defaultCropSeasonsView);
  const history = useHistory();
  const [filteredCropSeasons, setFilteredCropSeasons] = useState(cropSeasons);
  const [orgLoading, setOrgLoading] = useState(false);
  const [financialAccess, setFinancialAccess] = useState();
  const [seasonPageNumber, setSeasonPageNumber] = useState(0);
  const [cropSeasonsTableFiltered, setCropSeasonsTableFiltered] = useState(
    cropSeasons
  );

  useEffect(() => {
    setOrgLoading(!isEmpty(loggedInUserOrgPermission));
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    if (
      (loggedInUserOrgPermission?.role === 'Full control' ||
        loggedInUserOrgPermission?.role === 'View Only') &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    const orderedSeasons =
      view === 'tile'
        ? orderCropSeasons(cropSeasons)
        : cropSeasonsTableFiltered || cropSeasons;

    if (debouncedSearchTerm) {
      const debouncedSeasons = findCrops(orderedSeasons, debouncedSearchTerm);
      setFilteredCropSeasons(
        paginateCropSeasons(debouncedSeasons, seasonPageNumber)
      );
    } else if (orderedSeasons) {
      const debouncedSeasons = findCrops(orderedSeasons, null);
      setFilteredCropSeasons(
        paginateCropSeasons(debouncedSeasons, seasonPageNumber)
      );
    }
  }, [
    cropSeasons,
    debouncedSearchTerm,
    seasonPageNumber,
    view,
    cropSeasonsTableFiltered
  ]);

  const tableSort = filteredSeasons => {
    setCropSeasonsTableFiltered(filteredSeasons);
    setFilteredCropSeasons(
      paginateCropSeasons(filteredSeasons, seasonPageNumber)
    );
  };

  const filterItems = [
    {
      key: 'Seasons',
      value: 'Seasons'
      // nestedItems: seasonItems
    },
    {
      key: 'Farms',
      value: 'Farms'
      // nestedItems: farmItems
    },
    {
      key: 'Crops',
      value: 'Crops'
      // nestedItems: cropItems
    },
    {
      key: 'Flags',
      value: 'Flags'
      // nestedItems: flagItems
    }
  ];

  useEffect(() => {
    if (view === 'tile') {
      dispatch({
        type: GLOBAL_BACKGROUND_COLOR,
        payload: '#F5F8FC'
      });
    }

    return () => {
      dispatch({
        type: GLOBAL_BACKGROUND_COLOR,
        payload: null
      });
    };
  }, [view, dispatch]);

  const handleChangeView = newView => {
    setView(newView);
    cropsSeasonViewStorageApi.save(newView);
  };

  useEffect(() => {
    if (reRenderSeason) {
      reloadCropSeasons();
      findCropSeason();
      setReRenderSeason(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderSeason]);

  const handleAction = (action, { id, name }) => {
    const actions = {
      view: () => {
        history.push(`${paths.cropSeasons}/${id}/view`);
      },
      edit: () => {
        history.push(`${paths.cropSeasons}/${id}`);
      },
      delete: () => {
        openDeleteModal();
        setSeasonToDelete({ id, name });
        setReRenderSeason(false);
      }
    };
    actions[action]();
  };

  const handleOrgChange = () => {
    setReRenderSeason(true);
    setSeasonPageNumber(0);
    setSearchText(null);
  };

  const handleClose = cropSeasonDeleted => {
    if (cropSeasonDeleted) {
      findCropSeason();
    }
    setDeleteModalOpen(false);
  };

  const handleDateRangeFilter = e => {
    const filteredDate = e.target.value;
    if (filteredDate.length === 2) {
      setFilteredCropSeasons(dateRangeFilter(cropSeasons, filteredDate));
    } else {
      setFilteredCropSeasons(cropSeasons);
    }
  };

  const handlePageChange = page => {
    setSeasonPageNumber(page);
  };

  const setPermission = hasRoleAccess(
    loggedInUserOrgPermission?.role,
    'Full control'
  );

  const showSpinner = () => {
    return loadingOrg || !orgLoading || isGlobalCropSeasonLoading;
  };
  return (
    <div className="w-full h-full" data-testid="cropSeasons">
      <Breadcrumb onOrganizationSelect={handleOrgChange} hideCropSeasonDropdown>
        <Breadcrumb.Item title="Crop Seasons" value="All Crop Seasons" isLast />
      </Breadcrumb>
      {showSpinner() ? (
        <Spinner ariaLabel="spinner" />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <LandingSearch
              id="crop-season-search-input"
              filterItems={filterItems}
              selectedItems={[]}
              name="search_crop_seasons"
              placeholder="Search Crop Seasons"
              onChange={event => {
                setSearchText(event.target.value);
                setSeasonPageNumber(0);
              }}
              onFilterChange={noop}
              onChangeLabel={noop}
              onChangeProductType={noop}
              onChangeTaskType={noop}
              onDateRangeChange={handleDateRangeFilter}
              showCropSeason={false}
            />
            <div className="flex items-center">
              <LandingListViewToggle
                testId="crop-seasons-view-toggle"
                view={view}
                changeView={handleChangeView}
              />
              {setPermission && (
                <div className="pl-2 whitespace-no-wrap">
                  <Button
                    type="primary"
                    id="add-crop-season-button"
                    onClick={event => {
                      event.stopPropagation();
                      history.push(`${paths.cropSeasons}/create`);
                    }}
                    icon={<AddRoundedIcon />}
                  >
                    Create Season
                  </Button>
                </div>
              )}
            </div>
          </div>
          {filteredCropSeasons && cropSeasons.length > 0 ? (
            <div>
              {view === 'tile' ? (
                <CropSeasonTileBoard
                  cropSeasons={filteredCropSeasons}
                  handleAction={handleAction}
                  seasonPageNumber={seasonPageNumber}
                  handlePageChange={handlePageChange}
                  cropSeasonsLength={cropSeasons.length}
                />
              ) : (
                <CropSeasonTable
                  cropSeasons={filteredCropSeasons}
                  allCropSeasons={cropSeasons}
                  tableSort={tableSort}
                  handleAction={handleAction}
                  selectedMember={loggedInUserOrgPermission}
                  financialAccess={financialAccess}
                  seasonPageNumber={seasonPageNumber}
                  handlePageChange={handlePageChange}
                  cropSeasonsLength={cropSeasons.length}
                />
              )}
            </div>
          ) : (
            <EmptyState
              className="w-1"
              buttonText="Create Season"
              onClick={() => history.push(`${paths.cropSeasons}/create`)}
              subtitle="We were unable to find any crop seasons under this organization."
              setPermission={() => setPermission}
            />
          )}
          <DeleteSeasonModal
            open={deleteModalOpen}
            close={handleClose}
            seasonName={seasonToDelete?.name}
            seasonId={seasonToDelete?.id}
          />
        </>
      )}
    </div>
  );
};

export default CropSeasons;
