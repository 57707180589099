import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import { DatePicker } from '@agconnections/grow-ui';

import useCropSeasons from 'hooks/useCropSeasons';

import CropSeasonDropdown from 'components/CropSeasonDropdown';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';

import { determineIntegrationContext } from 'screens/Integrations/helpers/determineIntegrationContext';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';

const SubmissionCropSeason = ({ context, FooterComponent }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { setSelectedCropSeasons, selectedCropSeasons } = useFormikHandler();

  const { updateCropSeasons } = useContext(SubmissionSummaryContext);
  const { goForward } = useContext(determineIntegrationContext(context));

  const { cropSeasons, loaded } = useCropSeasons();

  const handleCropSeasonSelect = seasonsSelected => {
    setSelectedCropSeasons(seasonsSelected);
  };

  const onNextHandler = () => {
    updateCropSeasons(selectedCropSeasons);
    triggerAnalyticsEvent(events.epic.Integrations.submissionSeasonSelected, {
      vendor: context
    });
    goForward();
  };

  return (
    <div
      className="flex flex-col bg-white top-30 left-26 w-140 rounded-md mb-20 p-6 shadow-sm"
      data-testid="submission-crop-season-selection"
    >
      <h1 className="mb-6 ml-8 font-body text-base font-normal leading-8 tracking-tight text-left">
        Add crop seasons for your submission.
      </h1>
      <div className="w-2/3 ml-8 flex-1 h-full">
        <CropSeasonDropdown
          selectedActive={false}
          allCropSeasonsData={cropSeasons}
          csLoaded={loaded}
          onSelect={handleCropSeasonSelect}
          isRequired
        />
        <div className="flex flex-row my-16 pb-6 gap-20">
          <GrowUIFormField
            label="Start Date"
            name="cropSeasonsMinDate"
            control={DatePicker}
            disabled
          />
          <GrowUIFormField
            label="End Date"
            name="cropSeasonsMaxDate"
            control={DatePicker}
            disabled
          />
        </div>
      </div>
      {FooterComponent && (
        <FooterComponent
          onNext={onNextHandler}
          nextDisabled={
            !selectedCropSeasons || selectedCropSeasons.length === 0
          }
        />
      )}
    </div>
  );
};

SubmissionCropSeason.propTypes = {
  context: PropTypes.string.isRequired,
  FooterComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ])
};

SubmissionCropSeason.defaultProps = {
  FooterComponent: null
};

export default SubmissionCropSeason;
