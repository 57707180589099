// LDBAPP-2717: Sort the returned array alphanumerically on the client side before populating the crops search tab
const alphanumSort = arr => {
  arr.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  return arr;
};

export default alphanumSort;
