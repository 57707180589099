import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Spinner } from '@agconnections/grow-ui';
import RemoveTaskModal from 'screens/Integrations/SubmissionSummary/components/RemoveTaskCropzoneModal';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import SubmitHeader from 'screens/Integrations/SubmissionSummary/components/TopHeader/components/SubmitHeader';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { SubmissionSummaryContext } from '../../../SubmissionSummary/context/SubmissionSummaryProvider';
import CalAgFormAccordion from './components/CalAgFormAccordion';
import returnApplicationMethodLabel from '../../helpers/products';
import CalAgCountiesDrawer from './components/CalAgCountiesDrawer';
import SummaryTag from './components/SummaryTag';

const CalAgReviewAndSubmit = ({ FooterComponent, loading }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();

  const {
    state: {
      tasks,
      fields,
      extraData,
      isReadOnly,
      submissionResult,
      openDrawerCounties,
      pastSubmissions
    },
    isLoading,
    setOpenDrawerCounties
  } = useContext(SubmissionSummaryContext);

  const { values, setValues } = useFormikContext();
  const [selectedId, setSelectedId] = useState('');
  const [forms, setForms] = useState([]);
  const [needInfoTask, setNeedInfoTask] = useState(0);

  useEffect(() => {
    setNeedInfoTask(forms.filter(f => f.status === 'need_info').length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const transformTaskToForm = task => {
    if (task?.taskId) {
      const applicationMethodsInTask = returnApplicationMethodLabel(
        task.products
      );
      const formId = `form-task-${task.taskId}-${task.cropZoneId}-${applicationMethodsInTask}`;

      let currentForm;
      if (forms) {
        currentForm = forms.find(form => form.formId === formId);
      }

      if (extraData) {
        const { forms: submitedForms } = extraData.purExtraData;

        const submitedForm = submitedForms.find(
          f =>
            formId ===
            `form-task-${f.taskId}-${f.cropZoneId}-${f.applicationMethod}`
        );
        if (submitedForm) {
          currentForm = { ...currentForm, ...submitedForm };
        }
      }
      let status = 'need_info';
      let errorMessage;

      if (
        isReadOnly &&
        submissionResult?.status === 'error' &&
        submissionResult?.response !== null
      ) {
        const taskWithError = submissionResult?.response.details.find(
          errorDetail =>
            errorDetail.responseData.taskIds.filter(t => t === task.taskId)
        );
        if (taskWithError) {
          status = 'error';
          errorMessage = taskWithError.errorMessage;
        }
      }

      return {
        formId,
        task,
        applicationMethods: applicationMethodsInTask,
        propertyDetails: fields.find(f => f.cropZoneId === task.cropZoneId),
        status,
        errorMessage,
        ...currentForm
      };
    }
    return {};
  };

  const getProductsByApplicationMethod = (products, applicationMethod) =>
    products.filter(prod => prod.applicationMethod === applicationMethod);

  const splitTasksByApplicationMethod = tasksToBeSplited => {
    return tasksToBeSplited.reduce((acc, cur) => {
      if (!cur.products || (cur.products && cur.products.length === 1)) {
        return acc.concat([cur]);
      }

      const splitedTaks = [];
      const applicationMethods = [
        ...new Set(cur.products.map(p => p.applicationMethod))
      ];
      applicationMethods.forEach(applicationMethod => {
        splitedTaks.push({
          ...cur,
          products: getProductsByApplicationMethod(
            cur.products,
            applicationMethod
          )
        });
      });

      return acc.concat(splitedTaks);
    }, []);
  };

  useEffect(() => {
    if (tasks && tasks.length > 0 && fields && fields.length > 0) {
      setForms(
        splitTasksByApplicationMethod(tasks).map(task =>
          transformTaskToForm(task)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, fields]);

  const validateMandatoryAttributesForm = form => {
    const mappedKeys = new Map(Object.entries(form));
    const requiredFields = [
      'task',
      'propertyDetails',
      'formId',
      'applicatorType'
    ];
    if (!requiredFields.every(key => mappedKeys.get(key))) {
      return false;
    }

    if (form.applicatorType === 'pco') {
      const requiredPcoFields = ['pco', 'pcoType', 'pcoNumber'];
      if (!requiredPcoFields.every(key => mappedKeys.get(key))) {
        return false;
      }
    }

    if (form.task.products[0].applicationMethod === 'Fumigation') {
      const requiredFumigationFields = ['fumigationCode'];
      if (!requiredFumigationFields.every(key => mappedKeys.get(key))) {
        return false;
      }
    }

    return true;
  };

  const updateForms = formIdToUpdate => {
    const newForms = [...forms].map(form => {
      if (form.status === 'error') {
        return form;
      }

      if (formIdToUpdate === form.formId) {
        const {
          applicatorType,
          pco,
          pcoType,
          pcoNumber,
          fumigationCode
        } = values;
        const newForm = {
          ...form,
          applicatorType,
          pco,
          pcoType,
          pcoNumber,
          fumigationCode
        };

        return {
          ...newForm,
          status: validateMandatoryAttributesForm(newForm)
            ? 'complete'
            : 'need_info'
        };
      }

      return form;
    });

    setForms(newForms);
    return newForms;
  };

  useEffect(() => {
    if (selectedId !== '') {
      updateForms(selectedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const expandForm = form => {
    if (selectedId === form.formId) {
      setValues({
        ...values,
        applicatorType: '',
        pco: {},
        pcoType: '',
        pcoNumber: '',
        fumigationCode: ''
      });
      setSelectedId('');
    } else {
      setValues({
        ...values,
        applicatorType: '',
        pco: '',
        pcoType: '',
        pcoNumber: '',
        fumigationCode: '',
        ...form
      });
      setSelectedId(form.formId);
    }
  };

  const onNextHandler = async () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.submissionApplicatorDetailsFilled,
      {
        vendor: INTEGRATIONS.calAgPermits
      }
    );
    setOpenDrawerCounties(true);
  };

  const renderSummaryTag = () => (
    <SummaryTag isLoading={isLoading} missingInfoAmount={needInfoTask} />
  );

  return (
    <>
      <div
        className="flex flex-col bg-white top-30 left-26 w-full rounded-md mb-20 px-6 py-4 shadow-sm"
        data-testid="submission-crop-season-selection"
      >
        <SubmitHeader
          integrationType={INTEGRATIONS.calAgPermits}
          submissionResult={submissionResult}
          subtitle="Review details and enter any missing information."
          RightComponent={renderSummaryTag}
        />

        {!openDrawerCounties && (isLoading || loading) ? (
          <Spinner />
        ) : (
          <>
            {forms.map(form => (
              <CalAgFormAccordion
                form={form}
                toggleCallback={() => expandForm(form)}
                selectedId={selectedId}
                key={form.formId}
                isReadOnly={isReadOnly}
                pastSubmissions={pastSubmissions}
              />
            ))}
          </>
        )}
        {!isReadOnly && FooterComponent && (
          <FooterComponent
            onNext={onNextHandler}
            nextLabel="Submit"
            nextDisabled={needInfoTask !== 0}
          />
        )}
      </div>
      <RemoveTaskModal
        onRemove={() => {
          setSelectedId('');
        }}
      />
      <CalAgCountiesDrawer forms={forms} />
    </>
  );
};

CalAgReviewAndSubmit.defaultProps = {
  FooterComponent: null,
  loading: false
};

CalAgReviewAndSubmit.propTypes = {
  FooterComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ]),
  loading: PropTypes.bool
};

export default CalAgReviewAndSubmit;
