import { useContext, useState, useCallback } from 'react';
import { invoice as invoiceApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

const useInvoiceData = id => {
  const [, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(!!id);
  const [saving, setSaving] = useState(false);
  const [invoice, setInvoice] = useState();
  const [error, setError] = useState('');
  const fetchInvoice = useCallback(
    (invoiceID = id, redirectOnError) => {
      const { promise, cancel } = invoiceApi.fetch(invoiceID);
      promise
        .then(({ data }) => {
          setInvoice(data);
        })
        .catch(catchCancel)
        .catch(parseServerError(dispatch, { showTryAgain: !!redirectOnError }))
        .finally(() => {
          setLoading(false);
        });
      return { promise, cancel };
    },
    [dispatch, id]
  );

  const saveInvoice = values => {
    const { promise } = invoiceApi.create(values);
    return promise
      .catch(catchCancel)
      .catch(err => {
        setSaving(false);
        if (err.response?.data?.validationErrors) {
          setError(`Error saving invoice: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const updateInvoice = values => {
    const { promise } = invoiceApi.update(values.id, values);
    return promise
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => {
        setSaving(false);
      });
  };

  return {
    saving,
    error,
    saveInvoice,
    updateInvoice,
    fetchInvoice,
    setError,
    loading,
    invoice
  };
};

export default useInvoiceData;
