import React from 'react';
import { Column, Columns, Input, Select, Search } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import ChemicalElementsSection from '../ChemicalElementsSection';

const fertilizerLiquidStandardUnitsList = [
  { key: 'gallons', value: 'gallons' },
  { key: 'quarts', value: 'quarts' },
  { key: 'tons', value: 'tons' },
  { key: 'pounds', value: 'pounds' },
  { key: 'acre inches', value: 'acre inches' },
  { key: 'acres', value: 'acres' },
  { key: 'liters', value: 'liters' },
  { key: 'metric tons', value: 'metric tons' },
  { key: 'kilograms', value: 'kilograms' }
  // { key: 'hectars', value: 'hectares' }
];
const fertilizerSolidStandardUnitsList = [
  { key: 'tons', value: 'tons' },
  { key: 'pounds', value: 'pounds' },
  { key: 'acres', value: 'acres' },
  { key: 'metric tons', value: 'metric tons' },
  { key: 'kilograms', value: 'kilograms' }
  // { key: 'hectars', value: 'hectares' }
];

const FertilizerProductForm = () => {
  const { values } = useFormikContext();

  const handleStandardUnitListPopulation = (f, m) => {
    let items = fertilizerSolidStandardUnitsList;
    if (f !== 'Service' && m === 'Liquid')
      items = fertilizerLiquidStandardUnitsList;
    return items;
  };
  return (
    <div>
      <div name="Fertilizer">
        <div className="mt-12">
          <Columns wrap>
            <Column width="w-1/2">
              <div className="pr-2">
                {/* <Select id="make-up-select" label="Make-Up" /> */}
                <GrowUIFormField
                  id="makeupselect"
                  label="Make-Up"
                  control={Select}
                  name="makeupselect"
                  placeholder="Select"
                  data-testid="makeupselect"
                  items={[
                    { key: 'Solid', value: 'Solid' },
                    { key: 'Liquid', value: 'Liquid' }
                  ]}
                />
              </div>
            </Column>
            <Column width="w-1/2">
              <div className="pl-2">
                {/* <Select id="standard-unit-select" label="Standard Unit" /> */}
                <div>
                  <GrowUIFormField
                    id="standardunitselect"
                    label="Standard Unit"
                    control={Search}
                    name="standardunitselect"
                    placeholder="Select Standard Unit"
                    data-testid="standard-unit-select"
                    items={handleStandardUnitListPopulation(
                      values.fertilizerService,
                      values.makeupselect
                    )}
                  />
                </div>
              </div>
            </Column>
          </Columns>
        </div>
        <div className="mt-12">
          <Columns wrap>
            <Column width="w-1/2">
              <div className="pr-2">
                {/* <Select id="density-select" label="Density (lb / gal)" /> */}
                <GrowUIFormField
                  control={Input}
                  id="densityselect"
                  name="densityselect"
                  label="Density (lb / gal)"
                />
              </div>
            </Column>
          </Columns>
        </div>
        <ChemicalElementsSection />
      </div>
    </div>
  );
};
export default FertilizerProductForm;
