/* eslint-disable no-else-return */
import React from 'react';
import { cloneDeep } from 'lodash';
import { Input, Select } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

import GrowUIFormField from 'components/Forms/GrowUIFormField';

const TopOptions = ({
  values,
  setFieldValue,
  applicationOptions,
  setSelectedApplicationOption,
  isEditing
}) => {
  setSelectedApplicationOption(values.applicationStrategy);
  const roundValue = valueToRound => {
    return Math.round((valueToRound + Number.EPSILON) * 100) / 100;
  };

  const recalcTotalProductValues = countChange => {
    const newProducts = [];
    // eslint-disable-next-line no-unused-expressions
    values?.products?.forEach(product => {
      const newProduct = cloneDeep(product);
      newProduct.ratePerTankValue = roundValue(
        product.totalProductValue / countChange
      );
      newProducts.push(newProduct);
    });

    setFieldValue('products', newProducts);
  };
  const tankSizeChange = sizeChange => {
    if (isEditing) {
      // eslint-disable-next-line radix
      if (Number(sizeChange)) {
        const tankCountCal = Math.round(
          Number(values?.tankInformation?.totalCarrierValue) /
            Number(sizeChange)
        );
        setFieldValue('tankInformation.tankSizeValue', sizeChange);
        setFieldValue('tankInformation.tankCount', tankCountCal);
      }
    } else {
      let totalCarrierValue = 0;
      if (Number.parseFloat(sizeChange)) {
        totalCarrierValue =
          Number(sizeChange) * Number(values?.tankInformation?.tankCount);
        setFieldValue(
          'tankInformation.totalCarrierValue',
          roundValue(totalCarrierValue)
        );
      }
      if (Number.parseFloat(values?.totalAreaValue) > 0) {
        const carrierPerArea = totalCarrierValue / values?.totalAreaValue;
        setFieldValue(
          'tankInformation.carrierPerAreaValue',
          roundValue(carrierPerArea)
        );
      }
      setFieldValue('tankInformation.tankSizeValue', sizeChange);
    }
  };

  const tankCountChange = countChange => {
    let totalCarrierValue = 0;
    if (countChange !== '' && countChange !== '0') {
      if (Number.parseFloat(values?.tankInformation?.tankSizeValue)) {
        totalCarrierValue =
          Number(values?.tankInformation?.tankSizeValue) * Number(countChange);
        setFieldValue(
          'tankInformation.totalCarrierValue',
          roundValue(totalCarrierValue)
        );
      }
      if (Number.parseFloat(values?.totalAreaValue) > 0) {
        const carrierPerArea = totalCarrierValue / values?.totalAreaValue;
        setFieldValue(
          'tankInformation.carrierPerAreaValue',
          roundValue(carrierPerArea)
        );
      }
    }
    setFieldValue('tankInformation.tankCount', countChange);
    recalcTotalProductValues(countChange);
  };

  const carrierPerAreaChange = carrierChange => {
    let totalCarrierValue = 0;
    if (Number.parseFloat(carrierChange)) {
      totalCarrierValue =
        Number(carrierChange) * Number(values?.totalAreaValue);
      setFieldValue(
        'tankInformation.totalCarrierValue',
        roundValue(totalCarrierValue)
      );
    }
    if (Number.parseFloat(values?.tankInformation?.tankSizeValue) > 0) {
      const tankCount =
        totalCarrierValue / Number(values?.tankInformation?.tankSizeValue);
      setFieldValue('tankInformation.tankCount', roundValue(tankCount));
      recalcTotalProductValues(tankCount);
    }
    setFieldValue('tankInformation.carrierPerAreaValue', carrierChange);
  };

  const totalCarrierChange = totalChange => {
    if (Number.parseFloat(values?.tankInformation?.tankSizeValue) > 0) {
      const tankCount =
        Number(totalChange) / Number(values?.tankInformation?.tankSizeValue);
      setFieldValue('tankInformation.tankCount', roundValue(tankCount));
      recalcTotalProductValues(tankCount);
    }
    if (Number.parseFloat(values?.totalAreaValue)) {
      const carrierPerArea =
        Number(totalChange) / Number(values?.totalAreaValue);
      setFieldValue(
        'tankInformation.carrierPerAreaValue',
        roundValue(carrierPerArea)
      );
    }
    setFieldValue('tankInformation.totalCarrierValue', totalChange);
  };
  return (
    <>
      <div className="flex grow">
        <div className="w-1/4">
          <span
            className="flex items-center text-neutral-600"
            style={{ fontSize: '0.95vw' }}
            data-testid="application-options-select"
          >
            Application Options
          </span>
          <div className="w-full">
            <GrowUIFormField
              control={Select}
              name="applicationStrategy"
              // label="Application Options"
              items={applicationOptions}
              onChange={e =>
                setFieldValue(
                  'applicationStrategy',
                  JSON.parse(e.target.value).key
                )
              }
            />
          </div>
        </div>
        <div className="w-3/4 flex justify-between">
          <div className="" style={{ paddingLeft: '2.5%' }}>
            <span
              className="flex items-center text-neutral-600"
              style={{ fontSize: '0.95vw' }}
              data-testid="tank-size-input"
            >
              Tank Size
            </span>
            <GrowUIFormField
              control={Input}
              // label="Tank Size"
              name="tankInformation.tankSizeValue"
              value={values?.tankInformation?.tankSizeValue}
              onChange={e => tankSizeChange(e.target.value)}
              disabled={values?.totalAreaValue === 0}
            />
          </div>
          <div className="" style={{ paddingLeft: '2.5%' }}>
            <span
              className="flex items-center text-neutral-600"
              style={{ fontSize: '0.95vw' }}
              data-testid="tank-count-input"
            >
              Tank Count
            </span>
            <GrowUIFormField
              control={Input}
              // label="Tank Count"
              name="tankInformation.tankCount"
              value={values?.tankInformation.tankCount}
              onChange={e => tankCountChange(e.target.value)}
              disabled={values?.totalAreaValue === 0}
            />
          </div>
          <div className="" style={{ paddingLeft: '2.5%' }}>
            <span
              className="flex items-center text-neutral-600"
              style={{ fontSize: '0.95vw' }}
              data-testid="total-carrier-input"
            >
              Total Carrier
            </span>
            <GrowUIFormField
              control={Input}
              // label="Total Carrier"
              name="tankInformation.totalCarrierValue"
              value={values?.tankInformation?.totalCarrierValue}
              onChange={e => totalCarrierChange(e.target.value)}
              disabled={values?.totalAreaValue === 0}
            />
          </div>
          <div className="" style={{ paddingLeft: '2.5%' }}>
            <span
              className="flex items-center text-neutral-600"
              style={{ fontSize: '0.95vw' }}
              data-testid="carrier-by-area-input"
            >
              Carrier per Area
            </span>
            <GrowUIFormField
              control={Input}
              // label="Carrier per Area"
              name="tankInformation.carrierPerAreaValue"
              valule={values?.tankInformation?.carrierPerAreaValue}
              onChange={e => carrierPerAreaChange(e.target.value)}
              disabled={values?.totalAreaValue === 0}
            />
          </div>
        </div>
      </div>
      {values?.totalAreaValue === 0 && (
        <div className="mt-2">
          You must select a property to edit tank information
        </div>
      )}
    </>
  );
};

TopOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  applicationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  setSelectedApplicationOption: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired
};

export default TopOptions;
