import { useState, useEffect, useCallback } from 'react';
import { invoices as invoicesApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';

const useInventoryInvoiceData = prodId => {
  const [invoices, setInvoices] = useState([]);
  const [invsLoading, setInvsLoading] = useState(true);
  const [invsError, setInvsError] = useState(null);

  const getAllInvoices = useCallback(productId => {
    const { promise } = invoicesApi.fetch(`?size=${10000}`);
    promise
      .then(data => {
        const apiInvoices = data.data.results.filter(inv => {
          const hasProduct =
            inv.invoiceProducts.filter(
              prod => prod.productId === productId || prod.id === productId
            ).length > 0;

          return hasProduct ? inv : null;
        });
        setInvoices(apiInvoices);
        setInvsLoading(false);
      })
      .catch(err => {
        setInvsError(err);
        setInvsLoading(false);
      })
      .catch(catchCancel);
  }, []);

  useEffect(() => {
    setInvsLoading(true);
    getAllInvoices(prodId);
  }, [prodId, getAllInvoices]);

  return { invoices, invsLoading, invsError };
};

export default useInventoryInvoiceData;
