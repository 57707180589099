import { useCallback, useContext, useState } from 'react';

import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';

import { organizations as orgsApi } from 'utilities/api';
import { CLIENT_SIDE_MODEL } from 'utilities/helpers/searchModels';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';
import { sortObjectsByAttribute } from './useOrganizationV2';

const useOrganizations = () => {
  const [filteredOrganizations, setOrganizations] = useState();
  const [loaded, setLoaded] = useState(false);
  const [defaultOrg, setDefaultOrg] = useState();
  const [loadedDefaultOrg, setLoadedDefaultOrg] = useState(false);
  const [{ organizations }, dispatch] = useContext(Context);

  const sortByNameOrganizations = (a, b) => {
    const orgNameA = a.name.toUpperCase();
    const orgNameB = b.name.toUpperCase();
    if (orgNameA < orgNameB) return -1;
    if (orgNameA > orgNameB) return 1;
    return 0;
  };

  const findOrganization = useCallback(
    term => {
      setLoaded(false);
      const { promise } = orgsApi.find(
        {
          filters: [
            {
              field: 'name',
              value: name =>
                !term || name.toLowerCase().includes(term.toLowerCase()),
              type: 'matches'
            }
          ]
        },
        {
          serverSearchModel: CLIENT_SIDE_MODEL,
          containerName: 'results'
        }
      );
      return promise
        .then(({ data }) => {
          const validOrgs =
            data?.filter(
              org =>
                !(
                  (org.properties?.status !== 200)
                  // || org.members?.status !== 200
                )
            ) || [];

          validOrgs.sort(sortByNameOrganizations);

          setOrganizations(validOrgs);
          dispatch({
            type: 'SET_ORGANIZATIONS',
            payload: validOrgs
          });
          setLoaded(true);
        })

        .catch(catchCancel)
        .catch(() => {
          parseServerError(dispatch);
          setLoaded(true);
        });
    },
    [dispatch]
  );

  const getDefaultOrg = useCallback((pageNo, size) => {
    setLoadedDefaultOrg(false);
    const promise = axios.get(`${CROPWISE_PROXY_URL}/v1/base/organizations`, {
      params: { pageNo, size },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    });

    return promise
      .then(({ data }) => {
        if (data.results.length) {
          const mappedResults = data.results.map(({ id, name }) => ({
            id,
            name
          }));
          setDefaultOrg(sortObjectsByAttribute(mappedResults, 'name'));
        } else {
          setDefaultOrg(null);
        }
      })
      .catch(catchCancel)
      .catch(parseServerError(dispatch))
      .finally(() => setLoadedDefaultOrg(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    organizations,
    getDefaultOrg,
    loadedDefaultOrg,
    defaultOrg,
    loaded,
    findOrganization,
    filteredOrganizations: filteredOrganizations || organizations
  };
};

export default useOrganizations;
