import React, { useEffect, useState } from 'react';
import { Column, Columns, Select } from '@agconnections/grow-ui';
import { ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { convertDensityFromLbsPerGalToKgPerL } from 'screens/ProductList/helpers/convertDensity';
import GrowUIFormField from 'components/Forms/GrowUIFormField';

import FormInputWithPrecisionDisplay from 'screens/YieldV2/AddYield/components/FormInputWithPrecisionDisplay';
import ChemicalElementsSection from '../ChemicalElementsSection';
import { fertilizerSchema, fertilizerLiquidSchema } from '../../schema';

export const fertilizerLiquidStandardUnitsList = [
  { key: 'gallon', value: 'gallon' },
  { key: 'quart', value: 'quart' },
  { key: 'ton', value: 'ton' },
  { key: 'pound', value: 'pound' },
  { key: 'acre inch', value: 'acre inch' },
  { key: 'acre', value: 'acre' },
  { key: 'liter', value: 'liter' },
  { key: 'metric ton', value: 'metric ton' },
  { key: 'kilogram', value: 'kilogram' }
  // { key: 'hectar', value: 'hectare' }
];
export const fertilizerSolidStandardUnitsList = [
  { key: 'ton', value: 'ton' },
  { key: 'pound', value: 'pound' },
  { key: 'acre', value: 'acre' },
  { key: 'metric ton', value: 'metric ton' },
  { key: 'kilogram', value: 'kilogram' }
  // { key: 'hectar', value: 'hectare' }
];

export const DENSITY_LABEL = 'Density (lb / gal)';

const FertilizerProductForm = ({ setValidationSchema }) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    validateField,
    validateForm
  } = useFormikContext();
  const [densityLabel, setDensityLabel] = useState(DENSITY_LABEL);

  const handleStandardUnitListPopulation = (f, m) => {
    let items = fertilizerSolidStandardUnitsList;
    if (f !== 'Service' && m === 'Liquid')
      items = fertilizerLiquidStandardUnitsList;
    return items;
  };

  useEffect(() => {
    if (values.makeUp === 'Liquid') {
      setValidationSchema(fertilizerLiquidSchema);
      setDensityLabel(`${DENSITY_LABEL}*`);
      validateForm();
    } else {
      setValidationSchema(fertilizerSchema);
      setDensityLabel(DENSITY_LABEL);
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.makeUp]);

  useEffect(() => {
    if (!values?.densityInLbsPerGallon) {
      setFieldValue('density');
      setFieldTouched('density');
      validateField('density');
      return;
    }
    setFieldValue(
      'density',
      convertDensityFromLbsPerGalToKgPerL(values.densityInLbsPerGallon)
    );
    setFieldTouched('density');
    validateField('density');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.densityInLbsPerGallon, setFieldValue]);

  return (
    <div>
      <div name="Fertilizer" data-testid="fertilizer-tab">
        <div className="mt-12">
          <Columns wrap>
            <Column width="w-1/2">
              <div className="pr-2">
                <GrowUIFormField
                  id="makeUp"
                  label="Make-Up*"
                  control={Select}
                  name="makeUp"
                  placeholder="Select Make-Up"
                  data-testid="makeupselect"
                  items={[
                    { key: 'Solid', value: 'Solid' },
                    { key: 'Liquid', value: 'Liquid' }
                  ]}
                />
              </div>
            </Column>
            <Column width="w-1/2">
              <div className="pl-2">
                <GrowUIFormField
                  id="standardUnit"
                  label="Standard Unit*"
                  control={Select}
                  name="standardUnit"
                  placeholder="Select Standard Unit"
                  data-testid="standard-unit-select"
                  items={handleStandardUnitListPopulation(
                    values?.type,
                    values?.makeUp
                  )}
                />
              </div>
            </Column>
          </Columns>
        </div>
        <div className="mt-8">
          <Columns wrap>
            <Column width="w-1/2">
              <div className="pr-2">
                <div className="text-neutral-60 flex">
                  {densityLabel}
                  {values.makeUp === 'LIQUID' ? (
                    <div className="text-remove">*</div>
                  ) : null}
                </div>
                <FormInputWithPrecisionDisplay
                  id="densityInLbsPerGallon"
                  fieldName="densityInLbsPerGallon"
                />
                <ErrorMessage
                  name="density"
                  component="div"
                  className="text-remove"
                />
              </div>
            </Column>
          </Columns>
        </div>
        <ChemicalElementsSection />
      </div>
    </div>
  );
};
export default FertilizerProductForm;

FertilizerProductForm.propTypes = {
  setValidationSchema: PropTypes.func.isRequired
};
