import React, { useContext, useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import NoDocumentIcon from 'assets/no_documents_found.svg';
import axios from 'axios';
import LandingTable from 'components/LandingTable';
import CwFPagination from 'components/CwFPagination';
import PAGINATION_SIZE from 'helpers/globalConstants';
import multiLevelSort from 'helpers/multiLevelSort';
import useClientSidePagination from 'screens/Property/hooks/useClientSidePagination';
import EmptyTableState from 'components/EmptyTableState';
import HistoryIcon from '../../../../../assets/historyIcon.svg';
import { CROPWISE_PROXY_URL } from '../../../../../utilities/apiConstants';
import { getAccessToken } from '../../../../../utilities/base-auth';
import catchCancel from '../../../../../helpers/catchCancel';
import { Context } from '../../../../../components/Store';
import useFieldData from '../../../hooks/useFieldData';
import { parseServerError } from '../../../../../helpers/errorHelpers';
import PropertyCropHistoryRow from './components/PropertyCropHistoryRow';
import cropHistoryTableSort from './helpers/cropHistoryTableSort';
import { PROPERTY_CROPHISTORY_TABLE } from './constants';
import PropertyCardHeader from '../PropertyCardHeader';

const { columns, defaultSort } = PROPERTY_CROPHISTORY_TABLE;

const PropertyCropHistory = ({ id, level = 'field' }) => {
  const isField = level === 'field';

  const {
    currentPageData,
    pageNo,
    totalItems,
    handlePageChange,
    updateAllData
  } = useClientSidePagination(PAGINATION_SIZE);

  const [sortField, setSortField] = useState(defaultSort.by);
  const [sortDir, setSortDir] = useState(defaultSort.dir);

  const { field } = useFieldData(id);

  const [loading, setLoading] = useState(true);

  const [{ organization }, dispatch] = useContext(Context);

  const handleSort = useCallback(
    (tableField, dir = 'asc') => {
      const newSortBy = [tableField];
      const newSortDir = [dir];
      setSortField(newSortBy);
      setSortDir(newSortDir);
      updateAllData(prevAllData =>
        multiLevelSort({
          items: prevAllData,
          sortBy: newSortBy,
          sortDir: newSortDir,
          sorter: cropHistoryTableSort
        })
      );
    },
    [updateAllData]
  );

  const getCropHistory = async () => {
    setLoading(true);
    const promise = axios.get(
      `${CROPWISE_PROXY_URL}/v2/base/field/${id}/crop-varieties`,
      {
        headers: {
          'cwf-context': JSON.stringify({
            organization_id: organization.id
          }),
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
    );

    await promise
      .then(({ data }) => {
        const sortedData = multiLevelSort({
          items: data,
          sortBy: defaultSort.by,
          sortDir: defaultSort.dir,
          sorter: cropHistoryTableSort
        });
        updateAllData(sortedData);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCropHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isEverythingLoaded = () => {
    return !loading;
  };

  return (
    <div>
      <PropertyCardHeader
        title="Crop History"
        description={`All crops ever planted on field ${field?.name}`}
        icon={HistoryIcon}
      />
      {isEverythingLoaded() ? (
        <div>
          {totalItems > 0 ? (
            <div
              className="cwf-property-sticky-table min-w-content"
              data-testid="crophistory-table"
            >
              <LandingTable
                key={pageNo}
                listType="Crop History"
                items={currentPageData || []}
                tableColumns={columns}
                onSort={handleSort}
                sortBy={sortField[0]}
                sortDir={sortDir[0]}
                wrapperClassName={
                  currentPageData.length <= 5 ? 'h-full' : 'h-330px'
                }
              >
                {({ rows }) =>
                  rows.map(entry => (
                    <PropertyCropHistoryRow
                      key={uuidv4()}
                      data={entry}
                      isField={isField}
                    />
                  ))
                }
              </LandingTable>
              {CwFPagination(totalItems, pageNo, handlePageChange)}
            </div>
          ) : (
            <EmptyTableState
              title="No Crop History Found"
              overlayIcon={NoDocumentIcon}
            />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

PropertyCropHistory.propTypes = {
  id: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired
};

export default PropertyCropHistory;
