/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from 'utilities/base-auth';
import { Spinner, Tabs, Toast } from '@agconnections/grow-ui';
import {
  invoice as invoiceApi,
  organization as organizationApi
} from 'utilities/api';
import { Context } from 'components/Store';
import { parseServerError } from 'helpers/errorHelpers';
import catchCancel from 'helpers/catchCancel';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { paths } from 'routes/paths';
import TopHeader from './components/TopHeader';
import InvoiceDetailsTab from './components/InvoiceDetailsTab';
import InvoiceProductsTab from './components/InvoiceProductsTab';

import schema from './schema';
import useInvoiceData from './hooks/useInvoiceData';

export const defaultValues = {
  invoiceNumber: '',
  vendor: '',
  invoiceDate: new Date(Date.now()),
  invoiceDueDate: '',
  cropSeason: JSON.parse(localStorage.getItem('selectedCropSeasons')) || [],
  notes: '',
  flagIds: [],
  invoiceProducts: []
};

export const fetchInvoiceById = (invID, setInvoice, setLoading = () => {}) => {
  const getInvoiceApi = invoiceApi.createChildApi({
    action: `invoice/${invID}`
  });
  const { promise } = getInvoiceApi.fetch();
  promise
    .then(({ data }) => {
      if (data) {
        const inv = {
          ...data,
          invoiceDate: new Date(data?.invoiceDateEpoch),
          invoiceDueDate: new Date(data?.invoiceDueDate),
          cropSeason: data?.cropSeasons?.[0]?.seasonId,
          flagIds: data.flagIds?.reduce((flagIds, flagId) => {
            return `${flagIds},${flagId}`;
          })
        };
        if (inv?.invoiceDueDate === '01/01/1970') {
          delete inv?.invoiceDueDate;
          delete inv?.invoiceDueDateEpoch;
        }
        setInvoice(inv);
      }
      setLoading(false);
    })
    .catch(catchCancel)
    .catch(() => {
      setLoading(false);
    });
};

const Invoice = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const [invoice, setInvoice] = useState(defaultValues);
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useContext(Context);
  const [toastRenderContents, setToastRenderContents] = useState(null);
  const [toastHasNotBeenClosed, setToastHasNotBeenClosed] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [financialAccess, setFinancialAccess] = useState();
  const [selectedCropSeasonIds, setSelectedCropSeasonIds] = useState(
    JSON.parse(localStorage.getItem('selectedCropSeasons')) || null
  );
  const updateSelectedCropSeasons = () => {
    setSelectedCropSeasonIds(
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || null
    );
  };
  const history = useHistory();
  let invID = '';
  if (id?.length > 0) invID = id;
  else {
    invID = 'invoice';
  }
  const isCreateScreen = id === 'invoice' || false;
  const isEdit = id === invoice?.id || false;

  const { saveInvoice, updateInvoice, error, setError } = useInvoiceData(invID);
  useEffect(() => {
    fetchInvoiceById(invID, setInvoice, setLoading);
  }, [dispatch, invID]);

  const getSingleMember = useCallback(async () => {
    const { promise } = organizationApi.fetch();
    await promise
      .then(({ data }) => {
        const filterMember = data?.members?.filter(
          eachMember => eachMember.email === user.email
        )[0];
        setSelectedMember(filterMember);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      });
  }, [dispatch, user.email]);

  useEffect(() => {
    if (!selectedMember) {
      getSingleMember();
    }

    if (
      selectedMember?.role === 'Full control' &&
      !Object.prototype.hasOwnProperty.call(selectedMember, 'financialAccess')
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(selectedMember?.financialAccess);
    }
  }, [selectedMember, getSingleMember]);

  const onSubmit = async values => {
    const invDateEpoch = Date.parse(values?.invoiceDate);
    const invDueDateEpoch = Date.parse(values?.invoiceDueDate);
    const seasonsArr = selectedCropSeasonIds.map(season => {
      return { seasonId: season, name: '' };
    });
    delete values.totalQuantityUnit; // eslint-disable-line no-param-reassign
    // eslint-disable-next-line func-names
    if (values?.invoiceProducts?.length > 0)
      values.invoiceProducts.forEach(v => {
        delete v.manufacturerName; // eslint-disable-line no-param-reassign
      });

    const invoiceValues = {
      ...values,
      invoiceDueDateEpoch: invDueDateEpoch,
      invoiceDateEpoch: invDateEpoch,
      cropSeasons: seasonsArr,
      flagIds: values?.flagIds?.length > 0 ? values?.flagIds?.split(',') : []
    };

    delete invoiceValues.invoiceDate;
    delete invoiceValues.invoiceDueDate;
    delete invoiceValues.cropSeason;
    delete invoiceValues.cropsSeasons;
    if (values?.id === undefined) delete invoiceValues.id;
    let success = '';
    if (values?.id?.length > 0) {
      success = await updateInvoice(invoiceValues);
    } else {
      success = await saveInvoice(invoiceValues);
    }
    if (success) {
      setToastRenderContents('Save Succeeded');
      history.push(paths.invoices);
    }
  };

  return (
    <>
      <div className="w-full h-full" data-testid="invoices">
        <Breadcrumb
          disabled={isCreateScreen || isEdit}
          hideCropSeasonDropdown={false}
          onCropSeasonSelect={updateSelectedCropSeasons}
        >
          <Breadcrumb.Item
            title="Invoices"
            value="All Invoices"
            to={paths.invoices}
          />
          <Breadcrumb.Item
            title="Invoices"
            value={isCreateScreen ? 'Create Invoice' : 'Edit Invoice'}
            isLast
          />
        </Breadcrumb>
        {!loading ? (
          <>
            {error && (
              <Toast icon="error" onClose={() => setError('')}>
                {error}
              </Toast>
            )}
            <Formik
              initialValues={invoice}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({ submitForm }) => {
                return (
                  <>
                    <TopHeader
                      onSubmit={submitForm}
                      memberRole={selectedMember?.role}
                      memberFinancialAccess={financialAccess}
                    />
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div
                        data-testid="tabs-content"
                        style={{ height: 'calc(100% - 58px)' }}
                      >
                        {toastHasNotBeenClosed ? (
                          <Toast
                            onClose={() => {
                              setToastHasNotBeenClosed(false);
                            }}
                          >
                            {toastRenderContents}
                          </Toast>
                        ) : null}
                        <div
                          className="px-6 pt-4 -mx-4 bg-white"
                          style={{ height: 'calc(100% - 16px)' }}
                        >
                          <Tabs>
                            <Tabs.Tab label="Details">
                              <InvoiceDetailsTab
                                memberRole={selectedMember?.role}
                                memberFinancialAccess={financialAccess}
                              />
                            </Tabs.Tab>
                            <Tabs.Tab label="Products">
                              <InvoiceProductsTab
                                memberRole={selectedMember?.role}
                                memberFinancialAccess={financialAccess}
                              />
                            </Tabs.Tab>
                          </Tabs>
                        </div>
                      </div>
                    )}
                  </>
                );
              }}
            </Formik>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default Invoice;
