/* eslint-disable no-unused-vars */
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  useMemo
} from 'react';
import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import { isEmpty } from 'lodash';
import { Context } from 'components/Store';
import { useHistory } from 'react-router-dom';

import { SimpleModal, Spinner } from '@agconnections/grow-ui';

import useCropData from 'hooks/useCropData';
// import useFarmData from '../hooks/useFarmData';
import {
  MASS_ASSIGNER_SUCCESS_COUNTER,
  RESET_MASS_ASSIGNER
} from 'reducers/reducer';
import { paths } from 'routes/paths';
import { FETCH_TYPES } from 'helpers/constants';
import Toast from 'components/Toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import usePopulateMapData from 'hooks/usePopulateMapData';
import PropertiesTree from './PropertiesTree';
import PropertyLandingMap from './PropertyLandingMap';
import PropertyTable from './PropertyTable';
import CreatePropertyModal from './CreatePropertyModal';
import { AmplitudeContext } from '../../../utilities/amplitude/useAmplitude';
import PropertyTopHeader from './PropertyTopHeader/index';
import ImportPropertyModal from './ImportPropertyModal';
import EditFarm from './EditFarm';
import PropertyBreadcrumb from '../components/PropertyBreadcrumb';
import MassAssignerHeader from './components/MassAssignerHeader';
import {
  LAST_COORDINATES_STORED,
  PROPERTY_VIEW_MODE
} from './helpers/constants';
import PropertyContributionMargin from './PropertyContributionMargin/PropertyContributionMargin';
import usePropertyFilters from '../../../hooks/usePropertyFilters';
import filterFeatureCollectionByParentIdNoZoom from './helpers/filterFeatureCollectionByParentIdNoZoom';
import PropertyCostAnalysis from './PropertyCostAnalysis';
import shouldResetViewMode from './helpers/shouldResetViewMode';
import './index.css';

const propertyViewStorageApi = getLocalStorageApi(
  STORAGE_KEYS.PROPERTY_VIEW_V2,
  PROPERTY_VIEW_MODE.MAP
);

const fftWidth = '338px';

const PropertiesLanding = () => {
  const {
    fePropertyDetails,
    fePropertyDetailsByOrgId,
    releaseCostAnalysisViewEnable,
    releaseCostAnalysisViewEnableByOrg
  } = useFlags();

  const {
    loading,
    zoom: propertyZoom,
    setZoom: propertySetZoom,
    dataSorted: propertyLandingPageData,
    reloadData,
    fieldsAndAreasGeoJSONCollection,
    setGeoJSONCollectionFeatures,
    fieldsLoading,
    filterFeatureCollectionByParentId,
    fetchFarmCosts
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap,
    isCacheOn: true
  });
  const [
    {
      loggedInUserOrgPermission,
      selectedProperty,
      massAssignerSuccessCounter,
      isFieldImportedSuccessful,
      importedFields,
      isEnableInfoTabEdit
    },
    dispatch
  ] = useContext(Context);
  const [edited, setEdited] = useState(false);

  const [enableEdit, setEnableEdit] = useState(false);
  const [cancelEditModalVisible, setCancelEditModalVisible] = useState(false);

  const [financialAccess, setFinancialAccess] = useState();
  const [orgLoading, setOrgLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(
    !!isFieldImportedSuccessful
  );
  const { crops } = useCropData();
  const history = useHistory();
  const [
    {
      organization,
      isMassAssignerActive,
      fieldsToMassiveAssign,
      loadingOrg,
      isGlobalCropSeasonLoading
    }
  ] = useContext(Context);
  const defaultLandingView = propertyViewStorageApi.get();

  const [view, setView] = useState(defaultLandingView);
  const [propertySelectorSource, setSelectorSource] = useState();

  const [openToast, setOpenToast] = useState(false);

  const { filteredProperties, setPropertiesToFilter } = usePropertyFilters();

  const [localSelectedProperty, setLocalSelectedProperty] = useState([
    filteredProperties
  ]);

  const costAnalysisViewEnabled = featureFlagRenderItem(
    releaseCostAnalysisViewEnable,
    releaseCostAnalysisViewEnableByOrg,
    organization.id
  );

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(loggedInUserOrgPermission)) {
      setOrgLoading(false);
    } else {
      setOrgLoading(true);
    }

    if (
      (loggedInUserOrgPermission?.role === 'Full control' ||
        loggedInUserOrgPermission?.role === 'View Only') &&
      !Object.prototype.hasOwnProperty.call(
        loggedInUserOrgPermission,
        'financialAccess'
      )
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_MASS_ASSIGNER });
      localStorage.removeItem(LAST_COORDINATES_STORED);
    };
  }, [dispatch]);

  useEffect(() => {
    setView(propertyViewStorageApi.get());
  }, [organization]);

  const handleChangeView = newView => {
    setView(newView);
    propertyViewStorageApi.save(newView);
  };

  const [selectedField, setSelectedField] = useState();
  const [selectedCrop, setSelectedCrop] = useState();
  const [mapFieldId, setMapFieldId] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const amplitude = useContext(AmplitudeContext);

  const handleBreadcrumbPropertySelect = useCallback(
    property => {
      setSelectorSource('farm');
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          property.id
        )
      );
    },
    [
      setGeoJSONCollectionFeatures,
      fieldsAndAreasGeoJSONCollection,
      filterFeatureCollectionByParentId
    ]
  );

  const handleBreadcrumbFieldSelect = useCallback(
    field => {
      setSelectorSource('field');
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          field.id
        )
      );
    },
    [
      fieldsAndAreasGeoJSONCollection,
      setGeoJSONCollectionFeatures,
      filterFeatureCollectionByParentId
    ]
  );

  const handleBreadcrumbCropZoneSelect = useCallback(
    cropZone => {
      setSelectorSource('cropzone');
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          fieldsAndAreasGeoJSONCollection,
          cropZone.id
        )
      );
    },
    [
      setGeoJSONCollectionFeatures,
      fieldsAndAreasGeoJSONCollection,
      filterFeatureCollectionByParentId
    ]
  );

  const handleOrganizationSelect = () => {
    setSelectorSource('org');
    reloadData({
      seasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons'))
    });
  };

  useEffect(() => {
    setPropertiesToFilter(propertyLandingPageData?.properties);
    setLocalSelectedProperty(filteredProperties);
  }, [
    propertyLandingPageData?.properties,
    filteredProperties,
    setPropertiesToFilter
  ]);

  useEffect(() => {
    setLocalSelectedProperty(filteredProperties);
  }, [filteredProperties]);

  const handleMapClick = shape => {
    setSelectorSource('field');
    setGeoJSONCollectionFeatures(
      filterFeatureCollectionByParentIdNoZoom(
        fieldsAndAreasGeoJSONCollection,
        shape.id
      )
    );
  };

  const handleMapFieldSelect = mapField => {
    setMapFieldId(mapField);
    handleBreadcrumbFieldSelect(mapField);
  };

  const drawFieldsClick = currentSelectedProperty => {
    // amplitude trigger
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.createField
    );

    let selectedId = null;
    if (currentSelectedProperty.type === 'Farm') {
      selectedId = currentSelectedProperty.id;
    } else if (currentSelectedProperty.type === 'Field') {
      const filterField = filteredProperties
        ?.filter(property =>
          property?.fields?.find(
            field => field.id === currentSelectedProperty.id
          )
        )
        .pop();
      selectedId = filterField.id;
    }

    history.push(`${paths.properties}/create`, {
      selectedId
    });
  };
  const uploadFilesClick = () => {
    setShowImportModal(true);
  };

  PropertiesLanding.zoomPropertyOut = () => {
    setGeoJSONCollectionFeatures(
      filterFeatureCollectionByParentId(
        fieldsAndAreasGeoJSONCollection,
        selectedProperty.id,
        fieldsToMassiveAssign
      )
    );
  };

  useEffect(() => {
    PropertiesLanding.zoomPropertyOut();
  }, [selectedProperty.id, isMassAssignerActive, fieldsToMassiveAssign]);

  const formPanel = {
    Farm: (
      <EditFarm
        farmName={selectedProperty.name}
        farmId={selectedProperty.id}
        reloadFarmData={reloadData}
        setView={setView}
      />
    )
  };
  const isFarm = selectedProperty?.type === 'Farm';
  const isField = selectedProperty?.type === 'Field';

  useEffect(() => {
    if (
      shouldResetViewMode({
        selectedProperty,
        currentView: view,
        costAnalysisViewEnabled
      })
    ) {
      setView(PROPERTY_VIEW_MODE.MAP);
    }
  }, [selectedProperty, view, isFarm, costAnalysisViewEnabled]);

  const getBreadcrumbTitle = () => {
    if (isEnableInfoTabEdit) {
      if (isFarm) return 'Edit Farm';
      if (isField) return `Edit Field info: ${selectedProperty?.name}`;
    }

    return isFarm || isField ? selectedProperty?.name : 'All Properties';
  };

  const displayHeader = useMemo(() => {
    return !selectedProperty?.isEditing;
  }, [selectedProperty]);

  const onCloseToast = useRef(() => {
    setOpenToast(false);
    setTimeout(() => {
      dispatch({
        type: MASS_ASSIGNER_SUCCESS_COUNTER,
        payload: {}
      });
    }, 500);
  });

  useEffect(() => {
    setOpenToast(!!Object.keys(massAssignerSuccessCounter).length);
  }, [massAssignerSuccessCounter]);

  const loadFarmCosts = farmId => {
    fetchFarmCosts(farmId);
  };

  return (
    <>
      <div className="w-full h-full">
        <PropertyBreadcrumb
          properties={filteredProperties}
          title={getBreadcrumbTitle()}
          onPropertySelect={handleBreadcrumbPropertySelect}
          onFieldSelect={handleBreadcrumbFieldSelect}
          onCropZoneSelect={handleBreadcrumbCropZoneSelect}
          onOrganizationSelect={handleOrganizationSelect}
          setGeoJSONCollectionFeatures={setGeoJSONCollectionFeatures}
          fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
          fieldId={selectedField}
          mapFieldId={mapFieldId}
          reloadData={reloadData}
          disabledPropertyChange={!isFarm}
        />
        {openCreateModal && (
          <CreatePropertyModal
            open={openCreateModal}
            closeModal={setOpenCreateModal}
            drawFieldsClick={drawFieldsClick}
            uploadFilesClick={uploadFilesClick}
            // selectFieldsClick={selectFieldsClick}
          />
        )}
        {loadingOrg || isGlobalCropSeasonLoading ? (
          <Spinner />
        ) : (
          <div className="w-full h-full">
            {isMassAssignerActive && (
              <MassAssignerHeader reloadData={reloadData} />
            )}
            <div
              className={`flex flex-row w-full ${
                isMassAssignerActive ? '-h-24' : 'h-full'
              }`}
            >
              <div className={`w-${fftWidth} h-full`}>
                {selectedProperty?.isEditing ? (
                  formPanel[selectedProperty.type]
                ) : (
                  <PropertiesTree
                    openCreateModal={() => setOpenCreateModal(true)}
                    properties={filteredProperties}
                    rawProperties={propertyLandingPageData?.properties ?? []}
                    fieldsAndAreasGeoJSONCollection={
                      fieldsAndAreasGeoJSONCollection
                    }
                    reloadData={reloadData}
                    onFarmSelect={handleBreadcrumbPropertySelect}
                    onFieldSelect={handleBreadcrumbFieldSelect}
                    onCropZoneSelect={handleBreadcrumbCropZoneSelect}
                    memberRole={loggedInUserOrgPermission?.role}
                    memberFinancialAccess={financialAccess}
                    loadFarmCosts={loadFarmCosts}
                    loading={loading}
                    view={view}
                    setView={setView}
                    onChangeView={handleChangeView}
                  />
                )}
              </div>
              <div
                className="h-full relative flex flex-col"
                style={{ width: `calc(100% - ${fftWidth})` }}
              >
                {displayHeader && (
                  <div
                    className={
                      view !== PROPERTY_VIEW_MODE.MAP
                        ? 'w-full'
                        : 'absolute z-40 bg-white w-full'
                    }
                  >
                    <PropertyTopHeader
                      viewMode={view}
                      edited={edited}
                      setCancelEditModalVisible={setCancelEditModalVisible}
                      enableEdit={enableEdit}
                      setEnableEdit={setEnableEdit}
                      properties={filteredProperties}
                      selectedCrop={selectedCrop}
                      setSelectedCrop={setSelectedCrop}
                    />
                  </div>
                )}

                {costAnalysisViewEnabled &&
                  view === PROPERTY_VIEW_MODE.COST && (
                    <PropertyCostAnalysis selectedCrop={selectedCrop} />
                  )}

                {view === PROPERTY_VIEW_MODE.MAP && (
                  <div className="relative">
                    {displayHeader &&
                      !costAnalysisViewEnabled &&
                      featureFlagRenderItem(
                        fePropertyDetails,
                        fePropertyDetailsByOrgId,
                        organization?.id
                      ) && <PropertyContributionMargin />}
                    <PropertyLandingMap
                      properties={localSelectedProperty}
                      fieldsLoading={fieldsLoading}
                      propertyCount={organization.properties?.propertyCount}
                      geoJSONCollection={fieldsAndAreasGeoJSONCollection}
                      crops={crops}
                      zoom={propertyZoom}
                      setZoom={propertySetZoom}
                      onReload={reloadData}
                      labelName={propertySelectorSource}
                      selectedField={selectedField}
                      onMapClick={handleMapClick}
                      setSelectedField={handleMapFieldSelect}
                    />
                  </div>
                )}
                {view === PROPERTY_VIEW_MODE.INFO && (
                  <PropertyTable
                    setCancelEditModalVisible={setCancelEditModalVisible}
                    edited={edited}
                    setEdited={setEdited}
                    enableEdit={enableEdit}
                    setEnableEdit={setEnableEdit}
                  />
                )}
              </div>
              <ImportPropertyModal
                open={showImportModal}
                close={() => setShowImportModal(false)}
                reload={reloadData}
                importedFields={importedFields}
              />
            </div>
          </div>
        )}

        <Toast
          type={massAssignerSuccessCounter.toastType}
          title={massAssignerSuccessCounter.toastTitle}
          open={openToast}
          onClose={onCloseToast.current}
        >
          <p className="text-sm font-body">
            {massAssignerSuccessCounter.toastDescription}
          </p>
        </Toast>
        <SimpleModal
          open={cancelEditModalVisible}
          close={() => setCancelEditModalVisible(false)}
          confirmLabel="Exit Form"
          onCancel={() => {
            setCancelEditModalVisible(false);
          }}
          onConfirm={() => {
            setEnableEdit(false);
            setCancelEditModalVisible(false);
          }}
          title="Leaving Form"
          type="error"
        >
          <span>
            Navigating away from this form will discard all unsaved changes.
          </span>
        </SimpleModal>
      </div>
    </>
  );
};

export default PropertiesLanding;
