/* eslint-disable import/prefer-default-export */
export const mapProductToAdd = (newProduct, values, pathname) => {
  let prodToAdd = {};

  if (pathname.includes('plans')) {
    prodToAdd = {
      productId: newProduct.id,
      density: newProduct.density,
      trackingId: '',
      productRateValue: 0,
      productRateUnit: newProduct.standardUnit,
      areaValue: values.area,
      areaUnit: 'acre',
      totalProductValue: 0,
      totalProductUnit: newProduct.standardUnit,
      pricingStrategy: 'SystemDefined',
      specificCostValue: 0,
      specificCostUnit: newProduct.standardUnit,
      totalProductCost: 0,
      coveragePercent: 100,
      applicationCount: 1,
      targetDateEpoch: '',
      timingEvent: '',
      timingEventTag: '',
      manufacturerName: newProduct.manufacturer,
      productDensity: newProduct.density,
      productName: newProduct.name,
      shareOwnerInformation: {
        percentGrowerResponsibility: 100,
        totalFromGrowerInventoryQuantity: 100,
        totalFromGrowerInventoryUnit: ''
      },
      stdfactor: newProduct.stdFactor,
      stdpackageunit: newProduct.standardUnit,
      stdunit: newProduct.standardUnit,
      currency: 'USD',
      custom: true
    };
  } else if (pathname.includes('invoices')) {
    prodToAdd = {
      productId: newProduct.id,
      totalQuantityUnit: newProduct.standardUnit,
      totalQuantityValue: newProduct.totalQuantityValue || 0,
      trackingId: '',
      totalCostValue: 0,
      manufacturerName: newProduct.manufacturer,
      productDensity: newProduct.density,
      productName: newProduct.name,
      totalCostCurrency: 'USD',
      custom: true
    };
  } else {
    prodToAdd = {
      applicationMethod: '',
      appliedAreaUnit: 'acre',
      appliedAreaValue: values.totalAreaValue || values.totalApplied,
      areaUnit: 'acre',
      averagePriceAtTimeOfCreation: 0,
      averagePriceUnitAtTimeOfCreation: '',
      coveragePercent: 100,
      manufacturerName: newProduct.manufacturer,
      productDensity: newProduct.density,
      productId: newProduct.id,
      productName: newProduct.name,
      ratePerAreaUnit: newProduct.standardUnit,
      ratePerAreaValue: 0,
      ratePerTankUnit: newProduct.standardUnit,
      ratePerTankValue: 0,
      shareOwnerInformation: {
        percentGrowerResponsibility: 100,
        totalFromGrowerInventoryQuantity: 100,
        totalFromGrowerInventoryUnit: ''
      },
      specificCostPerUnit: 0,
      specificCostUnit: newProduct.standardUnit,
      stdfactor: newProduct.stdFactor,
      stdpackageunit: newProduct.standardUnit,
      stdunit: newProduct.standardUnit,
      targetPest: undefined,
      targetPestId: undefined,
      totalCostAtTimeOfCreation: 0,
      totalProductCost: 0,
      totalProductUnit: newProduct.standardUnit,
      totalProductValue: 0,
      custom: true
    };
  }

  return prodToAdd;
};

export const filterByType = (products, filter) =>
  products.filter(
    p => !filter || p.producttype.toLowerCase() === filter?.toLowerCase()
  );
