import React, { useContext, useEffect, useRef, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { season as seasonApi, recommendation as recApi } from 'utilities/api';
import usePDFTools from 'hooks/usePDFTools';
import usePeopleData from 'hooks/usePeopleData';
import { standardFormat } from 'helpers/dateFormat';
import useCompanies from 'hooks/useCompanies';
import { Context } from 'components/Store';
import RecPdf from 'components/PDF/RecPdf';
import { sumFieldAcres } from 'components/PDF/helpers/tableGenerator';
import {
  fieldsToTable,
  mapProductsToTable
} from 'components/PDF/RecPdf/helpers/tableMaker';
import {
  buildFeature,
  convertFeaturesToMapUri,
  getProductsExtraData,
  getPropertyExtraData
} from 'components/PDF/helpers/pdfTools';
import { condensor } from 'helpers/condensor';
import { truncateCoordinates } from 'helpers/propertyHelpers';

const useRecEllipseOptions = () => {
  const [{ organization }] = useContext(Context);
  const { companies } = useCompanies();
  const { people } = usePeopleData();

  const { downloadPDFV2, downloading, setDownloading } = usePDFTools();

  const [recToPdf, setRecToPdf] = useState();
  const [instance, updateInstance] = usePDF({
    document: <RecPdf rec={recToPdf} />
  });

  const prevPdfUrl = useRef(undefined);

  useEffect(() => {
    if (recToPdf) {
      updateInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recToPdf]);

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      prevPdfUrl.current !== instance.url &&
      recToPdf
    ) {
      prevPdfUrl.current = instance.url;
      downloadPDFV2(prevPdfUrl.current, `Rec - ${recToPdf.title}.pdf`);
      setRecToPdf(null);
      setDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const getCompanyNameById = companyId => {
    if (companies?.length && companyId) {
      return companies.find(company => company.id === companyId)?.name ?? '--';
    }
    return '--';
  };

  const shareOrPrint = async recId => {
    setDownloading(true);

    const { promise } = recApi.fetch(recId);
    const { data: rec } = await promise.catch(() => ({}));

    if (rec) {
      const features = [];

      const mappedPropertiesPromise = rec.cropZones.map(async property => {
        const {
          cropZone: cropZoneInfo,
          field,
          farm,
          crop,
          centroId: { geometry }
        } = await getPropertyExtraData(property.cropZoneIdValue);

        if (cropZoneInfo) {
          features.push(buildFeature(cropZoneInfo.geometry));
        }

        return {
          ...property,
          farmName: farm.name,
          fieldName: field.name,
          cropZoneName: cropZoneInfo.name,
          cropName: crop.name,
          latlong: truncateCoordinates(geometry, 5)?.coordinates
        };
      });

      const mappedProductsPromise = rec.recommendationProductEntries.map(
        async product => {
          const {
            masterProduct: { registrationnumber },
            activeIngredients
          } = await getProductsExtraData(product.id);

          return {
            ...product,
            registrationnumber: registrationnumber
              ? condensor(registrationnumber)
              : '-',
            activeIngredients
          };
        }
      );

      const cropSeasonPromises = rec.cropSeasonIds.map(async cropSeasonId => {
        const { promise: cropSeasonPromise } = seasonApi.fetch(cropSeasonId);

        const { data } = await cropSeasonPromise.catch(() => ({}));

        return data;
      });

      const promises = await Promise.allSettled([
        ...mappedPropertiesPromise,
        ...mappedProductsPromise,
        ...cropSeasonPromises
      ]);

      const propertiesLength = mappedPropertiesPromise.length;
      const productsLength = mappedProductsPromise.length;
      const propertiesPlusProducts = propertiesLength + productsLength;

      const mappedProperties = promises.slice(0, propertiesLength);
      const mappedProducts = promises.slice(
        propertiesLength,
        propertiesPlusProducts
      );
      const cropSeasons = promises.slice(propertiesPlusProducts);
      const totalFieldsArea = sumFieldAcres(rec.cropZones);
      const generatedDate = new Date();
      const year = format(generatedDate, 'yyyy');
      const fullDate = format(generatedDate, 'MM/dd/yyyy h:mm aaa');

      const mapImage = convertFeaturesToMapUri(features);
      const mappedApplicator = rec.applicators?.map(applicator => {
        const companyName = getCompanyNameById(applicator.companyId);
        const person = people.find(p => p.id === applicator.personId);

        return {
          ...applicator,
          companyName,
          applicatorLicenseNumber: person?.licenseNumber || '',
          applicatorLicenseExpirationDate: person?.expirationDate
            ? standardFormat(person.expirationDate)
            : '',
          personName: person.name
        };
      });

      setRecToPdf({
        ...rec,
        applicators: mappedApplicator || [],
        cropZones: fieldsToTable(
          mappedProperties
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value)
        ),
        organizationName: organization.name,
        cropSeasons: cropSeasons
          .filter(request => request.status === 'fulfilled')
          .map(request => request.value.name),
        recommendationProductEntries: mapProductsToTable(
          mappedProducts
            .filter(request => request.status === 'fulfilled')
            .map(request => request.value)
        ),
        totalFieldsArea,
        year,
        fullDate,
        mapImage
      });
    } else {
      setDownloading(false);
    }
  };

  return {
    downloadingPDF: downloading,
    shareOrPrint
  };
};

export default useRecEllipseOptions;
