import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useRecommendations from 'hooks/useRecommendations';
import useDebounce from 'hooks/useDebounce';
import { noop, isEmpty } from 'lodash';
import { parseServerError } from 'helpers/errorHelpers';
import catchCancel from 'helpers/catchCancel';
import { Button, Spinner } from '@agconnections/grow-ui';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { Context } from 'components/Store';
import DeleteModal from 'components/Modals/DeleteModal';
import TagsModal from 'components/Modals/TagsModal';
import EmptyState from 'components/EmptyState';
import LandingSearch from 'components/LandingSearch';
import LandingListViewToggle from 'components/LandingListViewToggle';
import useCropSeasons from 'hooks/useCropSeasons';
import useTagsData from 'hooks/useTagsData';
import { recommendation as recommendationApi } from 'utilities/api';
import { getLocalStorageApi, STORAGE_KEYS } from 'utilities/localStorage';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import { paths } from 'routes/paths';
import RecommendationsTable from './components/RecommendationTable';
import RecommendationsTileBoard from './components/RecommendationsTileBoard';
import useFilterItems from './hooks/useFilterItems';
import useRecEllipseOptions from './hooks/useRecEllipseMenu';

const recommendationsViewStorageApi = getLocalStorageApi(
  STORAGE_KEYS.RECOMMENDATIONS_VIEW,
  'tile'
);

const Recommendations = () => {
  const {
    isLoading,
    recommendations,
    recommendationsCount,
    page,
    goToPage,
    filterByDateRange,
    filterByTitleQuery,
    sort,
    sortBy,
    sortDir,
    reload
  } = useRecommendations();

  const defaultRecommendationsView = recommendationsViewStorageApi.get();
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);

  const [orgLoading, setOrgLoading] = useState(false);
  const [view, setView] = useState(defaultRecommendationsView);
  const history = useHistory();
  const { tags, loading: isTagsLoading, getTags } = useTagsData();
  const [openTags, setOpenTags] = useState(false);
  const [allFlags, setAllFlags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    id: null
  });
  const [
    { loggedInUserOrgPermission, loadingOrg, isGlobalCropSeasonLoading },
    dispatch
  ] = useContext(Context);
  const [financialAccess, setFinancialAccess] = useState();
  const { cropSeasons } = useCropSeasons();
  const filterItems = useFilterItems(cropSeasons);
  const amplitude = useContext(AmplitudeContext);
  const [, setError] = useState(null);
  const [recName, setRecName] = useState('');
  const [recNum, setRecNum] = useState();

  const { shareOrPrint, downloadingPDF } = useRecEllipseOptions();

  const handleOrgChange = () => {
    reload();
    getTags();
  };

  const handleAction = (action, recommendation) => {
    const tag =
      recommendation.flagIds?.length > 0 &&
      recommendation.flagIds !== undefined &&
      recommendation.flagIds.map(flagId => {
        return tags.find(t => t.id === flagId);
      });
    const actions = {
      view: () => {
        history.push({
          pathname: `${paths.recommendations}/${recommendation.id}/view`
        });
      },
      edit: () => {
        history.push({
          pathname: `${paths.recommendations}/${recommendation.id}`
        });
      },
      tag: () => {
        setRecName(recommendation.title);
        setRecNum(recommendation.id);
        setAllFlags(
          recommendation.flagIds?.length > 0 ||
            recommendation.flagIds !== undefined
            ? tag
            : []
        );
        setOpenTags(true);
      },
      convertTask: () => {},
      duplicate: async () => {
        await dispatch({
          type: 'SET_CONVERT_PLAN',
          payload: { id: recommendation.id }
        });
        history.push(`${paths.recommendations}/duplicate`);
      },
      shareOrPrint,
      delete: () => {
        setDeleteModalState({ id: recommendation.id, open: true });
      }
    };
    actions[action](recommendation.id);
  };

  const handleReturnTags = async e => {
    setTagsLoading(true);
    const filterRec = recommendations.find(eachRec => eachRec.id === recNum);
    const body = {
      ...filterRec,
      flagIds: e.map(item => item.id)
    };
    const { promise } = await recommendationApi.update(recNum, body);
    await promise
      .then(data => {
        const updatedRecs = recommendations.map(rec => {
          if (rec.id === data.data.id) {
            return data.data;
          }
          return rec;
        });
        reload(updatedRecs);
        setAllFlags(e);
        getTags();
        setTagsLoading(false);
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      });
    setTagsLoading(false);
  };

  const handleDelete = async () => {
    // amplitude trigger
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Recommendations.deleteRecommendations
    );
    const { promise } = recommendationApi.delete(deleteModalState.id);
    await promise.then(() => {
      history.go(paths.recommendations);
    });
  };

  const handleDateRangeChange = e => {
    filterByDateRange([...e.target.value]);
  };

  useEffect(() => {
    filterByTitleQuery(debouncedSearchTerm);
  }, [debouncedSearchTerm, filterByTitleQuery]);

  useEffect(() => {
    if (
      loggedInUserOrgPermission?.role === 'View Only' ||
      (loggedInUserOrgPermission?.role === 'Full control' &&
        !Object.prototype.hasOwnProperty.call(
          loggedInUserOrgPermission,
          'financialAccess'
        ))
    ) {
      setFinancialAccess('none');
    } else {
      setFinancialAccess(loggedInUserOrgPermission?.financialAccess);
    }
  }, [loggedInUserOrgPermission]);

  useEffect(() => {
    if (isEmpty(loggedInUserOrgPermission)) {
      setOrgLoading(false);
    } else {
      setOrgLoading(true);
    }
  }, [loggedInUserOrgPermission]);

  const setPermission = useCallback(() => {
    if (
      loggedInUserOrgPermission?.role === 'View Only' &&
      (financialAccess === 'READ' || financialAccess === 'none')
    ) {
      return false;
    }
    return true;
  }, [loggedInUserOrgPermission.role, financialAccess]);

  const showSpinner = () => {
    return (
      isLoading ||
      isTagsLoading ||
      loadingOrg ||
      !orgLoading ||
      tagsLoading ||
      isGlobalCropSeasonLoading
    );
  };
  return (
    <div className="w-full h-full" data-testid="recommendations">
      <Breadcrumb
        hideCropSeasonDropdown={false}
        onCropSeasonSelect={handleOrgChange}
        onOrganizationSelect={handleOrgChange}
      >
        <Breadcrumb.Item
          title="Recommendations"
          value="All Recommendations"
          isLast
        />
      </Breadcrumb>

      <div className="flex items-center justify-between">
        <LandingSearch
          id="recommendations-search-input"
          name="search_recommendations"
          placeholder="Search recommendations"
          filterItems={filterItems}
          selectedItems={[]}
          searchText={searchText}
          onChange={event => {
            setSearchText(event.target.value);
          }}
          onFilterChange={noop}
          onChangeLabel={noop}
          onChangeProductType={noop}
          onChangeTaskType={noop}
          onDateRangeChange={handleDateRangeChange}
          showCropSeason={false}
        />
        <div className="flex items-center">
          <LandingListViewToggle
            changeView={newView => {
              setView(newView);
              recommendationsViewStorageApi.save(newView);
            }}
            onChangeProductType={noop}
            onChangeTaskType={noop}
            onFilterChange={noop}
            testId="recommendations-view-toggle"
            view={view}
          />
        </div>
        <div className="pl-2 whitespace-no-wrap">
          {setPermission() && (
            <Button
              id="add-plan-button"
              type="primary"
              onClick={event => {
                event.stopPropagation();
                history.push(`${paths.recommendations}/create`);
              }}
              icon={<AddRoundedIcon />}
            >
              Create Rec.
            </Button>
          )}
        </div>
      </div>

      {showSpinner() ? (
        <Spinner />
      ) : (
        <>
          {recommendations && recommendations.length ? (
            <div>
              <DeleteModal
                open={deleteModalState.open}
                itemType="Recommendation"
                onCancel={() => setDeleteModalState({ open: false, id: null })}
                onDelete={handleDelete}
              />
              <TagsModal
                open={openTags}
                close={() => {
                  setOpenTags(false);
                }}
                name={recName}
                selectedTags={
                  allFlags.length > 0
                    ? allFlags?.reduce((tagIds, tag) => {
                        return `${tagIds},${tag.id}`;
                      }, '')
                    : ''
                }
                returnSelectedTags={e => {
                  handleReturnTags(e);
                }}
              />
              {view === 'tile' ? (
                <RecommendationsTileBoard
                  recommendations={recommendations}
                  tags={tags}
                  pageNo={page}
                  setPageNo={goToPage}
                  totalRecommendations={recommendationsCount}
                  onAction={handleAction}
                  memberFinancialAccess={financialAccess}
                  downloadingPDF={downloadingPDF}
                  cropSeasons={cropSeasons}
                />
              ) : (
                <RecommendationsTable
                  recommendations={recommendations}
                  tags={tags}
                  pageNo={page}
                  setPageNo={goToPage}
                  totalRecommendations={recommendationsCount}
                  onAction={handleAction}
                  sort={sort}
                  sortBy={sortBy}
                  sortDir={sortDir}
                  downloadingPDF={downloadingPDF}
                  cropSeasons={cropSeasons}
                />
              )}
            </div>
          ) : (
            <EmptyState
              buttonText="Create Recommendation"
              onClick={() => history.push(`${paths.recommendations}/create`)}
              subtitle="We were unable to find any Recommendations under this organization."
              setPermission={setPermission}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Recommendations;
