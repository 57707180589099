import React, { useState, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Table } from '@agconnections/grow-ui';

import ViewIcon from 'assets/open_in_new.svg';
import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';
import tableSort from 'helpers/tableSort';
import useInventoryTasksData from 'screens/ProductList/ProductDetails/hooks/useInventoryTasksData';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { paths } from 'routes/paths';
import routesForPPLToCreate from 'screens/ProductList/helpers/creationRoutes';
import ProductDetailTableEmptyState from '../ProductDetailTableEmptyState';

const PAGE_COUNT = 20;

const ProductDetailTasksTable = ({ cropSeasons, dateRange }) => {
  const { id: productId } = useParams();
  const history = useHistory();
  const [sortField, setSortField] = useState('completedDate');
  const [sortDir, setSortDir] = useState('desc');
  const [pageNo, setPageNo] = useState(0);
  const { tasks, tasksLoading } = useInventoryTasksData(productId);

  const TABLE_COLUMNS = [
    {
      field: 'name',
      title: `Task Name`,
      sortable: true
    },
    {
      field: 'completedDate',
      title: 'Completed Date',
      sortable: true
    },
    {
      field: 'ratePerArea',
      title: 'Rate',
      sortable: true
    },
    {
      field: 'appliedArea',
      title: 'Applied Area',
      sortable: true
    },
    {
      field: 'totalProduct',
      title: 'Applied Quantity',
      sortable: true
    },
    {
      field: 'taskId',
      title: '',
      sortable: false
    }
  ];

  const mappedTasks = useMemo(() => {
    const taskList = tasks.map(task => {
      let ratePerArea = 0;
      let ratePerAreaUnit = '';
      let appliedArea = 0;
      let appliedAreaUnit = '';
      let totalProduct = 0;
      let totalProductUnit = '';

      const filteredProducts = task.products.filter(
        prod => prod.productId === productId
      );

      filteredProducts.forEach(product => {
        ratePerArea += product.ratePerAreaValue;
        ratePerAreaUnit = product.ratePerAreaUnit;
        appliedArea += product.appliedAreaValue;
        appliedAreaUnit = product.appliedAreaUnit;
        totalProduct += product.totalProductValue;
        totalProductUnit = product.totalProductUnit;
      });

      return {
        id: task.taskId,
        name: task.name,
        completedDate: new Date(
          task.duration === 'Custom'
            ? task.dueDateEpoch
            : task.startDateEpoch + task.duration * 3600
        ),
        taskCropSeasons: task.cropSeasonIds,
        ratePerArea,
        ratePerAreaUnit,
        appliedArea,
        appliedAreaUnit,
        totalProduct,
        totalProductUnit
      };
    });

    return taskList;
  }, [tasks, productId]);

  const filteredAndSortedTasks = useMemo(() => {
    const filtered = mappedTasks.filter(row => {
      const taskDate = new Date(row.completedDate);
      const cropFilter =
        cropSeasons.length === 0 ||
        row.taskCropSeasons.some(crop => cropSeasons.includes(crop.id));
      const dateFilter =
        !dateRange ||
        (taskDate > new Date(dateRange[0]) &&
          taskDate < new Date(dateRange[1]));
      return cropFilter && dateFilter;
    });
    return tableSort(filtered, sortField, sortDir);
  }, [mappedTasks, sortField, sortDir, cropSeasons, dateRange]);

  const totalProduct = useMemo(() => {
    const total = filteredAndSortedTasks.reduce((acc, task) => {
      return acc + task.totalProduct;
    }, 0);

    return `${total.toFixed(2)} ${getAbbreviatedUnit(
      filteredAndSortedTasks[0]?.totalProductUnit
    ) ?? ''}`;
  }, [filteredAndSortedTasks]);

  const handleSort = (field, dir) => {
    const _dir = !dir ? 'asc' : dir;
    setSortField(field);
    setSortDir(_dir);
  };

  const paginatedData = filteredAndSortedTasks.slice(
    pageNo * PAGE_COUNT,
    (pageNo + 1) * PAGE_COUNT
  );

  const isLoading = tasksLoading;
  const isEmpty = paginatedData.length === 0;

  return (
    <div className="flex" style={{ height: 'calc(100% - 3rem)' }}>
      <div className="flex-1 overflow-scroll">
        <LandingTable
          items={paginatedData}
          isLoading={isLoading}
          tableColumns={TABLE_COLUMNS}
          isEmpty={isEmpty}
          emptyState={() => null}
          onSort={handleSort}
          sortBy={sortField}
          sortDir={sortDir}
          wrapperClassName="flex-1 h-fit"
        >
          {({ rows }) =>
            rows.map(task => (
              <Table.Row key={task.taskId}>
                <Table.Cell width="auto">{task.name}</Table.Cell>
                <Table.Cell width="auto">
                  {new Date(task.completedDate).toLocaleDateString()}
                </Table.Cell>
                <Table.Cell width="auto">
                  {`${task.ratePerArea.toFixed(2)} ${getAbbreviatedUnit(
                    task.ratePerAreaUnit
                  )}/${getAbbreviatedUnit(task.appliedAreaUnit)}`}
                </Table.Cell>
                <Table.Cell width="auto">{`${task.appliedArea.toFixed(
                  2
                )} ${getAbbreviatedUnit(task.appliedAreaUnit)}`}</Table.Cell>
                <Table.Cell width="auto">{`${task.totalProduct.toFixed(
                  2
                )} ${getAbbreviatedUnit(task.totalProductUnit)}`}</Table.Cell>
                <Table.Cell width="auto">
                  <div className="w-full flex flex-row justify-end">
                    <Link
                      to={`${paths.tasks}/${task.id}/view`}
                      className="flex flex-row"
                    >
                      <img className="mr-1" src={ViewIcon} alt="view icon" />
                      <span>View</span>
                    </Link>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </LandingTable>
        {!isLoading && !isEmpty && (
          <div className="w-full px-6 border-1 border-t-0 border-neutral-20">
            {CwFPagination(tasks.length, pageNo, setPageNo, () => (
              <div
                className="bg-banner-alert-blue-10 rounded py-1 px-3"
                data-testid="product-detail-total-value"
              >
                <span>Total Applied Quantity: </span>
                <span className="font-semibold">{totalProduct}</span>
              </div>
            ))}
          </div>
        )}
        {!isLoading && isEmpty && (
          <ProductDetailTableEmptyState
            type="completed_tasks"
            totalItemCount={mappedTasks.length}
            onButtonClick={() => history.push(routesForPPLToCreate.task)}
          />
        )}
      </div>
    </div>
  );
};

ProductDetailTasksTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ProductDetailTasksTable;
