/* eslint-disable no-param-reassign */
import React, { useContext, useMemo } from 'react';
import events from 'utilities/amplitude/events';
import { getCropZonesFromProperties } from 'helpers/propertyHelpers';
import { Button } from '@agconnections/grow-ui';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { paths } from 'routes/paths';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { SubmissionSummaryContext } from '../../context/SubmissionSummaryProvider';

const SuccessPage = () => {
  const {
    state: { integrationType, farms, submissionId }
  } = useContext(SubmissionSummaryContext);

  const { comingFromView } = useSubmissionUrlHandler();

  const history = useHistory();

  const tasksLength = useMemo(() => {
    const cropzones = getCropZonesFromProperties(farms);
    return [
      ...new Map(
        cropzones?.flatMap(cropzone =>
          cropzone.tasks?.map(task => [task.taskId, task])
        )
      ).values()
    ].length;
  }, [farms]);

  useSubmissionAnalytics({
    onMountTriggerEvent: {
      event: events.epic.Integrations.submissionCompleted,
      data: { vendor: integrationType, tasksLength, submissionId }
    }
  });

  const handleRedirectToHistoryPage = () => {
    return history.push(
      `${paths.integrations}/${integrationType}/landing-page`
    );
  };

  return (
    <div
      className="w-full h-full bg-white flex flex-col px-6 py-4 rounded-xl font-body"
      data-testid="success-page"
    >
      <div className="flex justify-center  flex-col h-full">
        <div className="flex text-center items-center flex-col justify-center ">
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="4x"
            className="text-green-600 mb-5"
          />
          <h1
            className="text-2xl leading-8 font-semibold"
            style={{ marginBottom: '8px' }}
          >
            Your data has been {comingFromView ? 're' : ''}submitted!
          </h1>
          <div className=" w-1/2 text-center" style={{ marginBottom: '32px' }}>
            <p className="font-normal text-sm">
              {integrationType === INTEGRATIONS.calAgPermits ? (
                <>
                  Your pesticide usage reports have been successfully submitted
                  to CalAg. If you need to edit any part of your submission you
                  can do so at the{' '}
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_INTEGRATIONS_CALAG_WEBSITE ??
                      'https://calagtest.calicosol.com'
                    }
                    rel="noreferrer"
                  >
                    CalAg website.
                  </a>
                </>
              ) : (
                <>
                  Your submission has been sent to {integrationType}
                  <br />
                  You can view the submitted properties via the{' '}
                  {integrationType}
                  portal.
                </>
              )}
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              id="return-mainpage-button"
              type="primary"
              onClick={handleRedirectToHistoryPage}
            >
              Redirect to Submissions History
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
