import { centroid, featureCollection } from '@turf/turf';
import axios from 'axios';
import { truncateCoordinates } from 'helpers/propertyHelpers';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';
import {
  farm as farmApi,
  cropZone as cropZoneApi,
  field as fieldAPI,
  products as productsApi,
  newProxyReiphisData as reiphisAPI
} from 'utilities/api';
import { MASTERLIST_CROPS_URL } from 'utilities/apiConstants';
import { getAccessToken } from 'utilities/base-auth';
import reducePrecision from './reducePrecision';
import simplifyFeatures from './simplifyFeature';
import extractFirstCoordinates from './extractFirstCoordinates';

const LIMIT_RATE = 8192;
const BASE_MAP_URL =
  'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static';
const MAP_SIZE = '270x198';
const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Creates a GeoJSON Feature object with MultiPoint geometry type using provided coordinates
// Sets properties for marker size and color
export const createGeoJsonFeature = coordinates => ({
  type: 'Feature',
  properties: {
    'marker-size': 'xs',
    'marker-color': '#18A04A'
  },
  geometry: {
    type: 'MultiPoint',
    coordinates
  }
});

export const reducePayload = features => {
  let jsonData;
  // Apply geometry simplification and precision reduction
  const simplifiedFeatures = simplifyFeatures(features);
  const reducedPrecisionFeatures = simplifiedFeatures.map(feature => ({
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: reducePrecision(feature.geometry.coordinates)
    }
  }));
  jsonData = JSON.stringify(featureCollection(reducedPrecisionFeatures));
  if (jsonData.length > LIMIT_RATE) {
    // Reduces the data further by using only the first coordinates of each simplified feature
    const longCoordinates = extractFirstCoordinates(reducedPrecisionFeatures);
    jsonData = JSON.stringify(createGeoJsonFeature(longCoordinates));
  }

  const encodedGeoJson = encodeURIComponent(jsonData);
  const mapUri = `${BASE_MAP_URL}/geojson(${encodedGeoJson})/auto/${MAP_SIZE}?access_token=${ACCESS_TOKEN}`;

  return mapUri;
};

// Converts an array of GeoJSON features into a Mapbox API URL for generating static maps
// If the resulting GeoJSON string exceeds a pre-set size limit, it simplifies the data further
export const convertFeaturesToMapUri = features => {
  let geoJsonData;
  if (!features?.length) {
    return '';
  }
  try {
    geoJsonData = encodeURIComponent(
      JSON.stringify(featureCollection(features))
    );
  } catch (error) {
    return reducePayload(features);
  }
  if (geoJsonData.length <= LIMIT_RATE) {
    const mapUri = `${BASE_MAP_URL}/geojson(${geoJsonData})/auto/${MAP_SIZE}?access_token=${ACCESS_TOKEN}`;
    return mapUri;
  }
  return reducePayload(features);
};

export const buildFeature = geometry => ({
  type: 'Feature',
  properties: {
    stroke: '#31B4F2',
    'stroke-width': 1,
    fill: '#31B4F2',
    'fill-opacity': 0.37
  },
  geometry: truncateCoordinates(geometry, 5)
});

export const displayText = text => {
  if (text == null || text === '') return '-';
  return text;
};

export const displayNumber = number => {
  if (number == null) return '-';
  return convertNumberToDesiredFormat(number, 'fixed');
};

export const getPropertyExtraData = async cropZoneId => {
  const { promise: cropZonePromise } = cropZoneApi.fetch(cropZoneId);
  const { data: cropZone } = await cropZonePromise.catch(() => ({ data: {} }));

  const { promise: fieldPromise } = fieldAPI.fetch(cropZone.fieldId);
  const { data: field } = await fieldPromise.catch(() => ({ data: {} }));

  const { promise: farmPromise } = farmApi.fetch(field.propertyId);
  const { data: farm } = await farmPromise.catch(() => ({ data: {} }));

  const cropUrl = `${MASTERLIST_CROPS_URL}/crops/${cropZone.cropId}?location=@CWF`;
  const { data: crop } = await axios
    .get(cropUrl, {
      headers: { common: { Authorization: `Bearer ${getAccessToken()}` } }
    })
    .catch(() => ({ data: {} }));

  const centroId = cropZone.geometry ? centroid(cropZone.geometry) : {};

  return { cropZone, field, farm, crop, centroId };
};

export const getProductsExtraData = async productId => {
  const { promise: productPromise } = productsApi.fetch(productId);
  const { data: masterProduct } = await productPromise.catch(() => ({
    data: {}
  }));

  const { promise: activeIngredientsPromise } = productsApi.fetch(
    `${productId}/activeIngredients`
  );

  const {
    data: activeIngredients
  } = await activeIngredientsPromise.catch(() => ({ data: [] }));

  const workerProtection = {
    productName: masterProduct.name,
    key: masterProduct.id,
    signalword: displayText(masterProduct.signalword),
    rup: displayText(masterProduct.restricteduse?.toString()),
    ppe: displayText(masterProduct.ppe),
    ppereentry: displayText(masterProduct.ppereentry)
  };
  const { promise: reiphisPromise } = reiphisAPI.fetch(productId);
  const { data: reiphisData } = await reiphisPromise.catch(() => ({
    data: []
  }));
  return {
    masterProduct,
    activeIngredients,
    reiphisData,
    workerProtection
  };
};
