const returnApplicationMethodLabel = products => {
  if (!products || products.length === 0) {
    return '';
  }

  if (products[0].applicationMethod === 'Fumigation') {
    return 'Fumigation';
  }

  const uniqueApplicationsMethod = [
    ...new Set(products.map(prod => prod.applicationMethod || 'None'))
  ];

  return uniqueApplicationsMethod.join(', ');
};

export default returnApplicationMethodLabel;
