import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@agconnections/grow-ui';
import { customDateFormat } from 'helpers/dateFormat';
import FormattedNumber from 'components/FormattedNumber';

import defaultIcon from 'assets/AllCropSVGs/fallow.svg';
import CropIcons from 'helpers/getCropIconByCropName';
import CropIconMapping from 'components/CropIconMapping';
import FieldIcon from '../../../../../components/FieldIcon';

const PropertyCropHistoryRow = ({ data, isField }) => {
  const regex = / /g;
  const scrubbedProduct = {
    ...data,
    name: data?.crop?.replace(regex, '')
  };

  const cropName = scrubbedProduct?.name?.includes(':')
    ? scrubbedProduct?.name.split(':')[0].trim()
    : scrubbedProduct?.name;

  return (
    <Table.Row>
      <Table.Cell>{data?.cropSeasonName}</Table.Cell>
      <Table.Cell>
        <div className="flex gap-1">
          {cropName in CropIcons ? (
            <CropIconMapping cropObject={scrubbedProduct} />
          ) : (
            <img src={defaultIcon} alt="" />
          )}
          {data?.crop}
        </div>
      </Table.Cell>
      <Table.Cell>{data?.variety}</Table.Cell>
      <Table.Cell>
        {customDateFormat(data?.plantingDate, 'MMM dd, yyyy')}
      </Table.Cell>
      <Table.Cell>
        {isField ? (
          <div>
            {data?.cropZones?.map((cropZone, index) =>
              index <= 2 ? (
                <div key={cropZone?.id} className="flex gap-1 items-start">
                  <FieldIcon
                    id="field-card-icon"
                    width={18}
                    height={18}
                    features={[
                      { geometry: cropZone.geometry, type: 'Feature' }
                    ]}
                  />
                  <div>{cropZone.name}</div>
                </div>
              ) : (
                <div key={cropZone?.productId}>
                  + {data?.cropZones?.length - 3} more crop zones
                </div>
              )
            )}
            <div className="text-xs text-neutral-60 text-left">
              {data?.area ?? 0} ac
            </div>
          </div>
        ) : (
          <>
            <FormattedNumber roundedTo={2}>{data?.area ?? 0}</FormattedNumber>{' '}
            ac
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

PropertyCropHistoryRow.propTypes = {
  data: PropTypes.shape({
    cropSeasonName: PropTypes.string.isRequired,
    cropZones: PropTypes.arrayOf(PropTypes.object).isRequired,
    crop: PropTypes.string.isRequired,
    variety: PropTypes.string.isRequired,
    plantingDate: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }).isRequired,
  isField: PropTypes.bool.isRequired
};

export default PropertyCropHistoryRow;
