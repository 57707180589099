import React from 'react';

import { Table, Checkbox } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';

const ConvertPlanTableRow = ({ products, onAction }) => {
  return (
    <>
      {products &&
        products
          .filter(p => !p.hide)
          .map((product, index) => {
            return (
              <Table.Row key={product.productId}>
                <Table.Cell>
                  <Checkbox
                    onChange={event => onAction(event.target.checked, index)}
                    id="product.marked"
                    checked={product.marked}
                  />
                </Table.Cell>
                <Table.Cell>
                  <div className="text-left">
                    <div className="font-bold">{product.productName}</div>
                    <div className="mt-2 text-neutral-300">
                      {product.manufacturerName}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>{`${product.productRateValue} ${product.productRateUnit}`}</Table.Cell>
                <Table.Cell>{`${product.areaValue} ac`}</Table.Cell>
                <Table.Cell>{`${Number.parseFloat(product.coveragePercent) *
                  100}%`}</Table.Cell>
                <Table.Cell>{`${product.totalProductValue} ${product.totalProductUnit}`}</Table.Cell>
                <Table.Cell>{`$${product.totalProductCost}`}</Table.Cell>
              </Table.Row>
            );
          })}
    </>
  );
};

ConvertPlanTableRow.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAction: PropTypes.func.isRequired
};

export default ConvertPlanTableRow;
