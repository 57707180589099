import { format } from 'date-fns';

/* eslint-disable import/prefer-default-export */
export const formatEpochDate = date => {
  return date ? format(date, 'MMM d, yyyy') : null;
};

/**
 * Formats a date to a specific format: "Sep 15 2023 08:00AM".
 *
 * @param {string} date - The date to format.
 * @returns {string} The formatted date string. If the date is null or undefined, returns undefined.
 *
 * @example
 * "Sep 15, 2023 08:00AM"
 */
export const formatDateToShortMonthDayYearTime = date => {
  const dateObj = new Date(date);
  return dateObj
    ?.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })
    .replace(/(\d{2}:\d{2})\s?(AM|PM)/, '$1$2') // remove space between time and AM/PM
    .replace(/(\d{4}),/, '$1'); // remove comma after year
};
