/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import {
  Row,
  Col,
  AntdConfigProvider
} from 'syngenta-digital-cropwise-react-ui-kit';
import { Button } from '@agconnections/grow-ui';
import { ClearOutlined } from '@material-ui/icons';
import { Context } from 'components/Store';
import EditOutlined from '@material-ui/icons/EditOutlined';
import OrgIcon from 'assets/property_org.svg';
import FarmIcon from 'assets/property_farm_icon.svg';
import FieldIcon from 'assets/property_field_icon.svg';
import CropzoneIcon from 'assets/property_cropzone_icon_24px.svg';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { PROPERTY_VIEW_MODE } from '../helpers/constants';
import { calculateFarmAcres } from '../helpers/leftPanel';
import FormattedNumber from '../../../../components/FormattedNumber';
import FilterByCropsSelect from '../PropertyCostAnalysis/components/FilterByCropSelect';
import '../index.css';

const PropertyTopHeader = ({
  enableEdit,
  setEnableEdit,
  setCancelEditModalVisible,
  edited,
  properties,
  selectedCrop,
  setSelectedCrop,
  viewMode
}) => {
  const [{ organization, selectedProperty }] = useContext(Context);
  const [totalAcres, setTotalAcres] = useState(null);

  const {
    releaseCostAnalysisViewEnable,
    releaseCostAnalysisViewEnableByOrg
  } = useFlags();

  const costAnalysisViewEnabled = featureFlagRenderItem(
    releaseCostAnalysisViewEnable,
    releaseCostAnalysisViewEnableByOrg,
    organization.id
  );

  const displayCropSelection =
    (costAnalysisViewEnabled &&
      selectedProperty.type !== 'Farm' &&
      viewMode === PROPERTY_VIEW_MODE.COST) ||
    (selectedProperty.type === 'Crop Zone' &&
      viewMode === PROPERTY_VIEW_MODE.MAP);

  useEffect(() => {
    if (!selectedProperty.id) {
      setTotalAcres(
        calculateFarmAcres(_.flatten(properties.map(({ fields }) => fields)))
      );
    } else if (selectedProperty.reportedArea > 0) {
      setTotalAcres(selectedProperty.reportedArea);
    } else if (selectedProperty.fields) {
      const acres = calculateFarmAcres(selectedProperty.fields);
      setTotalAcres(acres);
    } else {
      setTotalAcres(selectedProperty.boundaryArea);
    }
  }, [selectedProperty, properties]);

  const editHeader = () => {
    return (
      <div className="flex flex-row w-full p-4 bg-white justify-between">
        <div className="flex">
          <div className="bg-neutral-100 w-10 h-10 flex mr-3 rounded">
            <div className="m-auto" style={{ height: '24px', width: '24px' }}>
              <EditOutlined />
            </div>
          </div>
          <div className="flex flex-col">
            <span className="font-semibold">
              Edit {selectedProperty.type} Information: {selectedProperty.name}{' '}
            </span>
            <span>
              Specific property boundary relevant content not contained within
              any crop season
            </span>
          </div>
        </div>

        <Button
          style={{
            alignSelf: 'end'
          }}
          type="text"
          icon={<ClearOutlined />}
          onClick={() => {
            if (edited) {
              setCancelEditModalVisible(prev => !prev);
            } else {
              setEnableEdit(false);
            }
          }}
        />
      </div>
    );
  };

  const propertyIcon = () => {
    switch (selectedProperty.type) {
      case 'Farm':
        return FarmIcon;
      case 'Field':
        return FieldIcon;
      case 'Crop Zone':
        return CropzoneIcon;
      default:
        return OrgIcon;
    }
  };

  return (
    <AntdConfigProvider prefixCls="syt-antd">
      {!enableEdit ? (
        <Row
          className="p-4 border-b flex-no-wrap"
          data-testid="property-top-header"
        >
          <Col className="content-center">
            <img src={propertyIcon()} alt="property-icon" />
          </Col>
          <Col
            className="ml-2 h-8 content-center text-xl font-semibold truncate"
            data-testid="selected-property-name"
          >
            {selectedProperty.name ?? organization.name}
          </Col>
          <Col className="ml-4 content-center text-sm text-gray-900 whitespace-nowrap flex-shrink-0">
            <FormattedNumber roundedTo={2}>{totalAcres}</FormattedNumber> ac
          </Col>
          <Col flex="auto" />
          <Col className="content-center ml-4 flex-shrink-0">
            {displayCropSelection && (
              <FilterByCropsSelect
                selectedCrop={selectedCrop}
                setSelectedCrop={setSelectedCrop}
              />
            )}
          </Col>
        </Row>
      ) : (
        editHeader()
      )}
    </AntdConfigProvider>
  );
};

PropertyTopHeader.defaultProps = {
  properties: [],
  selectedCrop: null,
  setSelectedCrop: () => {},
  viewMode: PROPERTY_VIEW_MODE.MAP
};

PropertyTopHeader.propTypes = {
  edited: PropTypes.bool.isRequired,
  setCancelEditModalVisible: PropTypes.bool.isRequired,
  enableEdit: PropTypes.bool.isRequired,
  setEnableEdit: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape()),
  selectedCrop: PropTypes.arrayOf(PropTypes.string),
  setSelectedCrop: PropTypes.func,
  viewMode: PropTypes.string
};

export default PropertyTopHeader;
