/* eslint-disable */

import React, { useEffect } from 'react';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import {
  performLogin,
  useAuth,
  isAccessTokenAvailable,
  accountsOAuthUrl
} from '.';

import { Spinner } from '@agconnections/grow-ui';

// tslint:disable-next-line:no-big-function
const LoginCallback = () => {
  const history = useHistory();
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const { fetchUserProfile, user } = useAuth();
  useEffect(() => {
    const locationSearch = location.search.replace('?', ''); //temp hack to replace ?
    const query = qs.parse(locationSearch);

    if (query && query.code && !query.state) {
      if (!sessionStorage.getItem(`cod${query.code}`)) {
        handleLogin(query.code);
      }
    } else if (isAccessTokenAvailable()) {
      fetchUserProfile();
    } else {
      window.location.href = accountsOAuthUrl;
    }
  }, []);

  useEffect(() => {
    if (user) {
      history.push('/app');
    }
  }, [user]);

  const handleLogin = code => {
    const userDetails = {
      grant_type: 'authorization_code',
      client_id: clientId,
      redirect_uri: `${location.origin}/authenticate`,
      code
    };
    sessionStorage.setItem(`cod${code}`, 'Y');
    performLogin(userDetails).then(() => {
      fetchUserProfile();
    });
  };

  return (
    <div className="m-auto h-screen w-full">
      <Spinner />
    </div>
  );
};

export default LoginCallback;
