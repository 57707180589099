import { ROLES } from 'utilities/access';

export const addElementToMenu = (menu, elementToAdd) => [...menu, elementToAdd];
export const addElementToBeginningMenu = (menu, elementToAdd) => [
  elementToAdd,
  ...menu
];
export const deleteMenuOption = (menu, option) =>
  menu.filter(item => item.value !== option);

export const viewOption = (minAllowedRole = '', label = '') => {
  return {
    value: 'view',
    text: 'View',
    label,
    minAllowedRole
  };
};

const editOption = (minAllowedRole = '') => {
  return {
    value: 'edit',
    text: 'Edit',
    minAllowedRole
  };
};

export const exportPdfEnglishOption = (minAllowedRole = '') => {
  return {
    value: 'exportPdfEnglish',
    text: 'Export PDF (English)',
    minAllowedRole
  };
};

export const exportPdfSpanishOption = (minAllowedRole = '') => {
  return {
    value: 'exportPdfSpanish',
    text: 'Export PDF (Spanish)',
    minAllowedRole
  };
};

export const shareOrPrintOption = (minAllowedRole = '') => {
  return {
    value: 'shareOrPrint',
    text: 'Share or Print',
    minAllowedRole
  };
};

const tagOption = (minAllowedRole = '') => {
  return { value: 'tag', text: 'Tag', minAllowedRole };
};

const duplicateOption = (minAllowedRole = '') => {
  return {
    value: 'duplicate',
    text: 'Duplicate',
    minAllowedRole
  };
};

const flagOption = (minAllowedRole = '') => {
  return {
    value: 'flag',
    text: 'Flag',
    minAllowedRole
  };
};

const convertToTaskOption = (minAllowedRole = '') => {
  return {
    value: 'convertTask',
    text: 'Convert to Task',
    minAllowedRole
  };
};

const convertToRecOption = (minAllowedRole = '') => {
  return {
    value: 'convertToRec',
    text: 'Convert to Recommendation',
    minAllowedRole
  };
};

export const deleteOption = (name = '', minAllowedRole = '') => {
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

  return {
    value: name,
    text: capitalizedName,
    className: 'text-red-500',
    minAllowedRole
  };
};

const createTaskOption = (minAllowedRole = '') => {
  return {
    value: 'createTask',
    text: 'Create Task From Invoice',
    minAllowedRole
  };
};

export const editBoundaryOption = (minAllowedRole = '') => {
  return {
    value: 'editBoundary',
    text: 'Edit Boundary',
    minAllowedRole
  };
};

export const addCropZoneOption = (minAllowedRole = '') => {
  return {
    value: 'addCropZone',
    text: 'Add Crop Zone',
    minAllowedRole
  };
};

export const editAccessOption = () => {
  return {
    value: 'editAccess',
    text: 'Edit Access'
  };
};

export const revokeInviteOption = () => {
  return {
    value: 'revokeInvite',
    text: 'Revoke Invite',
    className: 'text-red-500'
  };
};

export const revokeAccessOption = () => {
  return {
    value: 'revokeAccess',
    text: 'Revoke Access',
    className: 'text-red-500'
  };
};

export const resendInviteOption = () => {
  return {
    value: 'resendInvite',
    text: 'Resend Invite'
  };
};

export const createNewPropertyOption = () => {
  return {
    value: 'createNewProperty',
    key: 'Create New Property'
  };
};

// To any developer who needs to work in this file
// if you need to create a new variant of menu options create a new array
// instead of creating a weird logic, that way we can keep it simple
export const CROP_SEASONS_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const CROP_SEASON_VIEW_MENU = [
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const PLANS_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  convertToTaskOption(ROLES.FULL_CONTROL),
  convertToRecOption(ROLES.FULL_CONTROL),
  shareOrPrintOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const PLANS_PROPERTIES_TAB_OPTIONS = [
  editOption(),
  deleteOption('remove')
];

export const RECOMMENDATIONS_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  shareOrPrintOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TASKS_COMPLETE_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  exportPdfEnglishOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TASKS_COMPLETE_TASKLIST_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TASKS_COMPLETE_VIEW_MENU = [
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  exportPdfEnglishOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TASKS_READY_VIEW_MENU = [
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  exportPdfEnglishOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TASKS_READY_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  exportPdfEnglishOption(ROLES.VIEW_ONLY),
  exportPdfSpanishOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TASKS_READY_TASKLIST_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  exportPdfEnglishOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];
export const INVOICES_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  createTaskOption(ROLES.FULL_CONTROL),
  shareOrPrintOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const PEOPLE_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const PEOPLE_OPTIONS_MENU = [
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const COMPANIES_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const COMPANIES_VIEW_OPTIONS_MENU = [
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const EQUIPMENT_LANDING_MENU = [
  viewOption(ROLES.VIEW_ONLY),
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const EQUIPMENT_VIEW_MENU = [
  editOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const TAB_OPTIONS = [
  editOption(),
  deleteOption('remove', ROLES.FULL_CONTROL)
];

export const PRODUCTS_TABLE_OPTIONS = [
  flagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  editOption(ROLES.FULL_CONTROL),
  viewOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const PROPERTIES_MENU_OPTIONS = [
  editBoundaryOption(),
  createTaskOption(),
  deleteOption('delete')
];

export const HEADER_OPTIONS = [
  addCropZoneOption(),
  editBoundaryOption(),
  createTaskOption(),
  deleteOption('delete')
];

export const PROPERTIES_TABLE_ROW_GROUP_OPTIONS = [
  viewOption(ROLES.FULL_CONTROL),
  deleteOption('delete')
];

export const PLAN_MENU = [
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  duplicateOption(ROLES.FULL_CONTROL),
  convertToTaskOption(ROLES.FULL_CONTROL),
  convertToRecOption(ROLES.FULL_CONTROL),
  shareOrPrintOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const REC_MENU = [
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  shareOrPrintOption(ROLES.VIEW_ONLY),
  deleteOption('delete', ROLES.FULL_CONTROL)
];

export const INVOICE_VIEW_MENU = [
  editOption(ROLES.FULL_CONTROL),
  tagOption(ROLES.FULL_CONTROL),
  createTaskOption(ROLES.FULL_CONTROL),
  shareOrPrintOption(ROLES.FULL_CONTROL),
  deleteOption('delete', ROLES.FULL_CONTROL)
];
