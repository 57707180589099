import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { AgrianContext } from 'screens/Integrations/AgrianIntegration/context/Provider';
import { SimplotContext } from 'screens/Integrations/SimplotIntegration/context/Provider';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import reducer, {
  SET_OPEN_CONFIRMATION_MODAL,
  SET_MATCHES_SUBMISSION,
  SET_VENDOR_PROPERTIES,
  SET_SELECTED_CROPYEAR,
  SET_UNMATCHED_ENTITIES,
  SET_FARMS_TO_MATCH
} from '../reducer';
import usePropertiesMatch from '../../../hooks/usePropertiesMatch';

export const initialState = {
  matches: [],
  farmsMatch: [],
  vendorProperties: [],
  openConfirmationModal: false,
  errorMessage: '',
  matchEntityType: '',
  unmatchedEntities: -1
};

export const PropertiesMatchContext = createContext(initialState);

const PropertiesMatchProvider = ({
  integrationType,
  children,
  onCompleteMatch
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    integrationType,
    children,
    matchEntityType:
      integrationType === INTEGRATIONS.agrian ? 'Fields' : 'Crop Zones',
    selectedCropYear: integrationType === INTEGRATIONS.simplot ? 2023 : null
  });

  const {
    getPropertiesMatches,
    createMatches,
    getVendorProperties,
    loading: isLoading
  } = usePropertiesMatch();

  const updateFarmsMatch = farmsMatch => {
    dispatch({
      type: SET_FARMS_TO_MATCH,
      payload: farmsMatch
    });
  };

  const updateUnmatchedEntities = payload => {
    dispatch({
      type: SET_UNMATCHED_ENTITIES,
      payload
    });
  };

  const updateSelectedCropYear = cropYear => {
    dispatch({
      type: SET_SELECTED_CROPYEAR,
      payload: cropYear
    });
  };

  const updateMatches = payload => {
    dispatch({
      type: SET_MATCHES_SUBMISSION,
      payload
    });
  };

  const addPropertyMatchId = (cwfId, vendorId) => {
    const newMatches = [].concat(state.matches.filter(m => m.cwfId !== cwfId));
    newMatches.push({
      cwfId,
      vendorId
    });
    updateMatches(newMatches);
  };

  const updateVendorProperties = payload => {
    dispatch({
      type: SET_VENDOR_PROPERTIES,
      payload
    });
  };

  const submitMatches = async () => {
    const response = await createMatches({
      vendor: integrationType,
      data: state.matches.map(match => ({
        ...match,
        vendorPropertyName: state.vendorProperties.find(
          cz => cz.id === match.vendorId
        )?.name
      }))
    });

    if (response?.status === 201) {
      onCompleteMatch();
    }
  };

  const fetchMatches = async () => {
    const response = await getPropertiesMatches(integrationType);

    if (response?.status === 200) {
      updateMatches(response.data);
    }
  };

  const fetchVendorProperties = async () => {
    const response = await getVendorProperties({
      vendor: integrationType,
      cropYear: state.selectedCropYear
    });

    if (response?.status === 200) {
      updateVendorProperties(response.data);
    }
  };

  const setOpenConfirmationModal = bool => {
    dispatch({ type: SET_OPEN_CONFIRMATION_MODAL, payload: bool });
  };

  const defineIntegrationContext = () => {
    switch (state.integrationType) {
      case INTEGRATIONS.agrian:
        return AgrianContext;
      case INTEGRATIONS.simplot:
        return SimplotContext;
      default:
        return null;
    }
  };

  const memoized = useMemo(
    () => ({
      state,
      integrationContext: defineIntegrationContext(),
      dispatch,
      updateSelectedCropYear,
      updateUnmatchedEntities,
      addPropertyMatchId,
      updateMatches,
      submitMatches,
      fetchMatches,
      fetchVendorProperties,
      updateFarmsMatch,
      isLoading,
      setOpenConfirmationModal
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, isLoading]
  );

  return (
    <PropertiesMatchContext.Provider value={memoized}>
      {children}
    </PropertiesMatchContext.Provider>
  );
};

PropertiesMatchProvider.defaultProps = {
  children: null,
  integrationType: INTEGRATIONS.simplot,
  onCompleteMatch: null
};

PropertiesMatchProvider.propTypes = {
  children: PropTypes.node,
  integrationType: PropTypes.string,
  onCompleteMatch: PropTypes.func
};

export default PropertiesMatchProvider;
