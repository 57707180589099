import React, { useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Spinner, Toast } from '@agconnections/grow-ui';
import PropertyMapWrapper from 'screens/Property/components/PropertyMapWrapper';
import { FETCH_TYPES } from 'helpers/constants';
import FormNavGuard from 'components/FormNavGuard';
import usePopulateMapData from 'hooks/usePopulateMapData';
import FormSection from './FormSection';
import TopHeader from './TopHeader';

const CropSeasonForm = ({
  initialValues,
  onSubmit,
  schema,
  saving,
  totalArea,
  id,
  error,
  cropItems,
  setTotalArea,
  setError,
  blankValues
}) => {
  const transFormId = () => {
    if (id === 'create') {
      return '88888888-8888-8888-8888-888888888888';
    }
    return id;
  };
  const {
    dataSorted: propertyLandingPageData,
    fieldsAndAreasCentroidCoordinates,
    setFieldsAndAreasCentroidCoordinates,
    fieldsAndAreasGeoJSONCollection,
    fieldsLoading,
    loading,
    zoom,
    setZoom,
    setGeoJSONCollectionFeatures,
    reloadData
  } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap,
    cropSeasonsId: transFormId()
  });

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <div
            data-testid="crop-season-form"
            className="flex flex-col flex-1 -mx-4 -mt-3"
          >
            <TopHeader
              handleSubmit={handleSubmit}
              saving={saving}
              totalArea={totalArea.toFixed(2)}
              id={id}
            />
            {error && (
              <Toast icon="error" onClose={() => setError('')}>
                {error}
              </Toast>
            )}
            <FormNavGuard />
            <div className="flex flex-1">
              <FormSection
                values={values}
                properties={propertyLandingPageData?.properties}
                cropItems={cropItems}
                propertiesLoading={loading || !cropItems}
                setFieldValue={setFieldValue}
                setTotalArea={setTotalArea}
                fieldsAndAreasGeoJSONCollection={
                  fieldsAndAreasGeoJSONCollection
                }
                setGeoJSONCollectionFeatures={setGeoJSONCollectionFeatures}
                groupByDefaultValue={blankValues.propertiesGroupBy}
              />
              <div className="relative flex flex-col flex-auto">
                {!fieldsLoading ? (
                  <PropertyMapWrapper
                    properties={propertyLandingPageData?.properties}
                    zoom={zoom}
                    setZoom={setZoom}
                    currentCoordinates={fieldsAndAreasCentroidCoordinates}
                    setCurrentCoordinates={setFieldsAndAreasCentroidCoordinates}
                    displayOnly
                    geoJSONCollection={fieldsAndAreasGeoJSONCollection}
                    liveUpdate={false}
                    customStyles={{
                      focused: {
                        fillColor: 'rgba(0, 255, 0, 0.15)',
                        fillOutlineColor: 'rgba(255, 255, 255, 1)',
                        fillAntialias: true
                      }
                    }}
                  />
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
CropSeasonForm.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.shape()).isRequired,
  onSubmit: PropTypes.func.isRequired,
  schema: PropTypes.objectOf(PropTypes.shape()).isRequired,
  saving: PropTypes.bool.isRequired,
  totalArea: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  cropItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setTotalArea: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  blankValues: PropTypes.objectOf(PropTypes.shape()).isRequired
};

export default CropSeasonForm;
