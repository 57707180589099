import React, { useContext, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Context } from 'components/Store';
import { selectCropSeason } from 'utilities/updated-permissions';

import usePortal from 'hooks/usePortal';
import OrganizationImage from 'components/Icons/components/OrganizationImage';
import {
  GLOBAL_SET_ORGANIZATION,
  GLOBAL_SET_ORGANIZATIONS,
  RESET_MASS_ASSIGNER
} from 'reducers/reducer';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import createAction from 'helpers/createAction';
import useOrganizations from 'hooks/useOrganizations';
import { AddRounded } from '@material-ui/icons';
import BreadCrumbItem from './BreadCrumbItem';
import BreadcrumbDropDown from '../../../../Navigation/components/BreadcrumbDropDown';
import BreadcrumbCropSeasons from '../../../../Navigation/components/BreadcrumbCropSeasons';
import CreateOrganizationModal from '../../../../../screens/Organizations/components/CreateOrganizationModal';

const BREADCRUMB_PORTAL_DOM_ID = 'cwf-breadcrumb-portal';

const Breadcrumb = ({
  children,
  hideOrganizationDropdown,
  hideCropSeasonDropdown,
  onOrganizationSelect,
  onCropSeasonSelect,
  disabled,
  disabledCropSeasons
}) => {
  const [currentOrg, setCurrentOrg] = useState(null);
  const breadcrumbPortal = usePortal(BREADCRUMB_PORTAL_DOM_ID);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { updateIdentify } = useContext(AmplitudeContext);
  const [{ organization, organizations, cropSeasons }, dispatch] = useContext(
    Context
  );
  const { getDefaultOrg, loadedDefaultOrg, defaultOrg } = useOrganizations();
  const openModal = () => setCreateModalOpen(true);
  const closeModal = () => setCreateModalOpen(false);

  const orgDropDownItemProps = {
    name: org => org.name,
    isActive: org => organization && organization.id === org.id
  };

  const getCropSeasons = useCallback(async () => {
    await selectCropSeason(dispatch, null);
  }, [dispatch]);

  const handleOrgSelect = async org => {
    localStorage.setItem('selectedCropSeasons', JSON.stringify([]));
    createAction(dispatch, GLOBAL_SET_ORGANIZATION, org);
    if (!hideCropSeasonDropdown) await getCropSeasons();
    onOrganizationSelect(org);

    dispatch({ type: RESET_MASS_ASSIGNER });
  };

  const handleSeasonSelect = async seasons => {
    await selectCropSeason(dispatch, seasons);
    onCropSeasonSelect();
  };

  useEffect(() => {
    if (hideCropSeasonDropdown) {
      return;
    }
    getCropSeasons();
  }, [getCropSeasons, hideCropSeasonDropdown]);

  useEffect(() => {
    updateIdentify(organization?.name, organization?.id);
    setCurrentOrg(organization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    if (!defaultOrg) {
      return;
    }
    createAction(dispatch, GLOBAL_SET_ORGANIZATIONS, defaultOrg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrg]);

  const defaultBreadcrumb = (
    <>
      <div className="flex w-full">{children}</div>
    </>
  );
  if (process.env.JEST_WORKER_ID) {
    return defaultBreadcrumb;
  }
  return ReactDOM.createPortal(
    <>
      <CreateOrganizationModal
        open={createModalOpen}
        handleCancel={closeModal}
      />
      <div className="flex w-full mr-6 gap-2">
        {hideOrganizationDropdown ? null : (
          <div
            id="breadcrumb-organization"
            data-testid="breadcrumb-organization"
            className="flex items-center"
          >
            <div className="mr-2">
              <OrganizationImage />
            </div>
            {!disabled ? (
              <BreadcrumbDropDown
                dropDownItemProps={orgDropDownItemProps}
                getData={getDefaultOrg}
                organizations={organizations}
                onSelect={handleOrgSelect}
                searchPlaceholder="Search Organizations"
                title="Organization"
                value={currentOrg?.name ?? 'New Organization'}
                disabled
                dropDownFooter={props => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <button
                    data-testid="add-organization-button"
                    type="button"
                    onClick={event => {
                      event.stopPropagation();
                      openModal();
                      props.onClick(event);
                    }}
                  >
                    <div className="text-cucumber-green-700  text-sm font-normal hover:text-cucumber-green-500">
                      <div className="w-auto flex flex-row items-center">
                        <AddRounded style={{ width: '30%' }} />
                        <p style={{ whiteSpace: 'nowrap' }}>
                          Create Organization
                        </p>
                      </div>
                    </div>
                  </button>
                )}
                isLast={!children}
                loadedOrgs={loadedDefaultOrg}
              />
            ) : (
              <div
                data-testid="nav-menu-head"
                className="flex items-center flex-shrink-0 focus:border-none text-white"
              >
                <div>
                  <BreadCrumbItem
                    title="Organization"
                    value={currentOrg?.name ?? 'New Organization'}
                    isLast={!children}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {hideCropSeasonDropdown ? null : (
          <div
            id="breadcrumb-cropseason"
            data-testid="breadcrumb-cropseason"
            className="flex items-center"
          >
            <BreadcrumbCropSeasons
              getData={() => cropSeasons}
              onSelect={handleSeasonSelect}
              searchPlaceholder="Search Crop Seasons"
              title="Crop Season"
              disabled={disabledCropSeasons}
            />
          </div>
        )}
        {children ?? defaultBreadcrumb}
      </div>
    </>,
    breadcrumbPortal
  );
};

Breadcrumb.Item = BreadCrumbItem;
Breadcrumb.DropDown = BreadcrumbDropDown;

Breadcrumb.propTypes = {
  children: PropTypes.node,
  hideOrganizationDropdown: PropTypes.bool,
  hideCropSeasonDropdown: PropTypes.bool,
  onOrganizationSelect: PropTypes.func,
  onCropSeasonSelect: PropTypes.func,
  disabled: PropTypes.bool,
  disabledCropSeasons: PropTypes.bool
};

Breadcrumb.defaultProps = {
  children: null,
  hideOrganizationDropdown: false,
  hideCropSeasonDropdown: true,
  onOrganizationSelect: () => {},
  onCropSeasonSelect: () => {},
  disabled: false,
  disabledCropSeasons: false
};

export default Breadcrumb;
