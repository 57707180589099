import { useCallback, useRef, useContext, useEffect, useState } from 'react';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import PAGINATION_SIZE, {
  GET_ALL_PRODUCTS_IN_ORG
} from 'helpers/globalConstants';
import { inventoryProducts as productsApi } from 'utilities/api';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';
import filteredProducts from 'screens/ProductList/helpers/filteredProducts';

const useProducts = defaultParams => {
  const requestNumber = useRef(0);
  const [{ organization }, dispatch] = useContext(Context);
  const orgId = Context?._currentValue[0]?.organization?.id;
  const [allProducts, setAllProducts] = useState([]);
  const [isLoadingAllProducts, setIsLoadingAllProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productsCount, setProductsCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [request, setRequest] = useState({
    pageNo,
    size: PAGINATION_SIZE,
    ...defaultParams
  });
  const emptySelectedProduct = [];
  const getProducts = useCallback(
    async params => {
      setIsLoading(true);
      requestNumber.current += 1;
      const currentRequestNumber = requestNumber.current;
      const config = {
        ...request,
        ...params
      };
      const { promise } = await productsApi.fetch(undefined, config);

      await promise
        .then(({ data }) => {
          if (requestNumber.current === currentRequestNumber) {
            if (config?.searchBy?.length) {
              setProducts(
                filteredProducts(
                  data.results,
                  config?.searchBy,
                  emptySelectedProduct
                )
              );
              setProductsCount(data.totalElements);
              return;
            }
            setProducts(data.results);
            setProductsCount(data.totalElements);
          }
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request, dispatch, setIsLoading]
  );

  const getAllUseProducts = async () => {
    setIsLoadingAllProducts(true);
    const promise = axios.get(`${CROPWISE_PROXY_URL}/v1/inventory/products`, {
      headers: {
        'cwf-context': JSON.stringify({
          organization_id: orgId
        }),
        Authorization: `Bearer ${getAccessToken()}`
      },
      params: {
        size: GET_ALL_PRODUCTS_IN_ORG
      }
    });

    await promise
      .then(({ data }) => {
        setAllProducts(data.results);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setIsLoadingAllProducts(false);
      });
  };

  const reload = useCallback(async () => {
    getProducts();
  }, [getProducts]);

  const reset = () => {
    setRequest({
      ...request,
      pageNo,
      totalPages: 0,
      size: PAGINATION_SIZE
    });
  };

  useEffect(() => {
    reload();
  }, [reload, request, organization]);

  const filterByNameQuery = useCallback(
    newQuery => {
      setRequest(r => ({ ...r, pageNo: 0, searchBy: newQuery }));
    },
    [setRequest]
  );

  const goToPage = useCallback(
    (newPage, params) => {
      setPageNo(newPage);
      setRequest(r => ({ ...r, ...params, pageNo: newPage }));
    },
    [setRequest]
  );

  const sort = useCallback(
    (field, dir) => {
      setRequest(r => ({ ...r, pageNo: 0, sortBy: field, sortDir: dir }));
    },
    [setRequest]
  );

  return {
    getProducts,
    products,
    productsCount,
    pageNo: request.pageNo,
    goToPage,
    isLoading,
    reset,
    reload,
    filterByNameQuery,
    sort,
    sortBy: request.sortBy,
    sortDir: request.sortDir,
    getAllUseProducts,
    allProducts,
    isLoadingAllProducts
  };
};

export default useProducts;
