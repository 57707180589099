import React from 'react';
import BaseIntegration from 'screens/Integrations/components/BaseIntegration';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import StepSelector, { PROPERTIES_SELECTION_STEP } from '../StepSelector';
import { AGRIAN_RECOMMENDATION_STEPS } from '../..';

const progressBarSteps = AGRIAN_RECOMMENDATION_STEPS.map(step => ({
  caption: step.header
}));

const BaseAgrianIntegration = () => {
  return (
    <BaseIntegration
      integrationType={INTEGRATIONS.agrianRecommendation}
      progressBarSteps={progressBarSteps}
      selectPropertiesStep={PROPERTIES_SELECTION_STEP}
      StepSelector={StepSelector}
    />
  );
};

export default BaseAgrianIntegration;
