const events = {
  epic: {
    Authentication: { signIn: 'sign in' },
    Organization: {
      id: 'organizations',
      accessOrg: 'access organization',
      switchingOrgs: 'switching orgs',
      orgLoadingCount: 'orgs loading count'
    },
    Properties: {
      id: 'properties',
      accessProps: 'access properties',
      editFieldName: 'edit field name',
      addCropZone: 'add crop zone',
      createField: 'create field',
      createProperty: 'create property',
      archiveFarm: 'archive farm',
      unarchiveFarm: 'unarchive farm',
      archiveField: 'archive field',
      unarchiveField: 'unarchive field',
      filters: {
        toggleArchivedProperties: 'show archived properties'
      },
      viewCostAnalysis: 'view cost analysis'
    },
    ProductList: {
      editCustomProductCart: 'edit custom product cart',
      resetToListPrice: 'reset to list price',
      saveReiPhi: 'save rei phi',
      viewInvoice: 'view invoice',
      viewProductDetails: 'view product details',
      accessPPL: 'access product list'
    },
    PropertiesV2: {
      id: 'propertiesV2',
      accessPropertiesV2: 'access propertiesV2'
    },
    CropSeason: { id: 'crop seasons', accessCS: 'access crop season' },
    Plans: {
      id: 'plans',
      accessPlan: 'access plans',
      deletePlan: 'delete plan',
      createPlan: 'create plan'
    },
    Recommendations: {
      id: 'recommendations',
      accessRecommendations: 'access recommendations',
      createRecommendations: 'create recommendation',
      deleteRecommendations: 'delete recommendation'
    },
    Tasks: {
      id: 'tasks',
      accessTasks: 'access task',
      createTask: 'create task',
      deleteTask: 'delete task'
    },
    Invoices: { id: 'invoices', accessInvoice: 'access invoices' },
    People: {
      id: 'people',
      accessPeople: 'access people',
      deletePerson: 'delete person',
      editPerson: 'edit person',
      createPerson: 'create person'
    },
    Companies: {
      id: 'companies',
      accessCompanies: 'access companies',
      deleteCompany: 'delete company',
      createCompany: 'create company',
      editCompany: 'edit company'
    },
    Equipment: {
      id: 'equipment',
      accessEquipment: 'access equipment',
      createEquipment: 'create equipment',
      deleteEquipment: 'delete equipment'
    },
    Reports: {
      id: 'reports',
      accessReports: 'access report',
      reportSuccess: 'report success',
      reportFailed: 'report failed'
    },
    YieldV2: {
      saleLocations: {
        createSaleLocation: 'create sale location',
        editSaleLocation: 'edit sale location',
        deleteSaleLocation: 'delete sale location'
      },
      storageLocations: {
        goToLoads: 'select go to loads button',
        delete: 'delete storage location',
        editStorageLocation: 'edit storage location',
        createStorageLocation: 'create storage location',
        saveStorageLocation: 'save storage location',
        createCompany: 'create company'
      },
      addYield: {
        createYield: 'create yield',
        selectTemplate: 'select template',
        saveLoads: 'save loads',
        createNewSourceStorage: 'create new storage',
        saveProperties: 'save properties',
        selectUOM: 'select UOM'
      },
      loads: {
        selectCrop: 'select crop',
        deleteYield: 'delete yield load',
        editYield: 'edit yield'
      }
    },
    Integrations: {
      JohnDeere: {
        operationsMatched: 'operations matched',
        operationsUnmatched: 'operations unmatched',
        numberOfOperationAvailable: 'number of operations available',
        cropZoneMatchFailure: 'crop zone match failure',
        cropZoneMatchSuccess: 'crop zone match success',
        selectCropZone: 'select crop zone'
      },
      submissionStarted: 'submission started',
      submissionTaskClick: 'submission task click',
      submissionTaskRedirect: 'submission task redirect',
      submissionCredentialsFilled: 'submission credentials filled',
      submissionOrganizationFilled: 'submission organization filled',
      submissionSeasonSelected: 'submission season selected',
      submissionPropertiesSelected: 'submission properties selected',
      submissionProductsSelected: 'submission products selected',
      submissionFieldsFilledRegulatoryData:
        'submission fields filled regulatory data',
      submissionApplicatorDetailsFilled: 'submission applicator details filled',
      submissionRegulatoryDataReview: 'submission regulatory data review',
      submissionPropertiesMatched: 'submission properties matched',
      submissionCropZoneRemoved: 'submission crop zone removed',
      submissionRunning: 'submission running',
      submissionCompleted: 'submission completed',
      submissionCanceled: 'submission canceled',
      submissionEditClick: 'submission edit click',
      submissionRetryClick: 'submission retry click'
    }
  }
};

export default events;
