import React, { useState, useContext } from 'react';
import { Column, Columns, Spinner } from '@agconnections/grow-ui';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import Paper from 'components/Paper';
import DeleteModal from 'components/Modals/DeleteModal';
import { recommendation as recommendationApi } from 'utilities/api';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useTagsData from 'hooks/useTagsData';
import { Context } from 'components/Store';
import EllipseMenu from 'components/EllipseMenu';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { REC_MENU } from 'utilities/menus';
import useRecEllipseOptions from 'screens/Recommendations/hooks/useRecEllipseMenu';

import TagsModal from 'components/Modals/TagsModal';
import { paths } from 'routes/paths';
import TopHeaderDetails from '../components/TopHeaderDetails';
import useRecommendationData from './hooks/useRecommendationData';
import DetailsSection from './components/DetailsSection';
import ApplicatorSection from './components/ApplicatorSection';
import PropertiesSection from './components/PropertiesSection';
import ProductsSection from './components/ProductsSection';
import NotesSection from './components/NotesSection';

const RecommendationView = () => {
  const history = useHistory();
  const { id } = useParams();
  const [, dispatch] = useContext(Context);
  const { loading, recommendation, setRecommendation } = useRecommendationData(
    id
  );
  const { tags, loading: isTagsLoading, getTags } = useTagsData();

  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    id: null
  });
  const [openTags, setOpenTags] = useState(false);
  const [allFlags, setAllFlags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [, setError] = useState(null);

  const amplitude = useContext(AmplitudeContext);

  const { shareOrPrint, downloadingPDF } = useRecEllipseOptions();

  const handleAction = async action => {
    const tag =
      recommendation.flagIds?.length > 0 &&
      recommendation.flagIds !== undefined &&
      recommendation.flagIds.map(flagId => {
        return tags.find(t => t.id === flagId);
      });

    const actions = {
      edit: () => {
        history.push({
          pathname: `${paths.recommendations}/${recommendation.id}`
        });
      },
      tag: () => {
        setAllFlags(
          recommendation.flagIds?.length > 0 ||
            recommendation.flagIds !== undefined
            ? tag
            : []
        );
        setOpenTags(true);
      },
      duplicate: () => {},
      shareOrPrint,
      delete: () => {
        setDeleteModalState({ id: recommendation.id, open: true });
      }
    };
    actions[action](recommendation.id);
  };

  const handleDelete = async () => {
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Recommendations.deleteRecommendations
    );
    const { promise } = recommendationApi.delete(deleteModalState.id);
    await promise.then(() => {
      history.replace(paths.recommendations);
    });
  };

  const handleReturnTags = async e => {
    setTagsLoading(true);
    const body = {
      ...recommendation,
      flagIds: e.map(item => item.id)
    };
    const { promise } = await recommendationApi.update(recommendation.id, body);
    await promise
      .then(data => {
        setRecommendation(data.data);
        setAllFlags(e);
        getTags();
      })
      .catch(catchCancel)
      .catch(err => {
        if (err.response?.data?.validationErrors) {
          setError(`Error saving task: ${err.response.data.message}`);
        } else if (err.response?.data?.displayErrorMessage) {
          setError(err.response.data.displayErrorMessage);
        } else {
          parseServerError(dispatch)(err);
        }
      });

    setTagsLoading(false);
  };

  const appIsLoading = loading || isTagsLoading || tagsLoading;

  return (
    <>
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Recommendations"
          value="All Recommendations"
          to={paths.recommendations}
        />
        <Breadcrumb.Item
          title="Recommendation"
          value={recommendation?.title || 'View Recommendation'}
          isLast
        />
      </Breadcrumb>
      {appIsLoading ? (
        <Spinner />
      ) : (
        <div id="rec-view">
          <div className="px-5">
            <Columns alignVertically>
              <Column grow>
                <TopHeaderDetails
                  proposedDate={new Date(recommendation.proposedDate)}
                  numProducts={
                    recommendation.recommendationProductEntries.length
                  }
                  totalApplied={recommendation.cropZones
                    .reduce((total, cropZone) => total + cropZone.areaValue, 0)
                    .toFixed(2)}
                />
              </Column>
              <Column>
                <div className="flex justify-end">
                  {downloadingPDF ? (
                    <Spinner size="sm" />
                  ) : (
                    <EllipseMenu onAction={handleAction} options={REC_MENU} />
                  )}
                </div>
              </Column>
            </Columns>
          </div>
          <div style={{ minWidth: 'min-content' }}>
            <DeleteModal
              open={deleteModalState.open}
              itemType="Recommendation"
              onCancel={() => setDeleteModalState({ open: false, id: null })}
              onDelete={handleDelete}
            />
            <TagsModal
              open={openTags}
              close={() => {
                setOpenTags(false);
              }}
              name={recommendation.title}
              selectedTags={
                allFlags.length > 0
                  ? allFlags?.reduce((tagIds, tag) => {
                      return `${tagIds},${tag.id}`;
                    }, '')
                  : ''
              }
              returnSelectedTags={e => {
                handleReturnTags(e);
              }}
            />
            <Paper>
              <div className="m-4">
                <DetailsSection recommendation={recommendation} />
                <hr className="my-6" />
                <ApplicatorSection
                  applicators={recommendation.applicators ?? []}
                />
                <hr className="my-6" />
                <PropertiesSection recommendation={recommendation} />
                <ProductsSection recommendation={recommendation} />
                <NotesSection notes={recommendation.notes} />
              </div>
            </Paper>
          </div>
        </div>
      )}
    </>
  );
};

export default RecommendationView;
