import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import useSubmission from 'hooks/useSubmission';
import { useToast } from 'components/ToastContainer';

import useIntegrationMatching from 'hooks/useIntegrationMatching';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import extractCropZoneIds from 'screens/Integrations/helpers/extractCropZoneIds';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';

import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import { getTasksFromFarms } from 'screens/Integrations/helpers/extractFromFarms';
import reducer, {
  SET_SUBMISSION_DATA,
  SET_SUBMISSION_DONE,
  SET_OPEN_REMOVE_CROPZONE_MODAL,
  SET_OPEN_CONFIRMATION_MODAL,
  SET_SUBMISSION_FILTER_CROPZONE,
  SET_MATCHES,
  SET_SUBMISSION_ID,
  SET_CROPSEASONS,
  SET_SELECTED_PRODUCTS,
  SET_PRODUCTS,
  SET_FIELDS,
  SET_OPEN_REMOVE_TASK_MODAL,
  SET_OPEN_DRAWER_COUNTIES
} from '../reducer';

export const initialState = {
  farms: [],
  cropSeasons: [],
  selectedProducts: [],
  products: [],
  fields: [],
  tasks: [],
  extraData: undefined,
  submissionResult: {},
  matches: {},
  selectedFarm: 0,
  submissionFilterCropZoneIds: [],
  submissionId: null,
  cropSeasonStartDate: null,
  cropSeasonEndDate: null,
  openConfirmationModal: false,
  openRemoveCropzoneModal: false,
  openDrawerCounties: false,
  cropzoneToRemove: null,
  setOpenRemoveTaskModal: false,
  taskToRemove: null,
  errorMessage: '',
  submissionDone: false,
  isReadOnly: false,
  loadedSubmission: false,
  pastSubmissions: []
};

export const SubmissionSummaryContext = createContext(initialState);

const SubmissionSummaryProvider = ({ integrationType, children }) => {
  const orgId = localStorage.getItem('selectedOrganizationId');
  const {
    state: { submissionFlowId }
  } = useContext(IntegrationsContext);
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const {
    determineSubmissionId,
    isReadOnly,
    comingFromView
  } = useSubmissionUrlHandler();
  const { setAllDataFromSubmission } = useFormikHandler();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    integrationType,
    submissionId: determineSubmissionId(),
    isReadOnly,
    resubmitEnabled: isReadOnly || comingFromView,
    orgId
  });

  const toast = useToast();

  const {
    createSubmission,
    getSubmission,
    getProducts,
    getFieldsDetailsBySubmission,
    updateFieldsDetailsBySubmission,
    updateProducts: updateSubmissionProducts,
    deleteSubmissionCropZone,
    deleteTaskFromSubmissionCropZone,
    submit,
    resubmit,
    loading: isLoading
  } = useSubmission();

  const {
    createMatchingProducts,
    getMatches,
    loading: isLoadingProductMatching
  } = useIntegrationMatching();

  const resolveProductMatching = async () => {
    const products = state.farms
      .flatMap(property =>
        property.fields.flatMap(field =>
          field.cropzones.flatMap(cropzone =>
            cropzone.tasks.flatMap(task =>
              task.products.flatMap(product => ({
                ...product,
                id: product.productId,
                name: product.productName,
                type: product.productType,
                density: product.productDensity
              }))
            )
          )
        )
      )
      .reduce((acc, product) => {
        const find = acc.find(p => p.productId === product.productId);
        if (!find) {
          acc.push(product);
        }

        return acc;
      }, []);

    return createMatchingProducts({
      vendor: state.integrationType.toLowerCase(),
      products
    });
  };

  const updateMatches = matches => {
    dispatch({
      type: SET_MATCHES,
      payload: matches
    });
  };

  const resolveMatches = async () => {
    const response = await getMatches({
      vendor: state.integrationType,
      matchTypes: ['Organization', 'CropZone', 'Field']
    });

    if (response?.status === 200) {
      const matches = response.data?.reduce((acc, match) => {
        if (state.integrationType === 'Simplot') {
          acc[match.cwfEntityId] =
            match.simplotEntityDescription ?? match.simplotEntityId;
        }

        if (state.integrationType === 'Agrian')
          acc[match.cwfEntityId] =
            match.agrianEntityDescription ?? match.agrianEntityId;

        return acc;
      }, {});
      updateMatches(matches);
    }
  };

  const updateProducts = _products => {
    dispatch({
      type: SET_PRODUCTS,
      payload: _products
    });
  };

  const updateFields = _fields => {
    dispatch({
      type: SET_FIELDS,
      payload: _fields
    });
  };

  const updateSubmissionId = _submissionId => {
    dispatch({
      type: SET_SUBMISSION_ID,
      payload: _submissionId
    });
  };

  const updateSubmissionFilterCropZoneIds = cropZoneIds => {
    dispatch({
      type: SET_SUBMISSION_FILTER_CROPZONE,
      payload: cropZoneIds
    });
  };

  const updateCropSeasons = _cropSeasons => {
    dispatch({
      type: SET_CROPSEASONS,
      payload: _cropSeasons
    });
  };

  const submitIntegration = async () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionRunning, {
      vendor: integrationType,
      submissionId: state.submissionId,
      flowId: submissionFlowId
    });
    const productMatchingResponse = await resolveProductMatching();
    if (productMatchingResponse?.status !== 201) {
      return;
    }

    const response = await submit(state.submissionId, integrationType);
    if (response?.status === 201) {
      dispatch({
        type: SET_SUBMISSION_DONE,
        payload: response.data?.response
      });
    }
  };

  const submitIntegrationWithExtraData = async extraData => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionRunning, {
      vendor: integrationType,
      submissionId: state.submissionId,
      flowId: submissionFlowId
    });

    const response = await submit(
      state.submissionId,
      integrationType,
      extraData
    );
    if (response?.status === 201) {
      const { status, errorMessage, response: vendorResponse } = response.data;

      if (status !== 'error') {
        dispatch({
          type: SET_SUBMISSION_DONE,
          payload: response.data?.response
        });
        return;
      }

      toast.error('Submission unable to be sent', {
        content:
          vendorResponse?.details
            ?.flatMap(detail => detail.errorMessage.split(';'))
            .join('\n') ?? errorMessage,
        supportButton: true,
        timeout: 60000
      });
    }
  };

  const resubmitIntegration = async () => {
    const response = await resubmit(state.submissionId);

    if (response?.status !== 201) {
      return null;
    }

    return response;
  };

  const startSubmission = async () => {
    const response = await createSubmission({
      seasonIds: state.cropSeasons.map(s => s.id),
      cropzoneIds: state.submissionFilterCropZoneIds,
      vendor: integrationType.toLowerCase(),
      submissionId: state.submissionId
    });

    if (response?.status === 201) {
      updateSubmissionId(response.data.id);
    }
  };

  const loadSubmission = async () => {
    const response = await getSubmission(state?.submissionId);
    if (response?.status === 200 && response?.data) {
      const { data: cwfInternalData, extraData } = response.data.cwfData;
      if (cwfInternalData) {
        const { farms, cropSeasons } = cwfInternalData;
        dispatch({
          type: SET_SUBMISSION_DATA,
          payload: {
            farms,
            extraData,
            cropSeasons,
            cropZoneIds: extractCropZoneIds(farms),
            tasks: getTasksFromFarms(farms),
            pastSubmissions: response?.data?.pastSubmissions,
            submissionResult: {
              status: response?.data?.status,
              errorMessage: response?.data?.errorMessage,
              response: response?.data?.response
            }
          }
        });
      }
    }
  };

  const getSubmissionProducts = async () => {
    const response = await getProducts(state?.submissionId);
    if (response?.status === 200) {
      updateProducts(response.data);
    }
  };

  const setSubmissionProducts = async () => {
    const response = await updateSubmissionProducts(
      state?.submissionId,
      state?.selectedProducts
    );

    return response;
  };

  const getSubmissionFields = async () => {
    const response = await getFieldsDetailsBySubmission(state?.submissionId);
    if (response?.status === 200) {
      updateFields(response.data);
    }
    return response;
  };

  const setSubmissionFields = async updatedFields => {
    const response = await updateFieldsDetailsBySubmission(
      state?.submissionId,
      updatedFields
    );
    if (response?.status === 200) {
      updateFields(
        state?.fields.map(field => {
          const foundUpdatedField = updatedFields.find(
            updatedField =>
              updatedField.fieldId === field.fieldId &&
              updatedField.cropZoneId === field.cropZoneId
          );
          if (foundUpdatedField) {
            return foundUpdatedField;
          }
          return field;
        })
      );
    }
    return response;
  };

  const setOpenConfirmationModal = bool => {
    dispatch({ type: SET_OPEN_CONFIRMATION_MODAL, payload: bool });
  };

  const setOpenRemoveCropzoneModal = (bool, cropzone) => {
    dispatch({
      type: SET_OPEN_REMOVE_CROPZONE_MODAL,
      payload: {
        open: bool,
        cropzone
      }
    });
  };

  const removeCropzone = async () => {
    if (!state.cropzoneToRemove) return;

    const { id } = state.cropzoneToRemove;
    await deleteSubmissionCropZone({
      submissionId: state.submissionId,
      cropZoneId: id
    });

    await loadSubmission();
  };

  const setOpenRemoveTaskModal = (bool, task) => {
    dispatch({
      type: SET_OPEN_REMOVE_TASK_MODAL,
      payload: {
        open: bool,
        task
      }
    });
  };

  const removeTaskFromCropzone = async () => {
    const { submissionId, taskToRemove } = state;

    if (!taskToRemove || !submissionId) return;
    const { cropZoneId, taskId } = taskToRemove;

    await deleteTaskFromSubmissionCropZone({
      submissionId,
      cropZoneId,
      taskId
    });

    await loadSubmission();
  };

  const setFormikDataBySubmission = () => {
    setAllDataFromSubmission(
      orgId,
      state.cropSeasons,
      state.farms,
      state.submissionFilterCropZoneIds
    );
  };

  const setOpenDrawerCounties = open => {
    dispatch({
      type: SET_OPEN_DRAWER_COUNTIES,
      payload: open
    });
  };

  const setSelectedProducts = _selectedProducts => {
    dispatch({
      type: SET_SELECTED_PRODUCTS,
      payload: _selectedProducts
    });
  };

  const memoized = useMemo(
    () => ({
      state,
      dispatch,
      submitIntegration,
      resubmitIntegration,
      startSubmission,
      loadSubmission,
      resolveMatches,
      isLoadingProductMatching,
      isLoading,
      setOpenConfirmationModal,
      setOpenRemoveTaskModal,
      removeTaskFromCropzone,
      setOpenRemoveCropzoneModal,
      removeCropzone,
      updateSubmissionFilterCropZoneIds,
      updateCropSeasons,
      setFormikDataBySubmission,
      getSubmissionProducts,
      setSubmissionProducts,
      updateFields,
      getSubmissionFields,
      setSubmissionFields,
      setSelectedProducts,
      setOpenDrawerCounties,
      submitIntegrationWithExtraData
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, isLoading, isLoadingProductMatching]
  );

  return (
    <SubmissionSummaryContext.Provider value={memoized}>
      {children}
    </SubmissionSummaryContext.Provider>
  );
};

SubmissionSummaryProvider.defaultProps = {
  children: null,
  integrationType: INTEGRATIONS.simplot
};

SubmissionSummaryProvider.propTypes = {
  children: PropTypes.node,
  integrationType: PropTypes.string
};

export default SubmissionSummaryProvider;
