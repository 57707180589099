import { useCallback, useContext, useEffect, useState } from 'react';

import { Context } from 'components/Store';
import { orderBy } from 'lodash';
import catchCancel from 'helpers/catchCancel';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { parseServerError } from 'helpers/errorHelpers';
import { recommendations as recommendationsApi } from 'utilities/api';
import {
  filterResultByDateRange,
  filterResultByTitle
} from 'helpers/resultsFiltering';
import { resetOrg } from 'utilities/recommendationsFakeAPI';

const useRecommendations = () => {
  const [, dispatch] = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);

  const [recommendations, setRecommendations] = useState([]);

  const [recommendationsCount, setRecommendationsCount] = useState(0);
  const [recPageNumber, setRecPageNumber] = useState();

  const [request, setRequest] = useState({
    page: recPageNumber || 0,
    size: PAGINATION_SIZE,
    dateRange: [],
    titleQuery: '',
    sortBy: '',
    sortDir: '',
    cropSeasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons'))
  });

  const getRecommendations = useCallback(
    async (newData, pageNo, reportCropSeasonIds) => {
      setIsLoading(true);

      if (newData) {
        setRecommendations(newData);
      } else {
        const { promise } = recommendationsApi.fetch(
          null,
          {
            pageNo,
            seasonIdsCommaSeparated:
              reportCropSeasonIds ||
              JSON.parse(localStorage.getItem('selectedCropSeasons')).join(',')
          },
          {},
          null,
          false
        );

        await promise
          .then(({ data }) => {
            const results = data.results
              .filter(result => filterResultByTitle(result, request))
              .filter(result => filterResultByDateRange(result, request));
            setRecommendations(orderBy(results, 'proposedDate', 'desc'));
            setRecommendationsCount(data.totalElements);
          })
          .catch(catchCancel)
          .catch(err => {
            parseServerError(dispatch)(err);
          })
          .finally(() =>
            setTimeout(() => {
              setIsLoading(false);
            }, 700)
          );
      }
      setIsLoading(false);
    },
    [dispatch, request]
  );

  const reset = () => {
    setRequest({
      ...request,
      page: recPageNumber || 0,
      totalPages: 0,
      size: PAGINATION_SIZE,
      cropSeasonIds: JSON.parse(localStorage.getItem('selectedCropSeasons'))
    });
  };

  const reload = useCallback(
    async (newData = null) => {
      getRecommendations(newData);
      setRecPageNumber();
    },
    [getRecommendations]
  );

  useEffect(() => {
    return () => {
      // destroy fake api records
      resetOrg();
    };
  }, []);

  useEffect(() => {
    reload();
  }, [reload, request]);

  const goToPage = useCallback(
    newPage => {
      getRecommendations(null, newPage);
      setRequest(r => ({
        ...r,
        page: newPage
      }));
      setRecPageNumber(newPage);
    },
    [setRequest, getRecommendations]
  );

  const filterByDateRange = useCallback(
    newDateRange => {
      if (newDateRange.length === 2 || newDateRange.length === 0) {
        setRequest(r => ({ ...r, page: 0, dateRange: newDateRange }));
      }
    },
    [setRequest]
  );

  const filterByTitleQuery = useCallback(
    newQuery => {
      setRequest(r => ({ ...r, page: 0, titleQuery: newQuery }));
    },
    [setRequest]
  );

  const sort = useCallback(
    (field, dir) => {
      setRequest(r => ({ ...r, page: 0, sortBy: field, sortDir: dir }));
    },
    [setRequest]
  );

  return {
    isLoading,
    recommendations,
    recommendationsCount,
    page: request.page,
    goToPage,
    dateRange: request.dateRange,
    filterByDateRange,
    titleQuery: request.titleQuery,
    filterByTitleQuery,
    sort,
    sortBy: request.sortBy,
    sortDir: request.sortDir,
    reload,
    reset,
    getRecommendations
  };
};

export default useRecommendations;
