import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PropertyRow from 'screens/Property/PropertiesLanding/PropertyTable/PropertyRow';
import { Input, Select, Table } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import Editor from 'screens/Property/PropertiesLanding/components/Editor';
import { useFormikContext } from 'formik';
import { OpenInNewIcon } from 'components/Icons';
import {
  APPLICATOR_TYPES,
  PCO_APPLICATOR_TYPES
} from 'screens/Integrations/CalAgIntegration/helpers/constants';
import TrashIcon from 'components/Icons/TrashIcon';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import { Button } from 'syngenta-digital-cropwise-react-ui-kit';
import CompanyPeopleDropdown from 'components/CompanyPeopleDropdown';
import PencilEditIcon from 'components/Icons/components/PencilEditIcon';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import { IntegrationsContext } from 'screens/Integrations/context/IntegrationsProvider';
import FumigationField from './components/FumigationField';

import returnApplicationMethodLabel from '../../../../helpers/products';

const CalAgPurForm = ({ propertyDetails, task, isReadOnly }) => {
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const { values, setFieldValue } = useFormikContext();
  const { redirectToTask } = useSubmissionUrlHandler();

  const {
    setOpenRemoveTaskModal,
    state: { integrationType, submissionId }
  } = useContext(SubmissionSummaryContext);
  const {
    state: { selectedProperties }
  } = useContext(IntegrationsContext);

  const handleChangeField = (name, value) => {
    setFieldValue(name, value);
  };

  const handleFumigationCodeChange = value => {
    handleChangeField('fumigationCode', value);
  };

  const handleCompanyPeopleOnChange = value => {
    setFieldValue('pco', value);
    if (value.type === 'People' && value.licenseNumber) {
      setFieldValue('pcoNumber', value.licenseNumber);
    } else {
      setFieldValue('pcoNumber', '');
    }
  };

  const handleEditClick = () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionTaskClick, {
      vendor: integrationType
    });

    const cropSeasonsIds = [
      ...new Set(
        selectedProperties
          .flatMap(p => p.properties)
          .flatMap(a => a.cropSeasons)
          .map(season => season.id)
      )
    ];
    const taskInformation = { taskId: task.taskId, cropSeasonsIds };

    redirectToTask(taskInformation, submissionId, integrationType);
  };

  return (
    <div className="flex flex-col w-full p-4">
      <div className="flex flex-row w-full justify-start">
        <div className="w-1/2 mr-8">
          <div className="text-sm leading-7 font-bold text-neutral-1000 mb-2">
            Applicator Details
          </div>
          {!isReadOnly ? (
            <div className="grid grid-cols-2 gap-x-2">
              <Editor halfWidth>
                <GrowUIFormField
                  label="Applicator"
                  control={Select}
                  id="applicatorType"
                  name="applicatorType"
                  items={APPLICATOR_TYPES}
                />
              </Editor>
              {values?.applicatorType === 'pco' && (
                <div className="grid grid-cols-2 border-t-1 mt-4 gap-x-2 col-span-2">
                  <Editor label="PCO" halfWidth>
                    <CompanyPeopleDropdown
                      id="pco-select"
                      value={values.pco}
                      onChange={value => {
                        handleCompanyPeopleOnChange(value);
                      }}
                      bottomButtonText="Create New PCO"
                      title="Create a PCO"
                    />
                  </Editor>
                  <div className="grid grid-cols-2 col-span-2">
                    <Editor halfWidth>
                      <GrowUIFormField
                        label="Applicator Type"
                        control={Select}
                        id="pcoType"
                        name="pcoType"
                        items={PCO_APPLICATOR_TYPES}
                      />
                    </Editor>
                  </div>
                  <Editor>
                    <GrowUIFormField
                      label="Applicator Number"
                      control={Input}
                      type="text"
                      id="pcoNumber"
                      name="pcoNumber"
                      onChange={e =>
                        handleChangeField('pcoNumber', e.target.value)
                      }
                    />
                  </Editor>
                </div>
              )}
            </div>
          ) : (
            <>
              <PropertyRow
                label="Applicator"
                value={
                  values?.applicator === 1 ? 'Grower Applied' : 'PCO Applied'
                }
              />
              {values?.applicator === 2 && (
                <>
                  <PropertyRow label="PCO" value={values?.applicatorName} />
                  <PropertyRow
                    label="Applicator Type"
                    value={values?.applicatorLicenseType}
                  />
                  <PropertyRow
                    label="Applicator Number"
                    value={values?.applicatorLicenseNumber}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div className="w-1/2 capitalize">
          <div className="text-sm leading-7 font-bold text-neutral-1000 mb-2">
            {`[${propertyDetails.fieldName}] / [${propertyDetails.cropZoneName}]`}
          </div>
          <PropertyRow label="State" value={propertyDetails.state} />
          <PropertyRow label="County" value={propertyDetails.county} />
          <PropertyRow label="Section" value={propertyDetails.section} />
          <PropertyRow
            label="Township"
            value={`${propertyDetails.townshipNumber}\n${propertyDetails.townshipDirection}`}
          />
          <PropertyRow
            label="Range"
            value={`${propertyDetails.rangeNumber}\n${propertyDetails.rangeDirection}`}
          />
          <PropertyRow label="Permit ID" value={propertyDetails.permitId} />
          <PropertyRow label="Site ID" value={propertyDetails.siteId} />
        </div>
      </div>
      <div className="flex flex-row w-full justify-start mt-8">
        <div className="w-1/2 mr-8">
          <div className="text-sm leading-7 font-bold text-neutral-1000 mb-2">
            Application Method
          </div>
          <PropertyRow value={returnApplicationMethodLabel(task.products)} />
        </div>
        {returnApplicationMethodLabel(task.products) === 'Fumigation' && (
          <div className="w-1/2 grid grid-cols-2 gap-x-2">
            <div className="text-sm leading-7 font-bold text-neutral-1000 col-span-2">
              Fumigation Code
            </div>
            {!isReadOnly ? (
              <Editor halfWidth noLabelMarginTop="mt-3">
                <FumigationField
                  id="fumigation-select"
                  value={values.fumigationCode}
                  onChange={value => handleFumigationCodeChange(value)}
                />
              </Editor>
            ) : (
              <PropertyRow value={values?.fumigationCode} />
            )}
            <Editor halfWidth noLabelMarginTop="mt-3">
              <a
                href="https://www.cdpr.ca.gov/docs/emon/vocs/vocproj/ffm_code-table.pdf"
                type="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex flex-row">
                  <div className="block px-4 font-normal text-center w-36 text-green-700">
                    Fumigation Code List
                  </div>
                  <OpenInNewIcon fill="#0C612C" />
                </div>
              </a>
            </Editor>
          </div>
        )}
      </div>
      <div className="w-full mt-8">
        <div>
          <div className="flex flex-row justify-between">
            <div className="text-sm leading-7 font-bold text-neutral-1000 mb-2">
              Applied Products
            </div>
            {!isReadOnly && (
              <div className="w-fit">
                <Button
                  type="outline"
                  className="w-full flex flex-row text-xs"
                  onClick={() => handleEditClick()}
                  data-testid="edit-task-from-product-button"
                >
                  <div className="text-right">
                    <PencilEditIcon />
                  </div>
                  <span className="ml-1">Edit</span>
                </Button>
              </div>
            )}
          </div>
          <div className="mt-2">
            <Table>
              <Table.Header>
                <Table.Cell>Cropwise Product</Table.Cell>
                <Table.Cell>Rate (per area)</Table.Cell>
                <Table.Cell>Total applied</Table.Cell>
              </Table.Header>
              {task.products.map(product => {
                return (
                  <Table.Row key={product.productId}>
                    <Table.Cell>
                      <div className="text-left">
                        <div className="font-bold">{product.productName}</div>
                        <div className="mt-2 text-neutral-300">
                          {product.manufacturerName}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {`${Number(product?.ratePerArea).toFixed(2)} ${
                        product?.ratePerAreaUnit
                      }`}
                    </Table.Cell>
                    <Table.Cell>
                      {`${Number(product?.quantity).toFixed(2)} ${
                        product?.quantityUnit
                      }`}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
          </div>
        </div>
      </div>
      {!isReadOnly && (
        <div className="w-full mt-8 flex justify-end">
          <Button
            type="outline"
            onClick={() => {
              setOpenRemoveTaskModal(true, task);
            }}
            style={{ borderColor: '#c53030' }}
            data-testid="remove-task-button"
          >
            <TrashIcon color="red-700" />
            <span className="text-red-700 ml-2">Remove From Submission</span>
          </Button>
        </div>
      )}
    </div>
  );
};

CalAgPurForm.propTypes = {
  propertyDetails: PropTypes.shape({
    fieldId: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    cropZoneId: PropTypes.string.isRequired,
    cropZoneName: PropTypes.string.isRequired,
    state: PropTypes.string,
    county: PropTypes.string,
    section: PropTypes.string,
    townshipNumber: PropTypes.string,
    townshipDirection: PropTypes.string,
    rangeNumber: PropTypes.string,
    rangeDirection: PropTypes.string,
    geometry: PropTypes.shape({
      type: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired))
      ).isRequired
    }).isRequired,
    permitId: PropTypes.string,
    siteId: PropTypes.string,
    status: PropTypes.string.isRequired
  }).isRequired,
  task: PropTypes.shape({
    name: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productName: PropTypes.string.isRequired,
        productId: PropTypes.string.isRequired
      }).isRequired
    )
  }).isRequired,
  isReadOnly: PropTypes.bool
};

CalAgPurForm.defaultProps = {
  isReadOnly: false
};

export default CalAgPurForm;
