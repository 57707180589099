/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getStringParsed } from 'utilities/helpers';
import PropertyMapWrapper from './PropertyMapWrapper';
import { calculateCentroidByEachProperties } from '../../helpers/mapApiHelpers';
import { LAST_COORDINATES_STORED } from '../helpers/constants';

const PropertyLandingMap = ({
  properties,
  fieldsLoading,
  geoJSONCollection,
  zoom,
  setZoom,
  onSetShowSummaryPanel,
  onMapClick,
  labelName,
  fieldBreadcrumb,
  selectedField,
  setSelectedField
}) => {
  const [manualCoords, setManualCoords] = useState(
    getStringParsed(localStorage.getItem(LAST_COORDINATES_STORED))
  );

  const geoJSONCentroid = useMemo(() => {
    return JSON.stringify(
      calculateCentroidByEachProperties(geoJSONCollection, properties)
    );
  }, [geoJSONCollection, properties]);

  const mapCoordinates = useMemo(
    () => manualCoords || JSON.parse(geoJSONCentroid)?.geometry?.coordinates,
    [geoJSONCentroid, manualCoords]
  );

  useEffect(() => {
    if (selectedField?.length > 0) {
      onSetShowSummaryPanel(true)();
    }
  }, [selectedField, onSetShowSummaryPanel]);

  const onClickShape = useCallback(
    event => {
      let fldId;
      const { $landType, ...featureProperties } = event.features[0].properties;
      if ($landType === 'field') fldId = featureProperties.id;
      else if ($landType === 'cropzone')
        fldId = featureProperties.$parentFieldId;
      if (fldId) {
        setSelectedField(fldId);
        onSetShowSummaryPanel(true)();
        onMapClick(featureProperties);
      }
    },
    [onSetShowSummaryPanel, onMapClick, setSelectedField]
  );

  return (
    <div className="relative w-full h-full">
      {fieldBreadcrumb}
      <PropertyMapWrapper
        fieldsLoading={fieldsLoading}
        zoom={zoom}
        setZoom={setZoom}
        currentCoordinates={mapCoordinates}
        setCurrentCoordinates={setManualCoords}
        displayOnly
        geoJSONCollection={geoJSONCollection}
        onClickShape={onClickShape}
        labelName={labelName}
        selectedField={selectedField}
      />
    </div>
  );
};

PropertyLandingMap.defaultProps = {
  properties: null,
  geoJSONCollection: null,
  onMapClick: null,
  selectedField: '',
  labelName: '',
  onSetShowSummaryPanel: () => () => {}
};

PropertyLandingMap.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  geoJSONCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.object)
  }),
  zoom: PropTypes.arrayOf(PropTypes.number).isRequired,
  setZoom: PropTypes.func.isRequired,
  onSetShowSummaryPanel: PropTypes.func,
  labelName: PropTypes.string,
  fieldsLoading: PropTypes.bool.isRequired,
  onMapClick: PropTypes.func,
  selectedField: PropTypes.string
};

export default PropertyLandingMap;
