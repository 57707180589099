import React, { useEffect, useState } from 'react';
import Toast from 'components/Toast';
import SupportButton from 'components/SupportButton';
import { toastManager } from './toastManager';

/**
 * This component utilizes the `Toast` component to display various
 * types of toast messages (e.g., success, error, warning, info) and, optionally,
 * a `SupportButton` for error toast.
 *
 * It's intended to be included in your application's component tree at a top level,
 * so it can be accessible throughout the application.
 * @component
 * @example
 * return (
 *   <div>
 *     <ToastContainer />
 *     // Your application components here
 *   </div>
 * )
 */
const ToastContainer = () => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    /**
     * subscribes to toast notifications on mount and unsubscribes on unmount,
     * ensuring that it always reflects the current state of active toasts.
     */
    const unsubscribe = toastManager.subscribe(setToasts);
    return () => unsubscribe();
  }, []);

  const handleRemoveToast = id => {
    /* each toast can be dismissed individually,
     * which will remove it from the view and the state manager.
     */
    toastManager.removeToast(id);
  };

  return (
    <>
      {toasts.map(({ id, title, type, content, supportButton, timeout }) => (
        <Toast
          open
          isBold
          key={id}
          type={type}
          title={title}
          timeout={timeout}
          onClose={() => handleRemoveToast(id)}
        >
          {!content && !supportButton ? null : (
            <div className="whitespace-pre-line">
              {content}
              {supportButton && <SupportButton />}
            </div>
          )}
        </Toast>
      ))}
    </>
  );
};

/**
 * Custom hook to use the toast notification system. This hook
 * exposes methods for displaying success, error, warning, and information toasts.
 *
 * Example Usage of `useToast`:
 * ```jsx
 * const toast = useToast();
 *
 * // To show a success message
 * toast.success('Success Title', {
 *   content: 'This is a success message',
 *   timeout: 3000
 * });
 *
 * // To show an error message with a support button
 * toast.error('Error Title', {
 *   content: 'This is an error message',
 *   supportButton: true,
 *   timeout: 3000
 * });
 * ```
 * @returns {{
 *  success: (title: string, options?: {className?: string, content?: string, timeout?: number}) => void,
 *  error: (title: string, options?: {className?: string, content?: string, timeout?: number, supportButton?: boolean }) => void,
 *  warn: (title: string, options?: {className?: string, content?: string, timeout?: number}) => void,
 *  info: (title: string, options?: {className?: string, content?: string, timeout?: number}) => void,
 * }}
 */
export const useToast = () => ({
  success: toastManager.success,
  error: toastManager.error,
  warn: toastManager.warn,
  info: toastManager.info
});

export default ToastContainer;
