/* eslint-disable import/prefer-default-export */
const defaultColumns = [
  {
    field: 'cropSeasonName',
    title: `Crop Season`,
    sortable: true,
    width: '197px'
  },
  {
    field: 'crop',
    title: 'Crop',
    sortable: true,
    width: '197px'
  },
  {
    field: 'variety',
    title: 'Variety',
    sortable: true,
    width: '197px'
  },
  {
    field: 'plantingDate',
    title: 'Date Planted',
    sortable: true,
    width: '197px'
  },
  {
    field: 'area',
    title: 'Area',
    sortable: true,
    width: '197px'
  }
];

export const PROPERTY_CROPHISTORY_TABLE = {
  columns: defaultColumns,
  defaultSort: {
    by: ['plantingDate', 'crop', 'variety'],
    dir: ['asc', 'asc', 'asc']
  }
};
