import MapboxDraw from '@mapbox/mapbox-gl-draw';

const { StringSet, CommonSelectors, doubleClickZoom } = MapboxDraw.lib;

// custom mapbox draw mode modeled after the simple_select mode
const SelectOnlyMode = {};

SelectOnlyMode.onSetup = function onSetup(opts) {
  // turn the opts into state.
  const state = {
    initiallySelectedFeatureIds: opts.featureIds || []
  };

  this.setSelected(
    state.initiallySelectedFeatureIds.filter(
      id => this.getFeature(id) !== undefined
    )
  );
  this.fireActionable();

  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true
  });

  return state;
};

SelectOnlyMode.fireActionable = function fireActionable() {
  const selectedFeatures = this.getSelected();

  const multiFeatures = selectedFeatures.filter(feature =>
    this.isInstanceOf('MultiFeature', feature)
  );

  let combineFeatures = false;

  if (selectedFeatures.length > 1) {
    combineFeatures = true;
    const featureType = selectedFeatures[0].type.replace('Multi', '');
    selectedFeatures.forEach(feature => {
      if (feature.type.replace('Multi', '') !== featureType) {
        combineFeatures = false;
      }
    });
  }

  const uncombineFeatures = multiFeatures.length > 0;
  const trash = selectedFeatures.length > 0;

  this.setActionableState({
    combineFeatures,
    uncombineFeatures,
    trash
  });
};

SelectOnlyMode.getUniqueIds = function getUniqueIds(allFeatures) {
  if (!allFeatures.length) return [];
  const ids = allFeatures
    .map(s => s.properties.id)
    .filter(id => id !== undefined)
    .reduce((memo, id) => {
      memo.add(id);
      return memo;
    }, new StringSet());

  return ids.values();
};

SelectOnlyMode.stopExtendedInteractions = function stopExtendedInteractions() {
  this.map.dragPan.enable();
};

SelectOnlyMode.onStop = function onStop() {
  doubleClickZoom.enable(this);
};

SelectOnlyMode.onMouseMove = function onMouseMove(state) {
  // On mousemove that is not a drag, stop extended interactions.
  // This is useful if you drag off the canvas, release the button,
  // then move the mouse back over the canvas --- we don't allow the
  // interaction to continue then, but we do let it continue if you held
  // the mouse button that whole time
  this.stopExtendedInteractions(state);
  // Skip render
  return true;
};

SelectOnlyMode.onMouseOut = function onMouseOut() {
  // Skip render
  return true;
};

SelectOnlyMode.onMapSelect = function onMapSelect(state, e) {
  // Click (with or without shift) on no feature
  // eslint-disable-next-line react/no-this-in-sfc
  if (CommonSelectors.noTarget(e)) return this.clickAnywhere(state, e); // also tap
  // eslint-disable-next-line react/no-this-in-sfc
  if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e);
  return null;
};

SelectOnlyMode.onTap = function onTap(state, e) {
  return this.onMapSelect(state, e);
};

SelectOnlyMode.onClick = function onClick(state, e) {
  return this.onMapSelect(state, e);
};

SelectOnlyMode.clickAnywhere = function clickAnywhere(state) {
  // Clear the re-render selection
  const wasSelected = this.getSelectedIds();
  if (wasSelected.length) {
    this.clearSelectedFeatures();
    wasSelected.forEach(id => this.doRender(id));
  }
  doubleClickZoom.enable(this);
  this.stopExtendedInteractions(state);
};

SelectOnlyMode.startOnActiveFeature = function startOnActiveFeature(state, e) {
  // Stop any already-underway extended interactions
  this.stopExtendedInteractions(state);

  // Re-render it and enable drag move
  this.doRender(e.featureTarget.properties.id);
};

SelectOnlyMode.clickOnFeature = function clickOnFeature(state, e) {
  // Stop everything
  doubleClickZoom.disable(this);
  this.stopExtendedInteractions(state);

  const isShiftClick = CommonSelectors.isShiftDown(e);
  const selectedFeatureIds = this.getSelectedIds();
  const featureId = e.featureTarget.properties.id;
  const isFeatureSelected = this.isSelected(featureId);

  // Shift-click on a selected feature
  if (isFeatureSelected && isShiftClick) {
    // Deselect it
    this.deselect(featureId);
    if (selectedFeatureIds.length === 1) {
      doubleClickZoom.enable(this);
    }
    // Shift-click on an unselected feature
  } else if (!isFeatureSelected && isShiftClick) {
    // Add it to the selection
    this.select(featureId);
    // Click (without shift) on an unselected feature
  } else if (!isFeatureSelected && !isShiftClick) {
    // Make it the only selected feature
    selectedFeatureIds.forEach(id => this.doRender(id));
    this.setSelected(featureId);
  }

  // No matter what, re-render the clicked feature
  this.doRender(featureId);
};

SelectOnlyMode.onMouseDown = function onMouseDown(state, e) {
  return (
    CommonSelectors.isActiveFeature(e) && this.startOnActiveFeature(state, e)
  );
};

SelectOnlyMode.onTouchStart = function onTouchStart(state, e) {
  return (
    CommonSelectors.isActiveFeature(e) && this.startOnActiveFeature(state, e)
  );
};

SelectOnlyMode.onMouseUp = function onMouseUp(state) {
  this.stopExtendedInteractions(state);
};

SelectOnlyMode.toDisplayFeatures = function toDisplayFeatures(
  state,
  geojson,
  display
) {
  // eslint-disable-next-line no-param-reassign
  geojson.properties.active = this.isSelected(geojson.properties.id)
    ? MapboxDraw.constants.activeStates.ACTIVE
    : MapboxDraw.constants.activeStates.INACTIVE;
  display(geojson);
  this.fireActionable();
};

export default SelectOnlyMode;
