import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import ClickableDiv from 'components/ClickableDiv';
import AddIcon from '@material-ui/icons/Add';
import ProductCard from '../ProductCard';

const ProductsAndServices = ({
  addProduct,
  toggleCreateCustomProductModal,
  products,
  setPageNo,
  isLoading,
  hasNextPage
}) => {
  const intObserver = useRef();
  const lastPageRef = useCallback(
    product => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(prods => {
        if (prods[0].isIntersecting && hasNextPage) {
          setPageNo(prev => prev + 1);
        }
      });
      if (product) intObserver.current.observe(product);
    },
    [isLoading, hasNextPage, setPageNo]
  );

  const content = products.map((product, index) => {
    if (products.length === index + 1) {
      return (
        <ProductCard
          addProduct={() => addProduct(product)}
          product={product}
          isFirst={index === 0}
          ref={lastPageRef}
          key={product?.id}
        />
      );
    }
    return (
      <ProductCard
        addProduct={() => addProduct(product)}
        product={product}
        isFirst={index === 0}
        key={product?.id}
      />
    );
  });

  return (
    <div className="mt-6">
      <div className="flex items-center justify-between">
        <div className="text-sm font-bold uppercase">PRODUCTS & SERVICES</div>
      </div>
      <div className="mt-6">
        <div data-testid="create-custom-product-button">
          <ClickableDiv
            className="w-full text-center text-md pt-2 pb-2 border rounded border-green-600 text-green-600"
            onClick={toggleCreateCustomProductModal}
          >
            <AddIcon style={{ fontSize: 16 }} /> <span>Create Custom</span>
          </ClickableDiv>
        </div>
      </div>
      {isLoading ? (
        <div className="my-3">
          <Spinner />
        </div>
      ) : (
        content
      )}
    </div>
  );
};

ProductsAndServices.propTypes = {
  addProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleCreateCustomProductModal: PropTypes.func.isRequired,
  setPageNo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired
};

export default ProductsAndServices;
