import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  DatePicker,
  TagCloud,
  Tag,
  TextArea,
  Form,
  Spinner,
  Select
} from '@agconnections/grow-ui';
import { set } from 'lodash';
import { generate } from 'shortid';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import ChecklistProperty from './GroupByFilterPropertyCropSeason';
import ChecklistCrop from './GroupByFilterCrop';
import { getCropzonesCropArea } from '../../helpers/propertyHelpers';

const FormSection = ({
  properties,
  propertiesLoading,
  cropItems,
  setFieldValue,
  values,
  setTotalArea,
  setGeoJSONCollectionFeatures,
  fieldsAndAreasGeoJSONCollection
}) => {
  const [crops, setCrops] = useState([]);
  const [cropsLoading, setCropsLoading] = useState(false);
  const [cropZonesChecked, setCropZonesChecked] = useState([]);

  const selectedCropzoneData = [];
  /* eslint no-unused-expressions: "off", curly: "error" */
  properties?.forEach(prop => {
    if (prop?.fields?.length > 0) {
      selectedCropzoneData.push({
        ...prop,
        selectedCropSeason: null
      });
    } else {
      selectedCropzoneData.push({
        ...prop,
        selectedCropSeason: false
      });
    }
  });

  let temp = 0;
  const getCropZonesData = cropZones => {
    setCropsLoading(true);
    getCropzonesCropArea(cropZones).then(results => {
      setCropsLoading(false);
      setCrops([
        ...new Set(
          results.map(({ cropId }) =>
            cropItems.find(item => item.key === cropId)
          )
        )
      ]);
      setTotalArea(
        results.reduce((acc, { reportedArea, boundaryArea }) => {
          if (reportedArea > 0) {
            temp = acc + reportedArea;
          } else {
            temp = acc + boundaryArea;
          }
          return temp;
        }, 0)
      );
    });
  };

  const filterFeatureCollectionByCropZones = (fc, ids) => {
    return fc.features
      ? fc.features.map(feat => {
          set(
            feat,
            'properties.$layer',
            ids?.includes(feat.properties?.id) ||
              (ids?.includes(feat.properties?.id) &&
                !feat.properties?.$parentDuplicated)
              ? 'focused'
              : 'default'
          );
          return feat;
        })
      : [];
  };

  const updateZoom = featureLayer =>
    setGeoJSONCollectionFeatures(
      fieldsAndAreasGeoJSONCollection.features
        ? fieldsAndAreasGeoJSONCollection.features.map(feat => {
            set(feat, 'properties.$layer', featureLayer);
            return feat;
          })
        : []
    );

  const updateSelectetdProperties = (cropZoneIds, farmIds) =>
    properties
      .filter(({ id }) => farmIds.includes(id))
      .map(property => {
        const propertyCropZones = property.fields.flatMap(
          ({ cropzones }) => cropzones
        );
        return {
          farmId: property.id,
          cropzoneIds: propertyCropZones
            .filter(({ id }) => cropZoneIds.includes(id))
            .map(({ id }) => id)
        };
      });

  const handlePropertySelect = (cropZoneIds, farmIds) => {
    setGeoJSONCollectionFeatures(
      filterFeatureCollectionByCropZones(
        fieldsAndAreasGeoJSONCollection,
        cropZoneIds
      )
    );
    const selectedProperties = updateSelectetdProperties(cropZoneIds, farmIds);
    getCropZonesData(cropZoneIds);
    setFieldValue('properties', selectedProperties);

    // Zooming out map in case the properties checklist is completely unchecked.
    if (
      !propertiesLoading &&
      properties.length !== 0 &&
      selectedProperties.length === 0
    ) {
      updateZoom('focused'); // setting as 'focused' to zoom out first.
      updateZoom('default'); // then setting as 'default' to update CSS.
    }
  };

  useEffect(() => {
    if (values.cropZones) {
      handlePropertySelect(values.cropZones, values.farms);
      setCropZonesChecked(values?.cropZones);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesLoading, setCropZonesChecked]);

  const handleChange = e => {
    const dropDownValue = JSON.parse(e.target.value);
    if (values?.propertiesGroupBy === 'property') {
      setFieldValue('propertiesGroupBy', dropDownValue.key);
    } else {
      setFieldValue('propertiesGroupBy', dropDownValue.key);
    }
  };

  return (
    <div
      data-testid="cropseason-sidebar"
      className="flex-col items-center justify-center flex-none p-6 bg-white"
      style={{ width: '500px' }}
    >
      <Form.Field label="Crop Season Name*">
        <GrowUIFormField
          control={Input}
          name="name"
          data-testid="cropSeasonName"
        />
      </Form.Field>
      <Form.Field label="Crop Season Dates*">
        <GrowUIFormField
          control={DatePicker}
          name="date"
          isRangePicker
          data-testid="cropSeasonDate"
        />
      </Form.Field>
      <div>
        <Form.Field label="Crop(s)" />
        <div style={{ minHeight: '4rem' }}>
          {cropsLoading ? (
            <Spinner />
          ) : (
            <TagCloud data-testid="crops">
              {crops?.map(crop => (
                <Tag key={crop?.key ?? generate()}>{crop?.value}</Tag>
              ))}
            </TagCloud>
          )}
        </div>
      </div>
      <div className="mb-3">
        <Form.Field label="Properties" />
        {!propertiesLoading ? (
          <>
            <GrowUIFormField
              control={Select}
              name="propertiesGroupBy"
              items={[
                { key: 'property', value: 'Group By Property' },
                { key: 'crop', value: 'Group By Crop' }
              ]}
              value={values?.propertiesGroupBy ?? ''}
              onChange={e => {
                handleChange(e);
              }}
            />

            {values?.propertiesGroupBy === 'property' ? (
              <ChecklistProperty
                data={selectedCropzoneData}
                onChange={handlePropertySelect}
                value={values.cropZones}
                cropZonesChecked={cropZonesChecked}
                setCropZonesChecked={setCropZonesChecked}
                disabled={cropsLoading}
              />
            ) : (
              <ChecklistCrop
                data={selectedCropzoneData}
                onChange={handlePropertySelect}
                value={values.cropZones}
                cropZonesChecked={cropZonesChecked}
                setCropZonesChecked={setCropZonesChecked}
                disabled={cropsLoading}
              />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <Form.Field label="Notes">
        <TextArea
          name="values.notes"
          id="notes"
          onChange={e => setFieldValue('notes', e.target.value)}
          value={values.notes}
        />
      </Form.Field>
    </div>
  );
};

FormSection.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  propertiesLoading: PropTypes.bool,
  cropItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setTotalArea: PropTypes.func.isRequired,
  setGeoJSONCollectionFeatures: PropTypes.func.isRequired,
  fieldsAndAreasGeoJSONCollection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired
};

FormSection.defaultProps = {
  properties: [],
  propertiesLoading: true
};
export default FormSection;
