const formatIsoDateString = isoDateString =>
  new Date(`${isoDateString?.split('T')?.[0]}:00:00`);

const sortDates = selectedCS => {
  if (!selectedCS?.length) {
    return {};
  }

  const listOfDates = selectedCS?.map(cropseaons => ({
    startDate: formatIsoDateString(cropseaons?.startDate),
    endDate: formatIsoDateString(cropseaons?.endDate)
  }));
  const listOfStartDates = listOfDates.sort(
    (date1, date2) => date1.startDate - date2.startDate
  );
  const listOfEndDates = listOfDates.sort(
    (date1, date2) => date1.endDate - date2.endDate
  );
  const [{ startDate }] = listOfStartDates;
  const [{ endDate }] = listOfEndDates.slice(-1);
  return { startDate, endDate };
};

export default sortDates;
