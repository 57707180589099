/**
 * Creates and returns a toast manager instance that can be used to manage toast notifications
 * within an application. The toast manager maintains a list of toasts and listeners,
 * allowing toasts to be added, removed, and to notify components about the current state
 * of toasts so they can be rendered appropriately.
 */
const createToastManager = () => {
  let toasts = [];
  let listeners = [];

  const subscribe = listener => {
    listeners.push(listener);
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  };

  const notify = () => {
    listeners.forEach(listener => listener(toasts));
  };

  const toastExists = ({ title, type }) => {
    return toasts.some(toast => toast.title === title && toast.type === type);
  };

  const addToast = ({
    title,
    type = 'info',
    content = '',
    timeout = 5000,
    supportButton = false
  }) => {
    if (toastExists({ title, type })) return;
    const id = Date.now();
    toasts = [...toasts, { id, title, type, content, timeout, supportButton }];
    notify();
    // eslint-disable-next-line consistent-return
    return id;
  };

  const removeToast = id => {
    toasts = toasts.filter(toast => toast.id !== id);
    notify();
  };

  return {
    subscribe,
    addToast,
    removeToast,
    success: (title, options) =>
      addToast({ title, ...options, type: 'success' }),
    error: (title, options) => addToast({ title, ...options, type: 'error' }),
    warn: (title, options) => addToast({ title, ...options, type: 'warning' }),
    info: (title, options) => addToast({ title, ...options, type: 'info' })
  };
};

// eslint-disable-next-line import/prefer-default-export
export const toastManager = createToastManager();
