import { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { Context } from '../components/Store';

const usePropertyFilters = () => {
  const [{ propertyFilters }, dispatch] = useContext(Context);
  const { showArchivedProperties, showFarmWithIds } = propertyFilters || {};
  const [properties, setProperties] = useState([]);

  const filterOutArchivedProperties = propertyList => {
    return (
      propertyList
        ?.filter(property => !property.archived)
        .map(property => ({
          ...property,
          fields: property.fields?.filter(field => !field.archived) || []
        })) || []
    );
  };

  const sortAndIncludeArchivedProperties = propertyList => {
    if (!propertyList) return [];
    return propertyList
      .sort((propertyA, propertyB) => propertyA.archived - propertyB.archived)
      .map(property =>
        property.fields
          ? {
              ...property,
              fields: property.fields.sort(
                (fieldA, fieldB) => fieldA.archived - fieldB.archived
              )
            }
          : property
      );
  };

  const propertiesFilter = useMemo(() => {
    let propertyList;
    if (showArchivedProperties) {
      propertyList = sortAndIncludeArchivedProperties(properties);
    } else {
      propertyList = filterOutArchivedProperties(properties);
    }

    if (showFarmWithIds?.length > 0) {
      propertyList = propertyList.filter(property =>
        showFarmWithIds?.includes(property.id)
      );
    }
    return propertyList;
  }, [showArchivedProperties, properties, showFarmWithIds]);

  const setShowArchivedProperties = enable => {
    dispatch({
      type: 'SET_PROPERTY_FILTERS',
      payload: {
        showArchivedProperties: enable
      }
    });
  };

  const setFarmsFilter = ids => {
    dispatch({
      type: 'SET_PROPERTY_FILTERS',
      payload: {
        showFarmWithIds: ids
      }
    });
  };

  const runMapFilter = useCallback(
    list => {
      if (showArchivedProperties) {
        return list;
      }

      return list.features
        ? {
            ...list,
            features: list.features.filter(f => !f.properties?.archived)
          }
        : [];
    },
    [showArchivedProperties]
  );

  const hasActiveFilters =
    showArchivedProperties ||
    properties?.length > 0 ||
    showFarmWithIds?.length > 0;

  const hasFilterChanges = ({ selectedFarmIds, showLocalArchivedProperties }) =>
    !_.isEqual(_.sortBy(selectedFarmIds), _.sortBy(showFarmWithIds)) ||
    showLocalArchivedProperties !== showArchivedProperties;

  const applyFilters = ({ selectedFarmIds, showLocalArchivedProperties }) => {
    setFarmsFilter(selectedFarmIds);
    setShowArchivedProperties(showLocalArchivedProperties);
  };

  return {
    filteredProperties: propertiesFilter || [],
    showArchivedProperties,
    setShowArchivedProperties,
    setPropertiesToFilter: setProperties,
    hasActiveFilters,
    hasFilterChanges,
    runMapFilter,
    applyFilters
  };
};

export default usePropertyFilters;
