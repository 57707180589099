import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer';

import LightItalic from '../fonts/Noto_Sans/NotoSans-LightItalic.ttf';
import RegularFont from '../fonts/Noto_Sans/NotoSans-Regular.ttf';
import MediumFont from '../fonts/Noto_Sans/NotoSans-Medium.ttf';
import SemiBoldFont from '../fonts/Noto_Sans/NotoSans-SemiBold.ttf';
import { Footer, Header } from '../components';

Font.register({
  family: 'Noto Sans',
  fonts: [
    { src: LightItalic, fontStyle: 'italic', fontWeight: 'light' },
    { src: RegularFont },
    { src: MediumFont },
    { src: SemiBoldFont }
  ]
});

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 20,
    paddingBottom: 85
  },
  overallView: {
    paddingHorizontal: 10
  },
  viewContainer: {
    marginBottom: 27
  }
});

const Layout = ({
  children,
  headerTitle,
  orgName,
  cropName,
  cropSeasons,
  generatedYear,
  generatedFullDate,
  status,
  type,
  exportLanguage
}) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Header
          type={type}
          title={headerTitle}
          organization={orgName}
          cropName={cropName}
          cropSeasons={cropSeasons}
          status={status}
          exportLanguage={exportLanguage}
        />
        <View style={styles.overallView}>{children}</View>
        <Footer
          exportLanguage={exportLanguage}
          year={generatedYear}
          fullDate={generatedFullDate}
        />
      </Page>
    </Document>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  cropName: PropTypes.string,
  cropSeasons: PropTypes.arrayOf(PropTypes.string),
  generatedYear: PropTypes.string.isRequired,
  generatedFullDate: PropTypes.string.isRequired,
  status: PropTypes.string,
  type: PropTypes.string.isRequired,
  exportLanguage: PropTypes.string
};

Layout.defaultProps = {
  cropName: null,
  cropSeasons: [],
  status: null,
  exportLanguage: PropTypes.string
};

export default Layout;
