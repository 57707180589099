import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import AgrianAuthenticationPage from 'screens/Integrations/AgrianIntegration/components/AgrianAuthenticationPage';
import AgrianOrganization from 'screens/Integrations/AgrianIntegration/components/AgrianOrganization';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { AgrianContext } from '../../../context/Provider';

export const PROPERTIES_SELECTION_STEP = 4; // Used to not allow the user move forward the step by progress bar

const STEPS = {
  1: (
    <AgrianAuthenticationPage
      buttonText="Start New Import"
      integrationType={INTEGRATIONS.agrianRecommendation}
    />
  ),
  2: <AgrianOrganization integrationType={INTEGRATIONS.agrianRecommendation} />,
  3: <AgrianOrganization integrationType={INTEGRATIONS.agrianRecommendation} />,
  4: <AgrianOrganization integrationType={INTEGRATIONS.agrianRecommendation} />,
  5: <AgrianOrganization integrationType={INTEGRATIONS.agrianRecommendation} />
};

const StepSelector = ({ fixedStep }) => {
  const {
    state: { step }
  } = useContext(AgrianContext);

  return STEPS[fixedStep ?? step];
};

StepSelector.propTypes = {
  fixedStep: PropTypes.number
};

export default StepSelector;
