import React, { useEffect, useState } from 'react';
import { Popup, List } from '@agconnections/grow-ui';
import useZendesk from 'hooks/useZendesk';
import NotificationCounter from 'components/NotificationCounter';

const openInNewTab = location => window.open(location, '_blank');

const HelpButton = () => {
  const { openChatWidget, getUnreadChatMessages } = useZendesk();

  const [menuOpened] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const unreadMessagesCallback = count => {
    setUnreadMessages(count);
  };

  useEffect(() => {
    getUnreadChatMessages(unreadMessagesCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.zE]);

  const Trigger = (
    <div className="relative">
      <NotificationCounter notifications={unreadMessages} />
      <button
        data-testid="help-button"
        type="button"
        className="flex justify-center items-center p-2 text-gray-100 rounded-full bg-gray-400 hover:bg-gray-500 hover:text-gray-200 focus:outline-none focus:shadow-outline focus:text-gray-500"
      >
        <svg fill="currentColor" viewBox="0 0 9 14" className="w-4 h-4">
          <path d="M2.94512 11.0506V14H5.81707V11.0506H2.94512ZM0 4.73051H2.68902C2.68902 4.38577 2.72561 4.06657 2.79878 3.77291C2.87195 3.46648 2.98171 3.20474 3.12805 2.98769C3.28659 2.75786 3.48171 2.57911 3.71341 2.45144C3.95732 2.31099 4.2439 2.24077 4.57317 2.24077C5.06098 2.24077 5.43902 2.38121 5.70732 2.66211C5.9878 2.943 6.12805 3.37711 6.12805 3.96443C6.14024 4.30917 6.07927 4.59644 5.94512 4.82626C5.82317 5.05609 5.65854 5.26676 5.45122 5.45828C5.2439 5.6498 5.01829 5.84131 4.77439 6.03283C4.53049 6.22435 4.29878 6.45417 4.07927 6.7223C3.85976 6.97766 3.66463 7.29047 3.4939 7.66074C3.33537 8.03101 3.2378 8.49065 3.20122 9.03967V9.9015H5.67073V9.17373C5.71951 8.7907 5.83537 8.4715 6.01829 8.21614C6.21341 7.96078 6.43293 7.73735 6.67683 7.54583C6.92073 7.34154 7.17683 7.14364 7.44512 6.95212C7.72561 6.74783 7.97561 6.50524 8.19512 6.22435C8.42683 5.94346 8.61585 5.60511 8.76219 5.2093C8.92073 4.8135 9 4.30917 9 3.69631C9 3.32604 8.92073 2.93023 8.76219 2.50889C8.61585 2.07478 8.36585 1.67259 8.01219 1.30232C7.65854 0.932056 7.18902 0.625627 6.60366 0.383037C6.03049 0.127679 5.31098 0 4.44512 0C3.77439 0 3.16463 0.121295 2.61585 0.363885C2.07927 0.593707 1.61585 0.919289 1.22561 1.34063C0.847561 1.76197 0.54878 2.25992 0.329268 2.83447C0.121951 3.40903 0.0121951 4.04104 0 4.73051Z" />
        </svg>
      </button>
    </div>
  );

  return (
    <Popup trigger={Trigger} position="bottom" align="right" open={menuOpened}>
      <div
        data-testid="help-popup"
        className="text-left -mx-2 whitespace-no-wrap"
      >
        <List>
          <List.Item
            id="support"
            selectable
            selectAction={() =>
              openInNewTab('https://www.syngentadigital.com/us')
            }
          >
            Support
          </List.Item>
          <List.Item
            id="training"
            selectable
            selectAction={() =>
              openInNewTab('https://agconnections.zendesk.com/hc/en-us')
            }
          >
            Help Center
          </List.Item>
          <List.Item
            id="productRequest"
            selectable
            selectAction={() =>
              openInNewTab(
                'https://www.syngentadigital.com/us/product-request-form'
              )
            }
          >
            <span>Product Request</span>
          </List.Item>
          <List.Item
            id="eula"
            selectable
            selectAction={() => openInNewTab('https://login.landdb.com/eula')}
          >
            EULA
          </List.Item>
          <List.Item id="chat" selectable selectAction={openChatWidget}>
            <p className="relative">
              Chat{' '}
              <NotificationCounter
                notifications={unreadMessages}
                style={{ left: '90%', top: '1px' }}
              />
            </p>
          </List.Item>
        </List>
      </div>
    </Popup>
  );
};

export default HelpButton;
