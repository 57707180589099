import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { paths } from 'routes/paths';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Spinner } from '@agconnections/grow-ui';
import {
  INTEGRATIONS,
  SubmissionStatus
} from 'screens/Integrations/helpers/constants';
import { SubmissionSummaryContext } from 'screens/Integrations/SubmissionSummary/context/SubmissionSummaryProvider';
import useSubmissionUrlHandler from 'screens/Integrations/hooks/useSubmissionUrlHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import StatusMessage from '../StatusMessage';

const SubmitHeader = ({
  subtitle,
  integrationType,
  submissionResult,
  RightComponent
}) => {
  const { status, response } = submissionResult || {};
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isRetryable, setIsRetryable] = useState(false);
  const history = useHistory();
  const {
    state: { isReadOnly, submissionId },
    setOpenConfirmationModal,
    resubmitIntegration
  } = useContext(SubmissionSummaryContext);

  const { redirectToEdit } = useSubmissionUrlHandler();

  useEffect(() => {
    if (integrationType === INTEGRATIONS.calAgPermits) {
      setIsEditable(false);
      setIsRetryable(false);
      return;
    }

    switch (status) {
      case SubmissionStatus.ERROR:
        if (response) {
          setIsEditable(true);
          setIsRetryable(false);
          break;
        }
        setIsEditable(true);
        setIsRetryable(true);
        break;
      case SubmissionStatus.DONE:
      case SubmissionStatus.PENDING:
        setIsEditable(true);
        setIsRetryable(false);
        break;
      case SubmissionStatus.IN_PROGRESS:
      default:
        setIsEditable(false);
        setIsRetryable(false);
    }
  }, [status, response, integrationType]);

  const handleRedirectToHistoryPage = () => {
    return history.push(
      `${paths.integrations}/${integrationType}/landing-page`
    );
  };

  const handleEditPage = async () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionEditClick, {
      vendor: integrationType,
      status
    });
    if (status === SubmissionStatus.PENDING) {
      return redirectToEdit(submissionId, integrationType);
    }
    setLoading(true);
    const resubmitResponse = await resubmitIntegration();
    setLoading(false);
    if (resubmitResponse) {
      const {
        data: { id }
      } = resubmitResponse;
      return redirectToEdit(id, integrationType);
    }
    return null;
  };

  const handleRetryPage = () => {
    triggerAnalyticsEvent(events.epic.Integrations.submissionRetryClick, {
      vendor: integrationType
    });
    setOpenConfirmationModal(true);
  };
  return (
    <>
      {isReadOnly ? (
        <div className="border-b-1 mb-2">
          <div className="w-full flex h-10 mb-4 items-center font-semibold justify-between">
            <div className="flex flex-row">
              <Button
                data-testid="back-button-header"
                circular
                style={{
                  borderRadius: 999,
                  marginRight: '0.5rem',
                  height: '2.5rem'
                }}
                id="return-history-button"
                type="outline"
                onClick={handleRedirectToHistoryPage}
              >
                <Icon icon="chevron-left" color="#718096" />
              </Button>
              <p className="text-2xl ml-4">View Submission</p>
            </div>
            <div className="flex flex-row w-2/5 justify-end">
              {isRetryable && (
                <Button
                  data-testid="retry-button-header"
                  id="redirect-retry-button"
                  type="outline"
                  style={{
                    height: '2.5rem',
                    marginRight: '0.5rem',
                    flex: 1,
                    maxWidth: '250px'
                  }}
                  onClick={handleRetryPage}
                >
                  Retry Submission
                </Button>
              )}

              {isEditable && (
                <Button
                  data-testid="edit-button-header"
                  id="redirect-retry-button"
                  type="primary"
                  style={{
                    height: '2.5rem',
                    flex: 1,
                    maxWidth: '250px'
                  }}
                  onClick={handleEditPage}
                >
                  {loading ? (
                    <Spinner color="#FFF" size="sm" />
                  ) : (
                    'Edit and Resubmit'
                  )}
                </Button>
              )}
            </div>
          </div>
          <StatusMessage submissionResult={submissionResult} />
        </div>
      ) : (
        <div className="mb-4 flex flex-row justify-between border-b-1">
          <div className="flex flex-col">
            <div className="text-xl leading-7 font-semibold text-neutral-1000 mb-2">
              Review and Submit
            </div>
            <p className="text-sm leading-6 text-neutral-70 font-normal pb-4">
              {subtitle}
            </p>
          </div>
          {RightComponent && <RightComponent />}
        </div>
      )}
    </>
  );
};

SubmitHeader.propTypes = {
  subtitle: PropTypes.string,
  integrationType: PropTypes.string,
  submissionResult: PropTypes.shape({
    status: PropTypes.string,
    errorMessage: PropTypes.string,
    response: PropTypes.objectOf(PropTypes.object)
  }),
  RightComponent: PropTypes.oneOfType([
    PropTypes.elementType, // For React components
    PropTypes.func // For functions that return JSX
  ])
};

SubmitHeader.defaultProps = {
  subtitle: 'Review selected data before submitting',
  RightComponent: undefined,
  integrationType: '',
  submissionResult: {}
};

export default SubmitHeader;
