import React, { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'syngenta-digital-cropwise-react-ui-kit';
import { Context } from 'components/Store';
import { hasRoleAccess, ROLES } from 'utilities/access';
import { findGeoJSON } from 'screens/Property/helpers/propertyDataHelpers';
import { PropertyTypes } from 'helpers/propertyHelpers';
import {
  EXPAND_PROPERTIES,
  SELECTED_FIELD_ROW_ID,
  SELECT_PROPERTY
} from 'reducers/reducer';
import createAction from 'helpers/createAction';
import FarmRow from '../../../FarmRow';
import FieldRow from '../../../FieldRow';
import CropZoneRow from '../../../CropZoneRow';
import UnarchivePropertyModal from '../../../../UnarchivePropertyModal';
import './index.css';

const DefaultTree = ({
  properties,
  fieldsAndAreasGeoJSONCollection,
  onFarmSelect,
  onFieldSelect,
  onCropZoneSelect,
  propertiesFilter,
  loadFarmCosts,
  reloadData
}) => {
  const [
    {
      selectedProperty,
      expandedPropertyIds,
      loggedInUserOrgPermission,
      searchTextValueFFT
    },
    dispatch
  ] = useContext(Context);
  const { role } = loggedInUserOrgPermission;
  const [unarchivePropertyModalOpen, setUnarchivePropertyModalOpen] = useState(
    false
  );
  const closeUnarchivePropertyModal = () =>
    setUnarchivePropertyModalOpen(false);
  const [unarchivedProperty, setUnarchivedProperty] = useState({});
  const [unarchivedPropertyType, setUnarchivedPropertyType] = useState(
    PropertyTypes.FARM
  );
  const [fieldOpen, setFieldOpen] = useState();
  const isRowSelected = row => selectedProperty?.id === row.id;

  const hasFullControl = useMemo(
    () => hasRoleAccess(role, ROLES.FULL_CONTROL),
    [role]
  );

  const selectProperty = (farm, type) => {
    createAction(dispatch, SELECT_PROPERTY, {
      ...farm,
      type
    });
  };

  const expandOrCollapse = (isExpanded, key) => {
    const i = expandedPropertyIds.findIndex(_key => _key === key);
    if (isExpanded && i === -1) {
      // If row gets selected, expand it
      createAction(dispatch, EXPAND_PROPERTIES, [...expandedPropertyIds, key]);
    } else if (!isExpanded && i !== -1) {
      // If row gets de-selected, collapse it
      const newExpandedKeys = expandedPropertyIds.slice();
      newExpandedKeys.splice(i, 1);
      createAction(dispatch, EXPAND_PROPERTIES, newExpandedKeys);
    }
  };

  const onSelect = (selectedKeys, info) => {
    const selectedKey = selectedKeys[0] ?? info.node.key;
    expandOrCollapse(!!selectedKeys[0], selectedKey);
  };

  const onExpand = (_, { expanded, node }) => {
    expandOrCollapse(expanded, node.key);
  };

  const onUnarchiveProperty = property => {
    setUnarchivedProperty(property);
    setUnarchivedPropertyType(
      property.fields ? PropertyTypes.FARM : PropertyTypes.FIELD
    );
    setUnarchivePropertyModalOpen(true);
  };

  const onFarmRowSelect = farm => {
    if (selectedProperty.id === farm.id) {
      selectProperty();
      return;
    }
    loadFarmCosts(farm.id);
    selectProperty(farm, 'Farm');
    setFieldOpen(null);
    onFarmSelect(farm);
  };

  const onFieldRowSelect = field => {
    if (selectedProperty.id === field.id) {
      selectProperty();
      return;
    }
    selectProperty(field, 'Field');
    setFieldOpen(field);
    onFieldSelect(field);
  };

  const onCropZoneRowSelect = cropZone => {
    if (cropZone.archived) {
      return;
    }
    if (selectedProperty.id === cropZone.id) {
      selectProperty();
      return;
    }
    selectProperty(cropZone, 'Crop Zone');
    onCropZoneSelect(cropZone);
  };

  useEffect(() => {
    if (fieldOpen) {
      createAction(dispatch, SELECTED_FIELD_ROW_ID, { id: fieldOpen?.id });
    }
  }, [fieldOpen, dispatch]);

  const treeData = propertiesFilter(properties, searchTextValueFFT).map(
    farm => ({
      title: (
        <FarmRow
          farm={farm}
          onSelect={onFarmRowSelect}
          isSelected={isRowSelected(farm)}
          hasEditAccess={!farm.archived && hasFullControl}
          showUnarchiveButton={farm.archived && hasFullControl}
          onUnarchive={() => onUnarchiveProperty(farm)}
        />
      ),
      key: farm.id,
      children: farm.fields.map(field => {
        const fieldGeoJSON = findGeoJSON(
          fieldsAndAreasGeoJSONCollection,
          field
        );
        const fieldWithFarmId = { ...field, farmId: farm.id };

        return {
          title: (
            <FieldRow
              field={fieldWithFarmId}
              onSelect={onFieldRowSelect}
              isSelected={isRowSelected(field)}
              icon={fieldGeoJSON}
              hasEditAccess={!field.archived && hasFullControl}
              showUnarchiveButton={field.archived && hasFullControl}
              onUnarchive={() => onUnarchiveProperty(fieldWithFarmId)}
            />
          ),
          key: field.id,
          children: field.cropzones.map(cropZone => {
            const cropZoneGeoJSON = findGeoJSON(
              fieldsAndAreasGeoJSONCollection,
              cropZone
            );
            const cropZoneWithFieldIdAndFarmId = {
              ...cropZone,
              fieldId: field.id,
              farmId: farm.id,
              archived: field.archived
            };

            return {
              title: (
                <CropZoneRow
                  onSelect={onCropZoneRowSelect}
                  isSelected={isRowSelected(cropZone)}
                  cropZone={cropZoneWithFieldIdAndFarmId}
                  icon={cropZoneGeoJSON}
                  fieldId={field.id}
                  isEditable={hasFullControl && !field.archived}
                />
              ),
              key: cropZone.id
            };
          })
        };
      })
    })
  );

  return (
    <div data-tree className="pb-4 default-fft h-full overflow-auto">
      <Tree
        selectedKeys={selectedProperty?.id ? [selectedProperty?.id] : []}
        treeData={treeData}
        onSelect={onSelect}
        onExpand={onExpand}
        expandedKeys={expandedPropertyIds}
      />
      {unarchivedProperty.id && (
        <UnarchivePropertyModal
          open={unarchivePropertyModalOpen}
          propertyId={unarchivedProperty.id}
          propertyName={unarchivedProperty.name}
          propertyType={unarchivedPropertyType}
          reloadData={reloadData}
          close={closeUnarchivePropertyModal}
        />
      )}
    </div>
  );
};

DefaultTree.defaultProps = {
  properties: [],
  fieldsAndAreasGeoJSONCollection: {},
  loadFarmCosts: () => {},
  reloadData: () => {}
};

DefaultTree.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf(PropTypes.any),
  onFarmSelect: PropTypes.func.isRequired,
  onFieldSelect: PropTypes.func.isRequired,
  onCropZoneSelect: PropTypes.func.isRequired,
  propertiesFilter: PropTypes.func.isRequired,
  loadFarmCosts: PropTypes.func,
  reloadData: PropTypes.func
};

export default DefaultTree;
