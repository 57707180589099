/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Context } from 'components/Store';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { set } from 'lodash';
import { FETCH_TYPES } from 'helpers/constants';
import usePopulateMapData from 'hooks/usePopulateMapData';
import useFieldData from '../../hooks/useFieldData';

const filterFeatureCollectionByParentId = (fc, parentId) =>
  fc?.features
    ? fc.features.map(feat => {
        set(
          feat,
          'properties.$layer',
          parentId === feat.properties?.id ||
            (parentId === feat.properties?.propertyId &&
              !feat.properties?.$parentDuplicated)
            ? 'focused'
            : 'default'
        );
        return feat;
      })
    : [];

const PropertyBreadcrumb = ({
  properties,
  propertyId,
  fieldId,
  cropZoneId,
  mapFieldId,
  title,
  onPropertySelect,
  onFieldSelect,
  onCropZoneSelect,
  onOrganizationSelect,
  reloadDataParent,
  onSetShowSummaryPanel,
  disabledOrgChange,
  disabledPropertyChange
}) => {
  const passedFieldData = useFieldData(mapFieldId);
  const [context] = useContext(Context);
  const { shouldResetPropertyName, loadingProperties } = context;
  const { setGeoJSONCollectionFeatures, reloadData } = usePopulateMapData({
    fetchType: FETCH_TYPES.propertiesMap
  });
  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currentFarm, setCurrentFarm] = useState(
    passedFieldData?.field?.propertyId
  );
  const [currentField, setCurrentField] = useState(mapFieldId);
  useEffect(() => {
    if (mapFieldId && currentField?.id !== mapFieldId) {
      setCurrentField(mapFieldId);
    }
  }, [currentField, mapFieldId]);
  const [currentCropZone, setCurrentCropZone] = useState(null);
  const history = useHistory();
  const { location } = useHistory();
  const getFarms = useCallback(() => {
    if (!properties) {
      return [];
    }
    return properties.sort((f1, f2) => (f1.name < f2.name ? -1 : 1));
  }, [properties]);

  useEffect(() => {
    if (shouldResetPropertyName) {
      reloadDataParent();
    }
  }, [shouldResetPropertyName, reloadDataParent]);

  const getFields = useCallback(
    farm =>
      farm?.fields ??
      (properties || []).flatMap(({ fields, id }) =>
        fields.map(field => ({ ...field, propertyId: id }))
      ),
    [properties]
  );

  const getCropZones = useCallback(
    field =>
      field?.cropzones ??
      (properties || []).flatMap(({ fields, id: parentPropertyId }) =>
        (fields || []).flatMap(({ cropzones, id: parentFieldId }) =>
          cropzones.map(cz => ({
            ...cz,
            fieldId: parentFieldId,
            propertyId: parentPropertyId
          }))
        )
      ),
    [properties]
  );

  useEffect(() => {
    if (title) {
      setCurrentFarm(null);
      setCurrentField(null);
      setCurrentCropZone(null);
      return;
    }

    const newCropZone = cropZoneId
      ? getCropZones().find(cropZone => cropZone.id === cropZoneId)
      : null;
    const newField =
      fieldId || newCropZone
        ? getFields().find(
            field => field.id === fieldId || field.id === newCropZone?.fieldId
          )
        : null;
    const newFarm =
      propertyId || newField
        ? getFarms().find(
            farm =>
              farm.id === propertyId ||
              farm.id === newField?.propertyId ||
              farm.id === newCropZone?.propertyId
          )
        : null;

    setCurrentFarm(newFarm);
    setCurrentField(newField);
    setCurrentCropZone(newCropZone);
  }, [
    getFarms,
    getFields,
    getCropZones,
    title,
    propertyId,
    fieldId,
    cropZoneId
  ]);

  const farmDropDownItemProps = {
    name: farm => farm.name,
    isActive: farm => [propertyId, currentFarm?.id].includes(farm.id)
  };

  const fieldDropDownItemProps = {
    name: field => field.name,
    isActive: field => [fieldId, currentField?.id].includes(field.id)
  };

  const cropZoneDropDownItemProps = {
    name: field => field.name,
    isActive: cropZone =>
      [cropZoneId, currentCropZone?.id].includes(cropZone.id)
  };

  const handleFarmSelect = (farm, event) => {
    if (onPropertySelect && farm) {
      onPropertySelect(farm);
    } else if (onOrganizationSelect && !farm) {
      onOrganizationSelect();
      event.preventDefault();
    } else if (farm) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(location?.state?.geoJsonData, farm.id)
      );

      // history.push(`/app/property/farms/${farm.id}`);

      history.push({
        pathname: `/app/property/farms/${farm.id}`,
        state: {
          properties: farm,
          geoJsonData: location?.state?.geoJsonData
        },
        label: 'farm'
      });
    }

    setCurrentFarm(farm);
    setCurrentField(null);
    setCurrentCropZone(null);
  };

  const handleFieldSelect = (field, event) => {
    if (onFieldSelect && field) {
      onFieldSelect(field);
    } else if (onPropertySelect && !field) {
      onPropertySelect(currentFarm);
      event.preventDefault();
    } else if (field) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          location?.state?.geoJsonData,
          field.id
        )
      );
      // history.push(`/app/property/fields/${field.id}`);
      const farm = [];
      setTimeout(() => {
        /* eslint no-unused-expressions: "off", curly: "error" */
        properties?.forEach(eachFarm => {
          /* eslint no-unused-expressions: "off", curly: "error" */
          eachFarm?.fields?.forEach(eachField => {
            if (eachField?.id === field.id) {
              farm.push(eachFarm);
            }
          });
        });
      }, 2000);
      history.push({
        pathname: `/app/property/fields/${field.id}`,
        state: {
          properties: farm,
          geoJsonData: location?.state?.geoJsonData
        },
        label: 'field'
      });
    }
    setCurrentField(field);
    setCurrentCropZone(null);
  };

  const handleCropZoneSelect = (cropZone, event) => {
    if (onCropZoneSelect && cropZone) {
      onCropZoneSelect(cropZone);
    } else if (onFieldSelect && !cropZone) {
      onFieldSelect(currentField);
      event.preventDefault();
    } else if (cropZone) {
      setGeoJSONCollectionFeatures(
        filterFeatureCollectionByParentId(
          location?.state?.geoJsonData,
          cropZone.id
        )
      );
      // history.push(
      //   `/app/property/fields/${currentField.id}/cropzones/${cropZone.id}`
      // );
      const farm = [];
      setTimeout(() => {
        /* eslint no-unused-expressions: "off", curly: "error" */
        properties?.forEach(eachFarm => {
          /* eslint no-unused-expressions: "off", curly: "error" */
          eachFarm?.fields?.forEach(eachField =>
            /* eslint no-unused-expressions: "off", curly: "error" */
            eachField?.cropzones?.forEach(eachCropzone => {
              if (eachCropzone?.id === cropZone.id) {
                farm.push(eachFarm);
              }
            })
          );
        });
      }, 2000);

      history.push({
        pathname: `/app/property/fields/${currentField.id}/cropzones/${cropZone.id}`,
        state: {
          properties: farm,
          geoJsonData: location?.state?.geoJsonData
        },
        label: 'cropzone'
      });
    }
    setCurrentCropZone(cropZone);
  };

  const handleOrganizationSelect = org => {
    setCurrentFarm(null);
    setCurrentField(null);
    setCurrentCropZone(null);
    if (onOrganizationSelect) {
      onOrganizationSelect(org);
    } else {
      history.push('/app/property');
    }
  };

  useEffect(() => {
    if (properties?.length > 0 && mapFieldId?.length > 0) {
      let farmData = {};
      let fieldData = {};
      /* eslint no-unused-expressions: "off", curly: "error" */
      properties?.forEach(property => {
        /* eslint no-unused-expressions: "off", curly: "error" */
        property?.fields?.forEach(field => {
          if (field.id === mapFieldId) {
            farmData = property;
            fieldData = field;
          }
        });
      });
      setCurrentFarm(farmData);
      setCurrentField(fieldData);
    }
  }, [properties, mapFieldId]);

  return (
    <Breadcrumb
      onOrganizationSelect={handleOrganizationSelect}
      disabled={disabledOrgChange}
      disabledCropSeasons={loadingProperties}
      hideCropSeasonDropdown={false}
      onCropSeasonSelect={handleOrganizationSelect}
    >
      {disabledPropertyChange && (
        <Breadcrumb.Item title="Properties" value={title} isLast />
      )}
      {!disabledPropertyChange && (currentFarm || title) && (
        <Breadcrumb.DropDown
          dropDownItemProps={farmDropDownItemProps}
          getData={getFarms}
          isLast={!currentFarm && !fieldId}
          onSelect={handleFarmSelect}
          searchPlaceholder="Search Properties"
          title="Properties"
          value={currentFarm?.name ?? title}
          dropDownFooter={props => {
            return (
              /* eslint-disable react/jsx-props-no-spreading */
              <NavLink
                exact
                to="/app/property"
                {...props}
                onClick={() => {
                  if (
                    (!cropZoneId && fieldId === null) ||
                    (cropZoneId === null && !fieldId)
                  ) {
                    onSetShowSummaryPanel(false)();
                  }
                  reloadDataParent();
                }}
              >
                <div className="text-cucumber-green-700 hover:text-cucumber-green-500">
                  <div>All Properties</div>
                </div>
              </NavLink>
            );
          }}
        />
      )}
      {(((propertyId || currentFarm) && !currentField) || currentField) && (
        <Breadcrumb.DropDown
          dropDownItemProps={fieldDropDownItemProps}
          getData={() => getFields(currentFarm)}
          isLast={!currentField && !cropZoneId}
          onSelect={handleFieldSelect}
          searchPlaceholder="Search Fields"
          title="Field"
          value={currentField?.name ?? 'All Fields'}
          dropDownFooter={props => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <NavLink to={`/app/property/farms/${currentFarm?.id}`} {...props}>
              <div className="text-cucumber-green-700 hover:text-cucumber-green-500">
                <div>All Fields</div>
              </div>
            </NavLink>
          )}
        />
      )}
      {(((fieldId || currentField) && !currentCropZone) || currentCropZone) && (
        <Breadcrumb.DropDown
          dropDownItemProps={cropZoneDropDownItemProps}
          getData={() => getCropZones(currentField)}
          isLast
          onSelect={handleCropZoneSelect}
          searchPlaceholder="Search Crop Zones"
          title="Crop Zone"
          value={currentCropZone?.name ?? 'All Crop Zones'}
          dropDownFooter={props => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <NavLink to={`/app/property/fields/${currentField?.id}`} {...props}>
              <div className="text-cucumber-green-700 hover:text-cucumber-green-500">
                <div>All Crop Zones</div>
              </div>
            </NavLink>
          )}
        />
      )}
    </Breadcrumb>
  );
};

PropertyBreadcrumb.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  propertyId: PropTypes.string,
  fieldId: PropTypes.string,
  cropZoneId: PropTypes.string,
  title: PropTypes.string,
  onPropertySelect: PropTypes.func,
  onFieldSelect: PropTypes.func,
  onCropZoneSelect: PropTypes.func,
  onOrganizationSelect: PropTypes.func,
  onSetShowSummaryPanel: PropTypes.func,
  reloadDataParent: PropTypes.func,
  disabledOrgChange: PropTypes.bool,
  disabledPropertyChange: PropTypes.bool
};

PropertyBreadcrumb.defaultProps = {
  properties: [],
  propertyId: null,
  fieldId: null,
  cropZoneId: null,
  title: null,
  onPropertySelect: null,
  onFieldSelect: null,
  onCropZoneSelect: null,
  onOrganizationSelect: null,
  onSetShowSummaryPanel: () => {},
  reloadDataParent: () => {},
  disabledOrgChange: false,
  disabledPropertyChange: false
};

export default PropertyBreadcrumb;
