import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from 'components/Paper';
import CwFPagination from 'components/CwFPagination';
import LandingTable from 'components/LandingTable';
import RecommendationTableRow from './components/RecommendationTableRow';

export const TABLE_COLUMNS = [
  {
    field: 'name',
    title: 'Recommendation',
    sortable: true
  },
  {
    field: 'fields',
    title: 'Fields',
    sortable: true
  },
  {
    field: 'totalAcres',
    title: 'Total Acres',
    sortable: true
  },
  {
    field: 'products',
    title: 'Products',
    sortable: true
  },
  {
    field: 'validFrom',
    title: 'Valid From',
    sortable: true
  },
  {
    field: 'seasons',
    title: 'Seasons',
    sortable: true
  },
  {
    field: 'type',
    title: '',
    sortable: false
  },
  {
    field: 'unknown',
    title: '',
    sortable: false
  }
];

function RecommendationsTable({
  recommendations,
  tags,
  pageNo,
  setPageNo,
  totalRecommendations,
  onAction,
  sort,
  sortBy,
  sortDir,
  downloadingPDF,
  cropSeasons
}) {
  const [rowIndex, setRowIndex] = useState(-1);

  return (
    <div
      className="mx-2 my-4 min-w-content"
      data-testid="recommendations-table"
      style={{
        minWidth: 'min-content'
      }}
    >
      <Paper>
        {CwFPagination(totalRecommendations, pageNo, setPageNo)}
        <LandingTable
          listType="Recommendations"
          routePath="/app/recommendation"
          items={recommendations}
          tableColumns={TABLE_COLUMNS}
          onSort={sort}
          sortBy={sortBy}
          sortDir={sortDir}
        >
          {({ rows }) =>
            rows.map((item, index) => {
              return (
                <RecommendationTableRow
                  key={item.id}
                  recommendation={item}
                  cropSeasons={cropSeasons ?? []}
                  tags={tags}
                  onAction={action => {
                    setRowIndex(index);
                    onAction(action, item);
                  }}
                  runningProcesses={downloadingPDF && rowIndex === index}
                />
              );
            })
          }
        </LandingTable>
        <div style={{ minHeight: '35px' }}>
          {CwFPagination(totalRecommendations, pageNo, setPageNo)}
        </div>
      </Paper>
    </div>
  );
}

RecommendationsTable.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ).isRequired,
  cropSeasons: PropTypes.objectOf(PropTypes.object).isRequired,
  onAction: PropTypes.func.isRequired,
  pageNo: PropTypes.number.isRequired,
  setPageNo: PropTypes.func.isRequired,
  totalRecommendations: PropTypes.number.isRequired,
  sort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  downloadingPDF: PropTypes.bool
};

RecommendationsTable.defaultProps = {
  recommendations: [],
  downloadingPDF: false
};

export default RecommendationsTable;
