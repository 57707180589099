/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Button,
  ButtonType,
  Drawer,
  Row
} from 'syngenta-digital-cropwise-react-ui-kit';
import { Formik } from 'formik';
import ChevronLeftIcon from 'assets/chevron_left.svg';
import CloseIcon from 'assets/close.svg';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import {
  handleFertilizerProduct,
  handleMasterProduct,
  handleServiceProduct
} from 'screens/ProductList/helpers/handleSchemaCustomProducts';
import CustomProductListForm from '../CustomProductListForm';
import MasterListProductForm from '../MasterListProductForm';
import {
  EditCustomProductSchema,
  EditCustomProductSchemaForService
} from './editCustomProductSchemas';
import { AddMasterListProductSchema } from './addMasterListProductSchema';

const EditProductDrawer = ({
  handleEdit,
  editProductHeader,
  product,
  visible,
  saveText,
  onClose
}) => {
  const { fetchCustomProduct } = useInventoryProduct();
  const [customProduct, setCustomProduct] = useState(product);
  const [schema, setSchema] = useState();

  useEffect(() => {
    if (!product || (!product.custom && !product.isNew)) {
      handleMasterProduct(setSchema);
    } else if (product.type === 'SERVICE') {
      handleServiceProduct(product, setCustomProduct, setSchema);
    } else {
      handleFertilizerProduct(
        product,
        setCustomProduct,
        setSchema,
        fetchCustomProduct,
        visible
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, visible]);

  useEffect(() => {
    if (!visible) setSchema('FERTILIZER'); // reset schema on drawer close
  }, [visible]);

  const schemaDefinition =
    {
      MASTER: AddMasterListProductSchema,
      FERTILIZER: EditCustomProductSchema,
      SERVICE: EditCustomProductSchemaForService
    }[schema] || Yup.object().shape({});

  return !product ? null : (
    <Formik
      enableReinitialize
      validationSchema={schemaDefinition}
      initialValues={product}
      onSubmit={handleEdit}
      validator={() => ({})}
      validateOnBlur
    >
      {({ isSubmitting, handleSubmit, isValid }) => (
        <Drawer
          className="add-product-drawer"
          open={visible}
          placement="right"
          closable={false}
          maskClosable={false}
          onClose={onClose}
          width="50%"
          footer={
            <Row justify="space-between">
              <Button
                type={ButtonType.outline}
                className="px-3"
                onClick={onClose}
              >
                <Row>
                  <img className="mr-1" alt="plus" src={ChevronLeftIcon} />
                  <span>Back</span>
                </Row>
              </Button>
              <Button
                type={ButtonType.primary}
                loading={isSubmitting}
                disabled={!isValid}
                onClick={handleSubmit}
              >
                {saveText}
              </Button>
            </Row>
          }
        >
          <div className="h-full">
            <div className="pt-4 pr-4 pb-5 pl-8">
              <Row justify="end">
                <button type="button" onClick={onClose}>
                  <img alt="close" src={CloseIcon} />
                </button>
              </Row>
              {product.custom || product.isNew ? (
                <div>
                  <div className="text-xl font-semibold">
                    {editProductHeader}
                  </div>
                  {customProduct ? (
                    <CustomProductListForm
                      setSchema={setSchema}
                      product={customProduct}
                    />
                  ) : null}
                </div>
              ) : (
                <div>
                  <div className="ml-2 mb-5 text-xl font-semibold">
                    {editProductHeader}
                  </div>
                  <MasterListProductForm product={product} />
                </div>
              )}
            </div>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};
EditProductDrawer.defaultProps = {
  saveText: 'Save'
};

EditProductDrawer.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  product: PropTypes.shape({
    type: PropTypes.string,
    isNew: PropTypes.bool,
    name: PropTypes.string,
    productId: PropTypes.string,
    averagePricePerUnit: PropTypes.number,
    density: PropTypes.number,
    custom: PropTypes.bool
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editProductHeader: PropTypes.string.isRequired,
  saveText: PropTypes.string
};

export default EditProductDrawer;
