import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import MassAssignerTree from './components/MassAssignerTree';
import DefaultTree from './components/DefaultTree';

const PropertyTreeView = ({
  properties,
  rawProperties,
  fieldsAndAreasGeoJSONCollection,
  onFarmSelect,
  onFieldSelect,
  onCropZoneSelect,
  loadFarmCosts,
  reloadData
}) => {
  const [{ isMassAssignerActive }] = useContext(Context);
  const propertiesFilter = (obj, searchTextValueFFT) => {
    return searchTextValueFFT.valueInput === '' ||
      searchTextValueFFT.valueInput === undefined
      ? obj
      : obj.filter(
          prop =>
            prop.name.toLowerCase().includes(searchTextValueFFT.valueInput) ||
            prop.fields.some(field =>
              field.name.toLowerCase().includes(searchTextValueFFT.valueInput)
            )
        );
  };
  return isMassAssignerActive ? (
    <MassAssignerTree
      properties={properties}
      fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
      propertiesFilter={propertiesFilter}
    />
  ) : (
    <DefaultTree
      properties={properties}
      rawProperties={rawProperties}
      fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
      onFarmSelect={onFarmSelect}
      onFieldSelect={onFieldSelect}
      onCropZoneSelect={onCropZoneSelect}
      propertiesFilter={propertiesFilter}
      loadFarmCosts={loadFarmCosts}
      reloadData={reloadData}
    />
  );
};

PropertyTreeView.defaultProps = {
  properties: [],
  rawProperties: [],
  fieldsAndAreasGeoJSONCollection: {},
  loadFarmCosts: () => {},
  reloadData: () => {}
};

PropertyTreeView.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object),
  rawProperties: PropTypes.arrayOf(PropTypes.object),
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf(PropTypes.any),
  onFarmSelect: PropTypes.func.isRequired,
  onFieldSelect: PropTypes.func.isRequired,
  onCropZoneSelect: PropTypes.func.isRequired,
  loadFarmCosts: PropTypes.func,
  reloadData: PropTypes.func
};

export default PropertyTreeView;
