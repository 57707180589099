/* eslint-disable react/jsx-curly-brace-presence */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tag, TagCloud } from '@agconnections/grow-ui';

import Farm from './components/Farm';

const SummaryFarms = ({ farm, selectFarm }) => {
  const lengths = useMemo(
    () =>
      farm?.fields?.reduce(
        /* eslint-disable no-param-reassign */
        (result, field) => {
          result.cropzones += field.cropzones?.length;
          result.applications += field.cropzones?.reduce(
            (tasksCount, cropzone) => tasksCount + cropzone.tasks.length,
            0
          );

          return result;
        },
        { applications: 0, cropzones: 0 }
      ),
    [farm]
  );

  if (lengths?.cropzones === 0) return null;

  return (
    <div
      className="flex"
      style={{ justifyContent: 'space-between' }}
      data-testid="submission-summary-farms"
    >
      <Farm farm={farm} selectFarm={selectFarm} />
      <div className="flex">
        <div className="h-full">
          <div>
            <TagCloud>
              <Tag key="cropzones-length" color="#D60469">
                {lengths?.cropzones} Crop Zone(s)
              </Tag>
              <Tag key="applications-length" color="#2B9C92">
                {lengths?.applications} Application(s)
              </Tag>
            </TagCloud>
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryFarms.propTypes = {
  farm: PropTypes.shape().isRequired,
  selectFarm: PropTypes.func.isRequired
};

export default SummaryFarms;
