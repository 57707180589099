import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import { noop } from 'lodash';
import FieldIcon from 'screens/Property/components/FieldIcon';
import FormattedNumber from 'components/FormattedNumber';
import { mappingShape } from 'screens/Property/helpers/propertyDataHelpers';
import { useHistory } from 'react-router-dom';
import { paths } from 'routes/paths';
import ClickableDiv from 'components/ClickableDiv';
import { getCurArea } from '../../helpers/leftPanel';
import EditRowButton from '../EditRowButton';

const CropZoneRow = ({
  cropZone,
  onSelect,
  isSelected,
  icon,
  fieldId,
  isEditable
}) => {
  const [{ updateCropName }] = useContext(Context);
  const history = useHistory();
  const [cropZoneMapped, setCropZoneMapped] = useState(icon);
  const [showEditButton, setShowEditButton] = useState(false);

  useEffect(() => {
    if (icon) {
      setCropZoneMapped(mappingShape(icon));
    }
  }, [icon]);

  const handleClick = () => {
    history.push(`${paths.properties}/edit/${fieldId}/cropzone/${cropZone.id}`);
  };

  const getCropName = () => {
    if (updateCropName && updateCropName.cropZoneId === cropZone.id)
      return updateCropName?.cropName;
    return cropZone?.crop?.name;
  };

  const handleMouseEnter = () => {
    setShowEditButton(true);
  };

  const handleMouseLeave = () => {
    setShowEditButton(false);
  };

  const cropZoneIconBorderColor = () => {
    if (cropZone.archived) return null;
    if (isSelected) return '#696F88';
    return '#868CA2';
  };

  return (
    <ClickableDiv
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onSelect(cropZone)}
      className="w-full py-3 pl-16 pr-1 text-left flex items-center justify-between"
    >
      {isSelected && (
        <div className="absolute left-0 h-50px w-1 bg-blue-50 rounded-r" />
      )}
      <div
        data-testid={`crop-zone-row-${cropZone.id}`}
        className="flex items-start"
      >
        <FieldIcon
          id="field-card-icon"
          features={[cropZoneMapped]}
          color="#DFE2E7"
          border={cropZoneIconBorderColor()}
          className="flex-shrink-0"
        />
        <div className="ml-2">
          <p
            className={`font-medium leading-5 break-all ${
              cropZone.archived ? 'text-gray-600' : 'text-neutral-1000'
            } ${isSelected ? 'font-semibold' : ''}`}
          >
            {cropZone.name}
          </p>
          <div className="mt-2px text-xs leading-3">
            <span
              className={
                cropZone.archived ? 'text-gray-700' : 'text-neutral-60'
              }
            >
              <FormattedNumber roundedTo={2}>
                {getCurArea(cropZone)}
              </FormattedNumber>
              &nbsp;ac • {getCropName()}
            </span>
          </div>
        </div>
      </div>
      {isEditable && showEditButton && (
        <EditRowButton isRowSelected={isSelected} onClick={handleClick} />
      )}
    </ClickableDiv>
  );
};

CropZoneRow.defaultProps = {
  cropZone: {
    name: '',
    boundaryArea: 0,
    crop: {
      name: ''
    }
  },
  onSelect: noop,
  icon: undefined,
  isEditable: true
};

CropZoneRow.propTypes = {
  cropZone: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    boundaryArea: PropTypes.number,
    crop: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    }),
    archived: PropTypes.bool
  }),
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.objectOf(PropTypes.any),
  fieldId: PropTypes.string.isRequired,
  isEditable: PropTypes.bool
};

export default CropZoneRow;
