/* eslint-disable import/prefer-default-export */
import { shouldUseMirage } from 'utilities/apiConstants';
import { genResource, seasons as cropSeasonsApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import {
  CROP_SEASONS,
  GLOBAL_SET_ORGANIZATION,
  GLOBAL_SET_PERMISSION,
  IS_GLOBAL_CROPSEASON_LOADING,
  SELECT_PROPERTY
} from 'reducers/reducer';
import createAction from 'helpers/createAction';

const defaultApiInstance = genResource();

export const getOrganizationPermissions = (organization, authorities) => {
  if (shouldUseMirage) {
    return [
      { scope: 'WRITE', name: 'APPS' },
      { scope: 'READ', name: 'ORG' }
    ];
  }
  return authorities?.find(authority => organization?.id === authority?.id)
    ?.permissions;
};
export const selectCropSeason = async (dispatch, selectedSeasons) => {
  createAction(dispatch, IS_GLOBAL_CROPSEASON_LOADING, true);
  if (JSON.parse(localStorage.getItem('selectedCropSeasons')) === null) {
    localStorage.setItem('selectedCropSeasons', JSON.stringify([]));
  }
  const currentTime = new Date().getTime();
  const { promise } = cropSeasonsApi.fetch(
    undefined,
    { pageNo: 0, size: 1000 },
    undefined,
    'results',
    true
  );
  await promise
    .then(({ data }) => {
      if (data) {
        const groupedSeasons = { active: [], upcoming: [], past: [] };
        data.forEach(item => {
          if (currentTime > item.startDateEpoch) {
            if (currentTime > item.endDateEpoch) {
              groupedSeasons.past.push(item.id);
            } else {
              groupedSeasons.active.push(item.id);
            }
          } else {
            groupedSeasons.upcoming.push(item.id);
          }
        });
        const selected = JSON.parse(
          localStorage.getItem('selectedCropSeasons')
        );
        if (!selected.length) {
          if (groupedSeasons.active.length) {
            localStorage.setItem(
              'selectedCropSeasons',
              JSON.stringify(groupedSeasons.active)
            );
          } else if (groupedSeasons.past.length) {
            localStorage.setItem(
              'selectedCropSeasons',
              JSON.stringify([groupedSeasons.past[0]])
            );
          } else if (groupedSeasons.upcoming.length) {
            localStorage.setItem(
              'selectedCropSeasons',
              JSON.stringify([groupedSeasons.upcoming[0]])
            );
          }
        } else if (selectedSeasons !== null) {
          localStorage.setItem(
            'selectedCropSeasons',
            JSON.stringify([selectedSeasons].flat())
          );
        }
        createAction(dispatch, CROP_SEASONS, data);
      }
    })
    .catch(catchCancel)
    .catch(err => {
      parseServerError(dispatch)(err);
    })
    .finally(() => createAction(dispatch, IS_GLOBAL_CROPSEASON_LOADING, false));
};

export const selectOrganization = (dispatch, organization, user) => {
  const organizationAuthorities = getOrganizationPermissions(
    organization,
    user.authorities
  );
  createAction(dispatch, GLOBAL_SET_ORGANIZATION, organization);
  createAction(dispatch, GLOBAL_SET_PERMISSION, organizationAuthorities);
  createAction(dispatch, SELECT_PROPERTY, {});
  localStorage.setItem('PROPERTY_VIEW_v2', 'map');
  defaultApiInstance.updateRequestInterceptor(
    {},
    () => `{organization_id: ${organization.id}}`
  );
  localStorage.setItem('selectedOrganizationId', organization.id);
};

// Need to get a default org if a default org has not been selected. First on the list?
export const getCurrentOrganizationAndPermissions = (user, organizations) => {
  if (shouldUseMirage) {
    return {
      currentOrganization: {
        id: process.env.REACT_APP_DEVTEST_ORGANIZATIONID,
        name: 'Test Org'
      },
      currentOrganizationPermissions: []
    };
  }
  let currentOrganization = null;
  let currentOrganizationPermissions = null;
  const authorities = user ? user.authorities : null;
  let selectedOrganizationId = localStorage.getItem('selectedOrganizationId');

  if (organizations?.length) {
    const filteredOrganization = organizations.find(
      organization => organization.id === selectedOrganizationId
    );
    if (filteredOrganization) {
      currentOrganization = filteredOrganization;
      currentOrganizationPermissions = getOrganizationPermissions(
        currentOrganization,
        authorities
      );
    } else {
      selectedOrganizationId = null;
    }
  }

  if (user && !selectedOrganizationId && organizations?.length) {
    const firstOrganization = organizations[0];
    localStorage.setItem('selectedOrganizationId', firstOrganization.id);

    currentOrganization = firstOrganization;
    currentOrganizationPermissions = getOrganizationPermissions(
      currentOrganization,
      authorities
    );
  }

  return {
    currentOrganization,
    currentOrganizationPermissions
  };
};
