import React from 'react';
import PropTypes from 'prop-types';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import { AgrianContext } from '../../context/Provider';

const AgrianSubmissionFooter = ({
  nextDisabled,
  onNext,
  setModalSubmissionState,
  isLastStep,
  integrationType
}) => {
  return (
    <SubmissionFooter
      nextDisabled={nextDisabled}
      onNext={onNext}
      setModalSubmissionState={setModalSubmissionState}
      isLastStep={isLastStep}
      context={AgrianContext}
      integrationType={integrationType}
    />
  );
};

AgrianSubmissionFooter.propTypes = {
  nextDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  setModalSubmissionState: PropTypes.func,
  isLastStep: PropTypes.bool,
  integrationType: PropTypes.string
};

AgrianSubmissionFooter.defaultProps = {
  nextDisabled: false,
  onNext: () => {},
  setModalSubmissionState: () => {},
  isLastStep: false,
  integrationType: INTEGRATIONS.agrian
};

export default AgrianSubmissionFooter;
