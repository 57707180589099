import { fieldBodyGenerator } from 'components/PDF/helpers/tableGenerator';
import { convertNumberToDesiredFormat } from 'helpers/transformHelpers';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

export const PRODUCT_WIDTH = 19;
export const AREA_APPLIED_WIDTH = 14;
export const PERCENT_AREA_WIDTH = 11;
export const EPA_NUMBER_WIDTH = 13;
export const REI_WIDTH = 8;
export const PHI_WIDTH = 8;
export const RATE_AREA_WIDTH = 15;
export const TOTAL_WIDTH = 12;

export const PRODUCT_HEADER_ITEMS = [
  { label: 'Name/Active', width: PRODUCT_WIDTH },
  { label: 'Area Applied', width: AREA_APPLIED_WIDTH },
  { label: '% Area', width: PERCENT_AREA_WIDTH },
  { label: 'EPA Number', width: EPA_NUMBER_WIDTH },
  { label: 'REI(h)', width: REI_WIDTH },
  { label: 'PHI(d)', width: PHI_WIDTH },
  { label: 'Rate/Area', width: RATE_AREA_WIDTH },
  { label: 'Total', width: TOTAL_WIDTH }
];

const FIELD_NAMES_WIDTH = 18;
const LAT_LONG_WIDTH = 19;
const REC_AREA_WIDTH = 9;

export const FIELDS_HEADER_ITEMS = [
  { label: 'Farm', width: FIELD_NAMES_WIDTH },
  { label: 'Field', width: FIELD_NAMES_WIDTH },
  { label: 'Crop Zone', width: FIELD_NAMES_WIDTH },
  { label: 'Crop', width: FIELD_NAMES_WIDTH },
  { label: 'Center Lat/Long', width: LAT_LONG_WIDTH },
  { label: 'Rec. Area', width: REC_AREA_WIDTH }
];

export const mapProductsToTable = products =>
  products.map(product => {
    const productName = fieldBodyGenerator(product.productName, {
      width: `${PRODUCT_WIDTH}%`
    });
    const appliedArea = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.productAppliedAreaValue, 'fixed'),
      {
        width: `${AREA_APPLIED_WIDTH}%`
      }
    );
    const percent = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.coveragePercent, 'percent'),
      {
        width: `${PERCENT_AREA_WIDTH}%`
      }
    );
    const epaNumber = fieldBodyGenerator(product.registrationnumber, {
      width: `${EPA_NUMBER_WIDTH}%`
    });
    const REI = fieldBodyGenerator('-', {
      width: `${REI_WIDTH}%`
    });
    const PHI = fieldBodyGenerator('-', {
      width: `${PHI_WIDTH}%`
    });
    const rateArea = fieldBodyGenerator(
      `${convertNumberToDesiredFormat(
        product.productRatePerAreaValue,
        'fixed'
      )} ${getAbbreviatedUnit(product.productRatePerAreaUnit)} / ac`,
      {
        width: `${RATE_AREA_WIDTH}%`
      }
    );
    const total = fieldBodyGenerator(
      convertNumberToDesiredFormat(product.totalProductValue, 'fixed'),
      {
        width: `${TOTAL_WIDTH}%`
      }
    );

    return [
      productName,
      appliedArea,
      percent,
      epaNumber,
      REI,
      PHI,
      rateArea,
      total
    ];
  });

export const fieldsToTable = fields =>
  fields.map(field => {
    const farmName = fieldBodyGenerator(field.farmName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const fieldName = fieldBodyGenerator(field.fieldName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const cropZoneName = fieldBodyGenerator(field.cropZoneName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const cropName = fieldBodyGenerator(field.cropName, {
      width: `${FIELD_NAMES_WIDTH}%`
    });
    const latlong = fieldBodyGenerator(
      `${field.latlong[1]}, ${field.latlong[0]}`,
      {
        width: `${LAT_LONG_WIDTH}%`
      }
    );
    const area = fieldBodyGenerator(
      convertNumberToDesiredFormat(field.areaValue, 'fixed'),
      {
        width: `${REC_AREA_WIDTH}%`
      }
    );

    return [farmName, fieldName, cropZoneName, cropName, latlong, area];
  });
