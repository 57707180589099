import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Popup,
  Spinner,
  Checkbox,
  Radio,
  Button
} from '@agconnections/grow-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { generate } from 'shortid';

import useDebounce from 'hooks/useDebounce';
import { Context } from 'components/Store';
import { RESET_MASS_ASSIGNER } from 'reducers/reducer';
import SeasonCreateModal from 'components/Modals/SeasonCreateModal';
import { cloneDeep, orderBy } from 'lodash';
import { selectCropSeason } from 'utilities/updated-permissions';
import BreadCrumbItem from '../../../App/AppShell/components/Breadcrumb/BreadCrumbItem';

const BreadcrumbCropSeasons = ({
  getData,
  isLast,
  onSelect,
  searchPlaceholder,
  title,
  disabled
}) => {
  const [menuOpened, setMenuOpen] = useState(false);
  const [{ cropSeasons }, dispatch] = useContext(Context);
  const [items, setItems] = useState({ active: [], upcoming: [], past: [] });
  const [loaded, setLoaded] = useState(false);
  const [selectedInactiveSeason, setSelectedInactiveSeason] = useState();
  const [selectedActiveSeasons, setSelectedActiveSeason] = useState();
  const [seasonTitle, setTitle] = useState('New Crop Season');
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const [openCropSeasonNewModal, setOpenCropSeasonNewModal] = useState(false);

  useEffect(() => {
    if (menuOpened) {
      const data = getData();
      const currentTime = new Date().getTime();
      const groupedSeasons = { active: [], upcoming: [], past: [] };
      data
        .filter(
          obj =>
            !debouncedSearchTerm ||
            obj.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        )
        .forEach(item => {
          if (currentTime > item.startDateEpoch) {
            if (currentTime > item.endDateEpoch) {
              groupedSeasons.past.push(item);
            } else {
              groupedSeasons.active.push(item);
            }
          } else {
            groupedSeasons.upcoming.push(item);
          }
        });
      const pastClone = cloneDeep(groupedSeasons.past);
      const orderedPast = orderBy(
        pastClone,
        [past => past.name.toLowerCase()],
        'asc'
      );
      groupedSeasons.past = orderedPast;
      const selectedCropSeasons =
        JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
      setItems(groupedSeasons);
      setLoaded(true);
      const activeSeasons = cropSeasons
        .filter(
          season => selectedCropSeasons.includes(season.id) && season.active
        )
        .map(season => season.id);
      const viewNoSeason = !activeSeasons.length
        ? '88888888-8888-8888-8888-888888888888'
        : null;
      setSelectedInactiveSeason(
        cropSeasons.find(
          season => selectedCropSeasons.includes(season.id) && !season.active
        )?.id || viewNoSeason
      );
      setSelectedActiveSeason(activeSeasons);
    }
  }, [debouncedSearchTerm, menuOpened, cropSeasons, getData]);

  useEffect(() => {
    const selectedCropSeasons =
      JSON.parse(localStorage.getItem('selectedCropSeasons')) || [];
    const seasonsSelected =
      selectedCropSeasons.length &&
      selectedCropSeasons[0] !== '88888888-8888-8888-8888-888888888888'
        ? cropSeasons
            ?.filter(season => selectedCropSeasons.includes(season.id))
            .reduce((season, val) => {
              const pipe = season.length ? ' | ' : '';
              return season + pipe + val.name;
            }, '')
        : 'New Crop Season';
    setTitle(seasonsSelected);
  }, [cropSeasons]);

  const closeMenu = () => {
    setMenuOpen(false);
    setSearchText(null);
  };

  const setItemState = () => {
    const selectedSeasons = selectedActiveSeasons.length
      ? selectedActiveSeasons
      : [selectedInactiveSeason];
    if (selectedSeasons[0]) onSelect(selectedSeasons);
    closeMenu();
  };

  const handleCreateSeason = async season => {
    await selectCropSeason(dispatch, season.id);
    onSelect(season.id);
  };

  const handleTriggerClick = () => {
    if (disabled) return;
    setMenuOpen(isOpen => !isOpen);
    setSearchText(null);
  };

  const handleTriggerKeyDown = event => {
    if (disabled) return;
    if (event.nativeEvent.key === 'Enter') {
      setMenuOpen(isOpen => !isOpen);
      setSearchText(null);
    }
  };

  const Trigger = (
    <div
      data-testid="nav-menu-head"
      className={`flex items-center  flex-shrink-0 focus:border-none text-white
    ${disabled ? 'pointer-events-none' : ''}`}
      role="button"
      tabIndex={0}
      onClick={handleTriggerClick}
      onKeyDown={handleTriggerKeyDown}
      disabled={disabled} // this is not a mistake, this disable is for the trigger button
    >
      <BreadCrumbItem
        title={title}
        value={seasonTitle}
        isLast={isLast}
        disabled={disabled} // this disable is for the style
      />
    </div>
  );

  return (
    <>
      <Popup
        trigger={Trigger}
        position="bottom"
        align="left"
        hasOffset
        open={menuOpened}
        onClose={closeMenu}
        footer={[
          <div
            key={generate()}
            className="w-full text-left rounded-b bg-white py-2 px-4 border-t border-neutral-200"
          >
            <div className="flex justify-between">
              <Button
                ghost
                data-testid="add-crop-season-button"
                onClick={event => {
                  event.stopPropagation();
                  closeMenu();
                  setOpenCropSeasonNewModal(true);
                }}
              >
                + New Season
              </Button>
              <div className="flex justify-end">
                <div className="mr-2">
                  <Button
                    ghost
                    data-testid="cancel-button"
                    onClick={event => {
                      event.stopPropagation();
                      closeMenu();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <Button
                  ghost
                  type="primary"
                  data-testid="apply-button"
                  onClick={event => {
                    event.stopPropagation();
                    dispatch({ type: RESET_MASS_ASSIGNER });
                    setItemState();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        ]}
      >
        <div
          data-testid="breadcrumb-popup"
          className="w-92 h-382px overflow-auto text-left"
        >
          <div className="relative w-full text-gray-400 focus-within:text-gray-600 mr-4 mb-2">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <div className="mr-1.5 h-5 w-5 pl-2 text-gray-400">
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </div>
            </div>
            <input
              className="border block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-600 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
              autoComplete="off"
              type="text"
              name="search_breadcrumb"
              placeholder={searchPlaceholder}
              onChange={event => {
                setSearchText(event.target.value);
              }}
            />
          </div>
          {loaded && !disabled ? (
            <>
              <div className="font-medium mb-2 ml-2">Crop Seasons</div>
              <ul className="mb-2 ml-2">
                <Radio
                  label="View Unassigned Fields"
                  value="88888888-8888-8888-8888-888888888888"
                  checked={
                    selectedInactiveSeason ===
                    '88888888-8888-8888-8888-888888888888'
                  }
                  onChange={e => {
                    if (selectedActiveSeasons) setSelectedActiveSeason([]);
                    setSelectedInactiveSeason(e.target.value);
                  }}
                />
              </ul>
              <div className="font-normal text-sm mb-2 ml-2 text-neutral-600">
                Active Seasons
              </div>
              <ul className="mb-2  ml-2">
                {items.active.length === 0 ? (
                  <div className="font-normal text-sm ml-4">
                    No Active Seasons
                  </div>
                ) : (
                  items.active.map(item => (
                    <Checkbox
                      key={item.id}
                      label={item.name}
                      value={item.id}
                      checked={selectedActiveSeasons.includes(item.id)}
                      onChange={e => {
                        if (selectedInactiveSeason)
                          setSelectedInactiveSeason(null);
                        if (e.target.checked) {
                          setSelectedActiveSeason([
                            ...selectedActiveSeasons,
                            e.target.value
                          ]);
                        } else {
                          setSelectedActiveSeason(
                            selectedActiveSeasons.filter(
                              season => season !== e.target.value
                            )
                          );
                        }
                      }}
                    />
                  ))
                )}
              </ul>
              <div className="font-normal text-sm ml-2 mb-2 text-neutral-600">
                Upcoming Seasons
              </div>
              <ul className="mb-2 ml-2">
                {items.upcoming.length === 0 ? (
                  <div className="font-normal text-sm ml-4">
                    No Upcoming Seasons
                  </div>
                ) : (
                  items.upcoming.map(item => (
                    <Radio
                      key={item.id}
                      label={item.name}
                      value={item.id}
                      checked={selectedInactiveSeason === item.id}
                      onChange={e => {
                        if (selectedActiveSeasons) setSelectedActiveSeason([]);
                        setSelectedInactiveSeason(e.target.value);
                      }}
                    />
                  ))
                )}
              </ul>
              <div className="font-normal text-sm ml-2 mb-2 text-neutral-600">
                Past Seasons
              </div>
              <ul className="mb-2 ml-2">
                {items.past.length === 0 ? (
                  <div className="font-normal text-sm ml-4">
                    No Past Seasons
                  </div>
                ) : (
                  items.past.map(item => (
                    <Radio
                      key={item.id}
                      label={item.name}
                      value={item.id}
                      checked={selectedInactiveSeason === item.id}
                      onChange={e => {
                        if (selectedActiveSeasons) setSelectedActiveSeason([]);
                        setSelectedInactiveSeason(e.target.value);
                      }}
                    />
                  ))
                )}
              </ul>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </Popup>
      <SeasonCreateModal
        open={openCropSeasonNewModal}
        onClose={() => setOpenCropSeasonNewModal(false)}
        onSuccess={handleCreateSeason}
      />
    </>
  );
};

BreadcrumbCropSeasons.defaultProps = {
  searchPlaceholder: 'Search',
  isLast: false,
  disabled: false
};

BreadcrumbCropSeasons.propTypes = {
  getData: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default BreadcrumbCropSeasons;
