/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const NotificationCounter = ({ notifications, style }) => {
  return (
    notifications > 0 && (
      <span
        data-testid="notification-counter"
        id="notification-counter"
        className="absolute -left-3 -top-2 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs"
        style={style}
      >
        {notifications}
      </span>
    )
  );
};

NotificationCounter.propTypes = {
  notifications: PropTypes.number.isRequired,
  style: PropTypes.shape()
};

NotificationCounter.defaultProps = {
  style: {}
};

export default NotificationCounter;
