/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'components/Store';
import { Spinner } from '@agconnections/grow-ui';
import {
  EmptyProperties,
  PanelHeader,
  PropertyTreeView
} from '../components/PanelComponents';
import PropertyFilters from '../components/PropertyTreeView/components/PropertyFilters';
import PanelFooter from '../components/PanelFooter';

const PropertiesTree = ({
  reloadData,
  properties,
  rawProperties,
  fieldsAndAreasGeoJSONCollection,
  openCreateModal,
  onFarmSelect,
  onFieldSelect,
  onCropZoneSelect,
  memberRole,
  memberFinancialAccess,
  loadFarmCosts,
  loading,
  view,
  setView,
  onChangeView
}) => {
  const [{ loadingProperties }] = useContext(Context);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const onOpenFilters = () => setFiltersOpen(true);
  const onCloseFilters = () => setFiltersOpen(false);

  return (
    <div
      data-testid="property-landing-property-tree"
      className="h-full bg-white cursor-default flex flex-col border-r border-neutral-20"
    >
      {filtersOpen ? (
        <PropertyFilters properties={rawProperties} onClose={onCloseFilters} />
      ) : (
        <>
          <PanelHeader
            showTools={!!properties?.length}
            title="Properties"
            reloadFarmData={reloadData}
            openCreateModal={openCreateModal}
            openFilters={onOpenFilters}
            loading={loading}
            onChangeView={onChangeView}
            view={view}
          />
          {properties?.length ? (
            <PropertyTreeView
              properties={properties}
              fieldsAndAreasGeoJSONCollection={fieldsAndAreasGeoJSONCollection}
              onFarmSelect={onFarmSelect}
              onFieldSelect={onFieldSelect}
              onCropZoneSelect={onCropZoneSelect}
              loadFarmCosts={loadFarmCosts}
              reloadData={reloadData}
            />
          ) : loadingProperties ? (
            <Spinner />
          ) : (
            <EmptyProperties
              openCreateModal={openCreateModal}
              memberRole={memberRole}
              memberFinancialAccess={memberFinancialAccess}
            />
          )}

          <PanelFooter
            showTools={!!properties?.length}
            reloadFarmData={reloadData}
            openCreateModal={openCreateModal}
            setView={setView}
          />
        </>
      )}
    </div>
  );
};

PropertiesTree.defaultProps = {
  properties: [],
  rawProperties: [],
  fieldsAndAreasGeoJSONCollection: {},
  loadFarmCosts: () => {},
  memberFinancialAccess: ''
};

PropertiesTree.propTypes = {
  reloadData: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.object),
  rawProperties: PropTypes.arrayOf(PropTypes.object),
  fieldsAndAreasGeoJSONCollection: PropTypes.objectOf(PropTypes.any),
  openCreateModal: PropTypes.func.isRequired,
  onFarmSelect: PropTypes.func.isRequired,
  onFieldSelect: PropTypes.func.isRequired,
  onCropZoneSelect: PropTypes.func.isRequired,
  memberRole: PropTypes.string.isRequired,
  memberFinancialAccess: PropTypes.string,
  loadFarmCosts: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  onChangeView: PropTypes.func.isRequired
};

export default PropertiesTree;
