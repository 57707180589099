import { useCallback, useContext, useEffect, useState } from 'react';

import { Context } from 'components/Store';
import { orderBy } from 'lodash';
import { selectCropSeason } from 'utilities/updated-permissions';

const useCropSeasons = debouncedSearchTerm => {
  const [
    { cropSeasons: contextCropSeasons, isGlobalCropSeasonLoading },
    dispatch
  ] = useContext(Context);
  const [cropSeasons, setCropSeasons] = useState();

  const getAllSeasons = async () => {
    await selectCropSeason(dispatch, null);
  };

  const reloadCropSeasons = () => {
    getAllSeasons();
  };

  const findCropSeason = useCallback(
    value => {
      const result = contextCropSeasons
        ? contextCropSeasons.filter(
            season =>
              !value || season.name.toLowerCase().includes(value.toLowerCase())
          )
        : [];

      if (result) {
        setCropSeasons(
          orderBy(result, [({ name }) => name?.toLowerCase()], 'asc')
        );
      }
    },
    [contextCropSeasons]
  );

  useEffect(() => {
    if (isGlobalCropSeasonLoading) return;
    getAllSeasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      findCropSeason(debouncedSearchTerm);
    } else {
      findCropSeason();
    }
  }, [debouncedSearchTerm, findCropSeason]);
  return {
    cropSeasons,
    findCropSeason,
    loaded: !isGlobalCropSeasonLoading,
    reloadCropSeasons
  };
};
export default useCropSeasons;
