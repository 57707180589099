import React, { useContext, useState, useEffect, useRef } from 'react';
import { Context } from 'components/Store';
import { useHistory, useParams } from 'react-router-dom';
import { usePDF } from '@react-pdf/renderer';
import { APPLICATION_OPTIONS, DEFAULT_TASK_VALUES } from 'helpers/constants';
import { defaultProducts } from 'helpers/transformHelpers';
import { invoice as invoiceApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { ORG_LOADING } from 'reducers/reducer';
import { addHours, format } from 'date-fns';
import _ from 'lodash';
import usePDFTools from 'hooks/usePDFTools';
import useCompanies from 'hooks/useCompanies';
import InvoicePdf from 'components/PDF/InvoicePdf';
import { mapInvoiceProductsToTable } from 'components/PDF/InvoicePdf/helpers/tableMaker';

import { paths } from 'routes/paths';
import { defaultValues, fetchInvoiceById } from '../Invoice';

const useInvoiceActions = () => {
  const [{ organization }, dispatch] = useContext(Context);
  const { companies } = useCompanies();

  const { downloadPDFV2, downloading, setDownloading } = usePDFTools();
  const [invoiceToPdf, setInvoiceToPdf] = useState();
  const [instance, updateInstance] = usePDF({
    document: <InvoicePdf invoice={invoiceToPdf} />
  });

  const prevPdfUrl = useRef(undefined);

  useEffect(() => {
    if (invoiceToPdf) {
      updateInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceToPdf]);

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      prevPdfUrl.current !== instance.url &&
      invoiceToPdf
    ) {
      prevPdfUrl.current = instance.url;
      downloadPDFV2(prevPdfUrl.current, `Invoice - ${invoiceToPdf.title}.pdf`);
      setInvoiceToPdf(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    id: null
  });
  const [invoiceTags, setInvoiceTags] = useState([]);
  const [openTags, setOpenTags] = useState(false);
  const [selectedInvoiceTitleAndId, setSelectedInvoiceTitleAndId] = useState({
    id: '',
    title: ''
  });

  const [selectedInvoiceData, setSelectedInvoiceData] = useState(defaultValues);

  const history = useHistory();

  const viewAction = invoice => {
    history.push(`${paths.invoices}/${invoice.invoiceId}/view`);
  };

  const editAction = invoice => {
    history.push({
      pathname: `${paths.invoices}/${invoice.invoiceId}`,
      invoiceID: invoice.invoiceId
    });
  };

  const deleteAction = invoice => {
    setDeleteModalState({ id: invoice.invoiceId, open: true });
  };

  const tagAction = invoice => {
    setInvoiceTags(
      invoice.flags
        ? invoice.flags.reduce((tagString, { id }) => {
            return `${tagString},${id}`;
          }, '')
        : ''
    );
    setSelectedInvoiceTitleAndId({
      id: invoice.invoiceId,
      title: invoice.title
    });
    setOpenTags(true);
    fetchInvoiceById(invoice.invoiceId, setSelectedInvoiceData);
  };

  const fetchInvoiceProducts = async id => {
    const { promise } = invoiceApi.fetch(id);

    const invoiceProducts = await promise
      .then(({ data }) => data.invoiceProducts)
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);

        return [];
      });

    return invoiceProducts;
  };

  const mapInvoiceToTask = async invoice => {
    const newTask = _.cloneDeep(DEFAULT_TASK_VALUES);
    newTask.applicationStrategy = APPLICATION_OPTIONS[1].key;
    let { invoiceProducts } = invoice;

    if (!invoiceProducts) {
      invoiceProducts = await fetchInvoiceProducts(invoice.invoiceId);
    }

    const products = invoiceProducts.map(product => {
      const specificCostValue =
        product.totalCostValue / product.totalQuantityValue;
      return defaultProducts({
        ...product,
        specificCostValue,
        totalProductValue: product.totalQuantityValue,
        totalProductUnit: product.totalQuantityUnit,
        totalProductCost: product.totalCostValue,
        productRateUnit: product.totalQuantityUnit,
        specificCostUnit: product.totalQuantityUnit
      });
    });

    const startDate = new Date(invoice.invoiceDate);

    newTask.products = products;
    newTask.source = invoice.invoiceId;
    newTask.startDate = startDate;
    newTask.dueDate = addHours(startDate, 2);
    newTask.authorizedDate = startDate;
    newTask.properties = [];

    return newTask;
  };

  const createTaskAction = async invoice => {
    dispatch({
      type: ORG_LOADING,
      payload: true
    });
    const convertInvoice = await mapInvoiceToTask(invoice);
    dispatch({
      type: ORG_LOADING,
      payload: false
    });

    dispatch({
      type: 'SET_CONVERT_PLAN',
      payload: convertInvoice
    });
    history.push(`${paths.tasks}/convert`);
  };

  const getCompanyNameById = vendorId => {
    let vendorName = '--';
    if (companies?.length > 0 && vendorId !== '') {
      vendorName = companies.find(company => company.id === vendorId)?.name;
    }
    return vendorName || '--';
  };
  const { id } = useParams();
  const shareOrPrintAction = async invoice => {
    setDownloading(true);
    const vendorName = getCompanyNameById(invoice.vendor);
    const generatedDate = new Date();
    const year = format(generatedDate, 'yyyy');
    const fullDate = format(generatedDate, 'MM/dd/yyyy h:mm aaa');
    const invoiceProducts = await fetchInvoiceProducts(invoice.invoiceId);
    const mappedInvoiceProductEntries = mapInvoiceProductsToTable(
      invoiceProducts
    );
    const invoiceDataToPrint = {
      ...invoice,
      vendorName,
      year,
      fullDate,
      organizationName: organization.name,
      mappedInvoiceProductEntries
    };
    if (!id) {
      // to check if this method is called from the landing page (id is undefined)
      const { promise } = await invoiceApi.fetch(invoice.invoiceId);
      promise
        .then(({ data }) => {
          if (!data) return;
          const { notes } = data;
          setInvoiceToPdf({ ...invoiceDataToPrint, notes });
        })
        .catch(catchCancel);
    } else setInvoiceToPdf({ ...invoiceDataToPrint });
    setDownloading(false);
  };

  const handleAction = (
    action,
    invoice,
    isLanding = false,
    index,
    setIndex
  ) => {
    if (isLanding) setIndex(index);

    const actions = {
      view: viewAction,
      edit: editAction,
      delete: deleteAction,
      createTask: createTaskAction,
      shareOrPrint: () => shareOrPrintAction(invoice),
      tag: tagAction,
      duplicate: () => {}
    };

    actions[action](invoice);
  };

  return {
    handleAction,
    deleteModalState,
    invoiceTags,
    openTags,
    selectedInvoiceTitleAndId,
    selectedInvoiceData,
    setOpenTags,
    setInvoiceTags,
    setDeleteModalState,
    shareOrPrint: shareOrPrintAction,
    downloadingPDF: downloading
  };
};

export default useInvoiceActions;
